import { LinkTarget } from '@/types'
import { RouteLocationRaw } from 'vue-router'
import { ROUTE_NAMES } from './constants'
import { TR } from '@/i18n/i18n-global'

// external routes not handled by vue-router
export const DEVELOPERS_LANDPAGE_PATH = '/developers'
export const PARTNERS_LANDPAGE_PATH = '/partners'
export const BLOG_PATH = '/blog'

type NavLink = {
    // TODO: type title, link to safely use our routes/route-names
    title: string
    to?: RouteLocationRaw
    externalTo?: string
    target?: LinkTarget
}

export const PRIVACY_POLICY_NAVIGATION_ITEM = (tr: TR): NavLink => ({
    title: tr('navigation.privacy_policy'),
    externalTo: '/confidential',
})

export const NAVIGATION_LINKS = (tr: TR): NavLink[] => [
    {
        title: tr('navigation.developers'),
        externalTo: DEVELOPERS_LANDPAGE_PATH,
        target: '_blank',
    },
    {
        title: tr('navigation.publishers'),
        externalTo: PARTNERS_LANDPAGE_PATH,
        target: '_blank',
    },
    PRIVACY_POLICY_NAVIGATION_ITEM(tr),
    {
        title: tr('navigation.contact_us'),
        externalTo: '/contacts',
    },
    {
        title: tr('navigation.takedown_notice'),
        externalTo: '/takedown-notice',
    },
]

export const NAVIGATION_ALL_GAMES_LINKS = (tr: TR) =>
    ({
        allGames: {
            title: tr('navigation.all_games'),
            icon: 'all_games',
            to: { name: ROUTE_NAMES.ALL_CATEGORIES },
        },
        allSeries: {
            title: tr('navigation.all_series'),
            icon: 'all_series',
            to: { name: ROUTE_NAMES.ALL_SERIES },
        },
        allTags: {
            title: tr('navigation.all_tags'),
            icon: 'all_tags',
            to: { name: ROUTE_NAMES.ALL_TAGS },
        },
    }) as const
