export const CUSTOM_PG_EVENTS = [
    'game_opened',
    'session_start',
    'export_timespent_3min',
    'export_timespent_1.5min',
    'ping',
    'navigation',
    'session_end',
    'timespent_5min',
    'timespent_10min',
    'timespent_15min',
] as const

export const DEFAULT_CLIDS = { direct: 'direct', directTest: 'direct-test' }

export const MANIFEST_META_KEY = 'pwa-manifest'
