import { defineStore } from 'pinia'
import { computed, ref } from 'vue'

export const useLayoutStore = defineStore('layout', () => {
    const fullScreen = ref(false)

    function setFullscreen(value: boolean) {
        fullScreen.value = value
    }

    const categoriesModalOpened = ref(false)

    function setCategoriesModalOpened(value: boolean) {
        categoriesModalOpened.value = value
    }

    const inviteModalOpenedReason = ref<boolean | 'need_more_fennecs'>(false)

    function setInviteModalOpened(value: typeof inviteModalOpenedReason.value) {
        inviteModalOpenedReason.value = value
    }

    const signInModalOpened = ref(false)

    function setSignInModalOpened(value: boolean) {
        signInModalOpened.value = value
    }

    const isReportBugModalOpen = ref(false)

    function setIsReportBugModalOpen(value: boolean) {
        isReportBugModalOpen.value = value
    }

    const paymentModalAmount = ref(0)
    const isPaymentModalOpen = computed(() => Boolean(paymentModalAmount.value))

    function setPaymentModalAmount(value: number) {
        paymentModalAmount.value = value
    }

    return {
        setFullscreen,
        fullScreen,
        setCategoriesModalOpened,
        categoriesModalOpened,
        setInviteModalOpened,
        inviteModalOpenedReason,
        setSignInModalOpened,
        signInModalOpened,
        setIsReportBugModalOpen,
        isReportBugModalOpen,
        isPaymentModalOpen,
        paymentModalAmount,
        setPaymentModalAmount,
    }
})
