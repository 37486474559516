import { defineComponent as _defineComponent } from 'vue'
import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = ["src", "alt"]

import Button from '@/components_new/Button.vue'
import { computed } from 'vue'
import { useRoute } from 'vue-router'
import { useCategoriesStore } from '@/store/categories-store'
import { type ErrorStatus, type PageStatus } from '@/store/app-store'
import img404 from './assets/404.webp'
import imgJoystickOnFire from './assets/joystick-on-fire.webp'
import Typography from '../Typography.vue'
import { useTr } from '@/composables/use-tr'
import { ROUTE_NAMES } from '@/router/constants'


export default /*@__PURE__*/_defineComponent({
  __name: 'ErrorContent',
  props: {
    type: {},
    randomGameHRU: {},
    showButtons: { type: Boolean }
  },
  setup(__props: any) {

const categoriesStore = useCategoriesStore()
const tr = useTr()

const props = __props

const route = useRoute()

const contentForType = computed(() => ({
    404: {
        img: img404,
        imgAlt: tr('errorContent.404_imgAlt'),
        title: tr('errorContent.404_title'),
        text: tr('errorContent.404_text'),
        buttons: [
            {
                text: tr('errorContent.404_back'),
                link: { name: ROUTE_NAMES.MAIN },
                interfaceTarget: 'navigation-link',
                // onClick: () => {},
            },
            props.randomGameHRU && {
                text: tr('errorContent.404_randomGame'),
                interfaceTarget: 'random-game',
                link: { name: ROUTE_NAMES.GAME_PAGE, params: { game: props.randomGameHRU } },
                onClick: () => {
                    categoriesStore.updateRandomGame()
                },
            },
        ].filter((x) => !!x),
    },
    500: {
        img: imgJoystickOnFire,
        imgAlt: tr('errorContent.500_imgAlt'),
        title: tr('errorContent.500_title'),
        text: tr('errorContent.500_text'),
        buttons: [
            {
                text: tr('errorContent.500_reload'),
                link: route.fullPath,
                interfaceTarget: 'reload-page',
                onClick: () => {},
            },
            {
                text: tr('errorContent.500_back'),
                link: { name: ROUTE_NAMES.MAIN },
                interfaceTarget: 'navigation-link',
                onClick: () => {},
            },
        ],
    },
}))

const content = computed(() => contentForType.value[props.type as ErrorStatus] || contentForType.value[500])

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_ctx.$style.container)
  }, [
    _createElementVNode("div", {
      class: _normalizeClass(_ctx.$style.center)
    }, [
      _createElementVNode("img", {
        class: _normalizeClass(_ctx.$style.img),
        src: content.value.img,
        alt: content.value.imgAlt
      }, null, 10, _hoisted_1),
      _createElementVNode("div", {
        class: _normalizeClass(_ctx.$style.texts)
      }, [
        _createVNode(Typography, {
          type: "header",
          size: "m",
          as: "h2"
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(content.value.title), 1)
          ]),
          _: 1
        }),
        _createVNode(Typography, {
          type: "paragraph",
          size: "s"
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(content.value.text), 1)
          ]),
          _: 1
        })
      ], 2),
      (_ctx.showButtons)
        ? (_openBlock(), _createElementBlock("div", {
            key: 0,
            class: _normalizeClass(_ctx.$style.buttons)
          }, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(content.value.buttons, (button, index) => {
              return (_openBlock(), _createBlock(Button, {
                key: index,
                class: _normalizeClass(_ctx.$style.button),
                size: "l",
                interfaceTarget: button.interfaceTarget,
                to: button.link,
                visual: index === 0 ? 'fill' : 'outline',
                onClick: button.onClick
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(button.text), 1)
                ]),
                _: 2
              }, 1032, ["class", "interfaceTarget", "to", "visual", "onClick"]))
            }), 128))
          ], 2))
        : _createCommentVNode("", true)
    ], 2)
  ], 2))
}
}

})