<template>
    <img
        v-if="typeof icon === 'string'"
        :src="icon"
        :alt="altText"
        :class="[$style.icon, $style.img, $style[`icon-${props.name}`]]"
    />
    <component
        :is="icon"
        v-else
        :class="[$style.icon, $style[`icon-${props.name}`]]"
        role="img"
        :aria-label="altText"
    />
</template>
<script setup lang="ts">
import { computed } from 'vue'
import { type IconNameType, icons } from '@/components_new/Icon/index'

export type { IconNameType }

const props = defineProps<{
    name: IconNameType
    alt?: string
}>()

const icon = computed(() => icons[props.name])

const altText = computed(() => props.alt || `${props.name} icon`)
</script>
<style module>
.icon {
    display: inline-block;
}

.img {
    width: 24px;
    height: 24px;
    object-fit: contain;
}

.icon-loader {
    animation: loader-spin 1s infinite steps(8, end);
    /* loader icon is slightly misaligned */
    transform-origin: 48% 50%;
}

@keyframes loader-spin {
    from {
        transform: rotate(0);
    }
    to {
        transform: rotate(360deg);
    }
}
</style>
