import { defineComponent as _defineComponent } from 'vue'
import { normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, renderSlot as _renderSlot, resolveDynamicComponent as _resolveDynamicComponent } from "vue"

const _hoisted_1 = ["src", "alt"]

import { computed, onBeforeMount } from 'vue'
import Icon, { type IconNameType } from '@/components_new/Icon/Icon.vue'
import Typography from '@/components_new/Typography.vue'
import { type PromoActions } from '@/components_new/PromoItems/types'
import { usePromoStore } from '@/components_new/PromoItems/store'
import BaseLink from '@/components_new/BaseLink.vue'
import Button from '@/components_new/Button.vue'
import { RouteLocationRaw } from 'vue-router'

interface PromoBannerProps {
    title: string
    description: string
    direction: 'row' | 'column'
    buttonPosition?: 'bottom' | 'right'
    textAlign?: 'left' | 'center'
    icon?: IconNameType
    hasCloseBtn?: boolean
    link?: { to?: RouteLocationRaw; target?: '_blank'; externalTo?: string }
    image?: { src: string; alt: string }
}


export default /*@__PURE__*/_defineComponent({
  __name: 'PromoBanner',
  props: {
    title: {},
    description: {},
    direction: {},
    buttonPosition: { default: 'bottom' },
    textAlign: { default: 'center' },
    icon: {},
    hasCloseBtn: { type: Boolean },
    link: {},
    image: {}
  },
  emits: ["activatePromo", "closePromo"],
  setup(__props: any, { emit: __emit }) {

const emit = __emit

const props = __props

const alt = computed(() => {
    if (!props.image) {
        return ''
    }
    if (props.image.src && !props.image.alt) {
        throw Error('Promo Banner alt for image is required')
    }

    return props.image.alt
})

const promoStore = usePromoStore()

function onClick(action: PromoActions) {
    promoStore.setPromoAction(action)
    emit('activatePromo')
}

// TODO: remove, it causes layout shifts
// eslint-disable-next-line no-restricted-syntax
onBeforeMount(() => {
    promoStore.checkAddGameBannerVisibility()
})

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_ctx.$style.promo_wrapper)
  }, [
    (props.hasCloseBtn)
      ? (_openBlock(), _createBlock(Button, {
          key: 0,
          size: "s",
          visual: "ghost",
          icon: "close",
          color: "white-60",
          interfaceTarget: "close",
          class: _normalizeClass(_ctx.$style.close),
          onClick: _cache[0] || (_cache[0] = ($event: any) => (emit('closePromo')))
        }, null, 8, ["class"]))
      : _createCommentVNode("", true),
    (_openBlock(), _createBlock(_resolveDynamicComponent(props.link ? BaseLink : 'div'), {
      to: props.link?.to,
      externalTo: props.link?.externalTo,
      target: props.link?.target,
      class: _normalizeClass([_ctx.$style.promo, _ctx.$style[props.direction]])
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", {
          class: _normalizeClass(_ctx.$style.promo_content)
        }, [
          (props.image)
            ? (_openBlock(), _createElementBlock("img", {
                key: 0,
                class: _normalizeClass(_ctx.$style.promo_image),
                src: props.image.src,
                alt: alt.value
              }, null, 10, _hoisted_1))
            : _createCommentVNode("", true),
          _createElementVNode("div", {
            class: _normalizeClass([_ctx.$style.promo_text, _ctx.$style[props.textAlign]])
          }, [
            _createElementVNode("div", {
              class: _normalizeClass(_ctx.$style.promo_title)
            }, [
              (props.icon)
                ? (_openBlock(), _createBlock(Icon, {
                    key: 0,
                    name: props.icon
                  }, null, 8, ["name"]))
                : _createCommentVNode("", true),
              _createVNode(Typography, {
                type: "header",
                size: "m",
                responsive: false,
                accent: ""
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(props.title), 1)
                ]),
                _: 1
              })
            ], 2),
            _createVNode(Typography, {
              size: "s",
              responsive: false,
              class: _normalizeClass(_ctx.$style.promo_description)
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(props.description), 1)
              ]),
              _: 1
            }, 8, ["class"])
          ], 2)
        ], 2),
        (_ctx.$slots.default)
          ? (_openBlock(), _createElementBlock("div", {
              key: 0,
              class: _normalizeClass(_ctx.$style[`promo_button_${props.buttonPosition}`])
            }, [
              _renderSlot(_ctx.$slots, "default", { handleClick: onClick })
            ], 2))
          : _createCommentVNode("", true)
      ]),
      _: 3
    }, 8, ["to", "externalTo", "target", "class"]))
  ], 2))
}
}

})