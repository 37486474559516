export const en = {
    game: 'game/:game',
    category: 'category/:hru',
    tag: 'tag/:hru',
    series: 'series/:hru',
    contacts: 'contacts',
    profile: 'profile',
    shop: 'shop',
    invite: 'invite/:inviteCode',
    allCategoriesOld: 'categorias-juegos',
    allCategories: 'all/categories',
    allTags: 'all/tags',
    allSeries: 'all/series',
    error: 'error',
    confidential: 'confidential',
    termsofuse: 'termsofuse',
    takedown_notice: 'takedown-notice',
    license: 'license',
    partners_api: 'partners-api',
}

export type LocalizedPaths = typeof en

export const es: LocalizedPaths = {
    game: 'juego/:game',
    category: 'categoria/:hru',
    tag: 'etiqueta/:hru',
    series: 'serie/:hru',
    contacts: 'contactos',
    profile: 'perfil',
    shop: 'tienda',
    invite: 'invitar/:inviteCode',
    allCategoriesOld: 'categorias-juegos',
    allCategories: 'todas/categorias',
    allTags: 'todas/etiquetas',
    allSeries: 'todas/series',
    error: 'error',
    confidential: 'confidencial',
    termsofuse: 'terminosdeuso',
    takedown_notice: 'aviso-de-eliminacion',
    license: 'licencia',
    partners_api: 'api-de-socios',
}
