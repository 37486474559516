import { onMounted, onUnmounted } from 'vue'
import { useLoggerStore } from './logger-store'
import { AvailableEventGA } from '@/types/logger.types'

/**
 * Composable for tracking interface events for Google Analytics
 * Tracks clicks on elements with [data-interface-target] attribute
 * Also captures the closest [data-interface-section] attribute if available
 */

export function useGlobalInterfaceEvents() {
    const loggerStore = useLoggerStore()

    /**
     * Process a click event to find GA tracking attributes and send to analytics
     * @param event - The click event
     */
    const handleInterfaceClick = (event: MouseEvent) => {
        const target = event.target as HTMLElement
        const gaTargetElement = target.closest('[data-interface-target]') as HTMLElement | null
        if (!gaTargetElement) {
            return
        }

        const gaSectionElement = gaTargetElement.closest('[data-interface-section]') as HTMLElement | null

        const eventProps = {
            interface_target: gaTargetElement.dataset.interfaceTarget as string,
            interface_path: gaSectionElement?.dataset.interfaceSection || 'unknown',
            eventName: 'interface_click' as AvailableEventGA,
        }
        loggerStore.logInterfaceEvents(eventProps)
    }

    onMounted(() => {
        document.body.addEventListener('click', handleInterfaceClick, true) // set on capture phase for buttons with no propagation
    })

    onUnmounted(() => {
        document.body.removeEventListener('click', handleInterfaceClick)
    })
}
