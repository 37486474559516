import type { AvailableLocale } from '@/i18n/types'
import type { AdvAction } from '@/modules/adv'
import type { VUE_APP_CONFIG } from '@/modules/config/createConfig'
import type { MessageAction } from '@/modules/main-frame/message-types'
import type { ExperimentsData } from '@/server/experiments/types'
import type { PageStatus } from '@/store/app-store'
import type { RouteLocationRaw } from 'vue-router'

export enum GameProviders {
    GOOGLE_AD = 'googleAd',
    GAME_DISTRIBUTION = 'gameDistribution',
    DEBUG_PROVIDER = 'debug',
    EMPTY_PROVIDER = 'emptyProvider',
}

export type TMessageAction = MessageAction & AdvAction

export type GameEvent = Omit<MessageEvent, 'data'> & {
    source: MessageEventSource
    data: {
        id: string
        action: TMessageAction
        payload?: Record<string, unknown>
        responseToId?: string
        type: 'adv' | 'liveness' | 'error'
    }
}

export type PaginationType = {
    page: number
    pageSize: number
}

export type PlatformType = 'ios' | 'android' | 'desktop'

export type APIGameInfoDTO = APIGameInfoType | APIRedirect

export type APIGameInfoType = {
    game: APIGameType
    categoriesInfo: APICategoryShortInfo[]
    pathCategories: APICategoryShortInfo[]
}

export type APIRedirect = {
    redirectTarget:
        | {
              type: 'main-page'
          }
        | {
              type: 'category'
              cat_type: 'tag' | 'series' | 'category'
              cat_hru: string
          }
        | {
              type: 'game'
              game_hru: string
          }
    status: 301 | 302
}

export type APIGameType = {
    id: string
    hru: string
    title: string
    icon: string
    preview: string
    description: string
    developer?: string
    how_to_play_text: string
    seo_text: string
    is_hidden: boolean
    is_android: boolean
    is_ios: boolean
    is_desktop: boolean
    tag: string[]
    categories: string[]
    series: string[]
    game_url: string
    screenshots: string[]
    videos: APIGameVideoType[]
    vertical: boolean
    horizontal: boolean
    source?: string
    rating: {
        value: number
        count: number
    }
}

export type GameCategoriesInfoType = {
    categoriesInfo: CategoryShortInfo[]
    pathCategories: CategoryShortInfo[]
}

export type ShortGameType = {
    hru: string
    preview: string
    title: string
    video: string | undefined
}

export type ShortGameZipType = [string, string, string, string | undefined]

export type APIShortGameType = {
    hru: string
    icon: string
    id: string
    preview: string
    title: string
    videos: APIGameVideoType[]
}

export type APIGameVideoType = {
    player_url: string
    preview_url: string
    thumbnail_url: string
}

export type MainCategoriesType =
    | 'io'
    | 'action'
    | 'puzzle'
    | 'arcade'
    | 'sports'
    | 'strategy'
    | 'bubble-shooter'
    | 'girls'
    | 'boys'
    | 'car'
    | 'simulation'
    | 'shooting'
    | 'gun'
    | 'clicker'
    | 'funny'
    | 'snake'
    | 'skill'
    | 'drawing'
    | 'idle'
    | 'multiplayer'
    | 'solitaire'
    | 'horror'
    | 'two-player'
    | 'trending_now'
    | 'new'
    | 'random'

export type GameRecommendationType = 'recommended' | 'top_playgama' | 'related' | 'random' | 'series'
export type APIGameRecommendation<T extends GameRecommendationType[]> = {
    [key in T[number]]: APIShortGameType[]
}

export type CategoryType = 'tag' | 'series' | 'category'

export type CategoryDataType = {
    categories: APICategoryData[]
    pagination: {
        page: 0
        pageSize: 0
    }
}

export type APICategoryDataType = {
    categories: APICategoryData[]
    pagination: {
        page: 0
        pageSize: 0
    }
}

export type APICategoryData = {
    id: string
    hru: string
    title: string
    categoryType: CategoryType
    games: APIShortGameType[]
    totalCount: number
}

export type MainCategoryListType = {
    id: MainCategoriesType
    title: string
    href: RouteLocationRaw
    games: APIShortGameType[]
}

export type APICategoryShortInfo = {
    id: string
    hru: string
    title: string
    categoryType: CategoryType
}

export type CategoryShortInfo = {
    id: string
    title: string
    href: RouteLocationRaw
    categoryType: CategoryType
}

export type APICategorySeoInfo =
    | {
          id: string
          hru: string
          seoText: string
          description: string // full name of categories
          subCategories: { title: string; id: string; hru: string }[]
          pathCategories: { title: string; id: string; hru: string }[]
          metaTitle?: string
          categoryType: CategoryType
          metaDescription?: string
      }
    | APIRedirect

export type CategoryInfo = {
    id: string
    fullTitle: string
    seoText: string
    metaTitle?: string
    metaDescription?: string
    subCategories: { title: string; id: string; href: RouteLocationRaw }[]
    pathCategories: { title: string; id: string; href: RouteLocationRaw }[]
}

export type FormSubmitData = {
    message: string
    email: string
    page?: string
}

export type UserSocialKeys = 'telegramAcc' | 'tonWallet'
export const SOCIALS = ['telegram', 'ton'] as const
export type SocialsName = (typeof SOCIALS)[number]
export type SocialType = 'account' | 'wallet'

export type UserSocialsType = {
    name: SocialsName
    account?: string
    type: SocialType
    tag: string
    key: UserSocialKeys
    isSupport: boolean
    validate: (value: string) => boolean
}

export type User = {
    _id: string
    firstName: string
    lastName: string
    avatar: string
    fennecs: number
    email?: string
    referralCode: string
    telegramAcc?: string
    tonWallet?: string
    gamesPlayed?: number
    createdAt?: number
    currentStreak?: number
    usersInvited?: number
    goldFenecBalance?: number
}

export type TaskType =
    | 'VISIT_EVERYDAY'
    | 'REFERRAL_INVITE'
    | 'RANDOM_GAME'
    | 'PLAY_GAME'
    | 'REGISTER'
    | 'REFERRAL_REGISTER'
export type TaskStatus = 'ACTIVE' | 'COMPLETED' | 'CLAIMED'

export type BaseTaskDto = {
    task_id: string
    type: TaskType
    status: TaskStatus
    coins: number
}

export type DailyTaskDto = BaseTaskDto & { type: 'VISIT_EVERYDAY' }
export type RandomTaskDto = BaseTaskDto & {
    type: 'RANDOM_GAME'
    settings: {
        game: ShortGameType
        seconds_to_play: number
    }
}

export type GameTaskDto = BaseTaskDto & {
    type: 'PLAY_GAME'
    settings: {
        game: ShortGameType
        seconds_to_play: number
    }
}

export type ReferralTaskDto = BaseTaskDto & {
    type: 'REFERRAL_INVITE'
    settings: {
        invitations_not_claimed: number
    }
}

export type TaskDto = DailyTaskDto | RandomTaskDto | GameTaskDto | ReferralTaskDto

export type UserTasksStats = {
    wallet: {
        coins: number
    }
    tasks: TaskDto[]
}

export type AdditionalServerData = {
    conversionValue?: number
    locale?: {
        countryCode?: string
        countryNameEn?: string
        currency: Currency
    }
    hasToken: boolean
    experiments: ExperimentsData
    config: VUE_APP_CONFIG
}

export type RewardCardTypeDTO = {
    id: string
    type: 'roblox' | 'amazon'
    availability: 'low' | 'medium' | 'high'
    value: number
    price: number
}

export type RewardCardType = {
    id: string
    type: 'roblox' | 'amazon'
    availability: 'low' | 'medium' | 'high'
    value: number
    currency: Currency
    price: number
}

export type Currency = 'eur' | 'usd'

export type RewardPurchasePayload = { rewardId: string; userEmail: string; countryCode: string }

export type LinkTarget = '_blank' | '_self'

export type ImportWrapper = <T>(importCallback: () => T) => () => T

export type ServerRenderContext = {
    pageStatus?: PageStatus
    platform: string
    auid?: string
    host: string
    detectedLocale?: AvailableLocale
}
