import { defineComponent as _defineComponent } from 'vue'
import { normalizeClass as _normalizeClass, createVNode as _createVNode, unref as _unref, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock, renderList as _renderList, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode } from "vue"

import { computed } from 'vue'
import { storeToRefs } from 'pinia'
import { RouteLocationRaw, useRoute, useRouter } from 'vue-router'
import { useUserStore } from '@/store/user-store/user-store'
import Logo from '@/components_new/Logo/Logo.vue'
import Icon from '@/components_new/Icon/Icon.vue'
import Typography from '@/components_new/Typography.vue'
import PromoSignInHorizontalShort from '@/components_new/PromoItems/components/PromoSignInHorizontalShort.vue'
import PromoInviteVertical from '@/components_new/PromoItems/components/PromoInviteVertical.vue'
import { DEVELOPERS_LANDPAGE_PATH, PARTNERS_LANDPAGE_PATH } from '@/router/navigation-constants'
import Link, { type LinkProps } from '@/components_new/Link.vue'
import BaseLink from '@/components_new/BaseLink.vue'
import TasksWidget from '@/components_new/TaskItems/TasksWidget.vue'
import AdBlock from '@/components_new/AdBlock.vue'
import { useTr } from '@/composables/use-tr'
import { type IconNameType } from '@/components_new/Icon'
import { ROUTE_NAMES } from '@/router/constants'
import { LinkTarget } from '@/types'
import UserAndBalance from './UserAndBalance.vue'
import Rewards from './Rewards.vue'

type MenuItem = {
    title: string
    to?: RouteLocationRaw
    externalTo?: string
    target?: LinkTarget
    icon?: IconNameType
    color?: LinkProps['color']
}


export default /*@__PURE__*/_defineComponent({
  __name: 'LeftSide',
  setup(__props) {

const tr = useTr()
const restMenuItems = computed((): MenuItem[] => [
    {
        title: tr('navigation.developers'),
        icon: 'sdk',
        externalTo: DEVELOPERS_LANDPAGE_PATH,
        target: '_blank' as const,
    },
    {
        title: tr('navigation.publishers'),
        icon: 'smile',
        externalTo: PARTNERS_LANDPAGE_PATH,
        target: '_blank' as const,
    },
])

const userStore = useUserStore()
const route = useRoute()
const router = useRouter()
const isUserProfilePage = computed(() => route.name === ROUTE_NAMES.PROFILE)
const isMain = computed(() => route.name === ROUTE_NAMES.MAIN)

const { user, isAuthorized, userBalance, isUserInfoLoaded } = storeToRefs(userStore)

async function logout() {
    await userStore.logout()
    router.replace({ name: ROUTE_NAMES.MAIN })
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_ctx.$style.sidebar),
    "data-interface-section": "sidebar"
  }, [
    _createVNode(Logo, {
      class: _normalizeClass(_ctx.$style.logo),
      noLink: isMain.value
    }, null, 8, ["class", "noLink"]),
    _createElementVNode("div", {
      class: _normalizeClass(_ctx.$style.sections)
    }, [
      (_unref(isUserInfoLoaded))
        ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
            (_unref(isAuthorized) && _unref(user))
              ? (_openBlock(), _createBlock(BaseLink, {
                  key: 0,
                  to: { name: _unref(ROUTE_NAMES).PROFILE },
                  disabled: isUserProfilePage.value,
                  class: _normalizeClass(_ctx.$style.section),
                  "data-interface-target": "profile-link"
                }, {
                  default: _withCtx(() => [
                    _createVNode(UserAndBalance, {
                      user: _unref(user),
                      userBalance: _unref(userBalance),
                      isProfilePage: isUserProfilePage.value,
                      onLogout: logout
                    }, null, 8, ["user", "userBalance", "isProfilePage"])
                  ]),
                  _: 1
                }, 8, ["to", "disabled", "class"]))
              : _createCommentVNode("", true),
            _createVNode(PromoSignInHorizontalShort)
          ], 64))
        : _createCommentVNode("", true),
      (!isUserProfilePage.value)
        ? (_openBlock(), _createBlock(TasksWidget, {
            key: 1,
            showOnlyFirst: "",
            redirect: !isUserProfilePage.value
          }, {
            default: _withCtx(() => [
              _createVNode(PromoInviteVertical)
            ]),
            _: 1
          }, 8, ["redirect"]))
        : _createCommentVNode("", true),
      (isUserProfilePage.value)
        ? (_openBlock(), _createBlock(PromoInviteVertical, { key: 2 }))
        : _createCommentVNode("", true),
      _createVNode(Link, {
        class: _normalizeClass(_ctx.$style.section),
        "data-interface-target": "shop-link",
        to: { name: _unref(ROUTE_NAMES).SHOP }
      }, {
        default: _withCtx(() => [
          _createVNode(Rewards)
        ]),
        _: 1
      }, 8, ["class", "to"]),
      _createVNode(Typography, {
        is: "ul",
        class: _normalizeClass([_ctx.$style.section, _ctx.$style.links]),
        type: "label",
        size: "s",
        accent: true,
        responsive: false
      }, {
        default: _withCtx(() => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(restMenuItems.value, (item, index) => {
            return (_openBlock(), _createElementBlock("li", {
              key: item.title
            }, [
              (index !== 0)
                ? (_openBlock(), _createElementBlock("span", {
                    key: 0,
                    class: _normalizeClass(_ctx.$style.inner_sep)
                  }, null, 2))
                : _createCommentVNode("", true),
              _createVNode(Link, {
                target: item.target,
                "data-interface-target": "navigation-link",
                externalTo: item.externalTo,
                color: item.color || 'white',
                class: _normalizeClass(_ctx.$style.links_item)
              }, {
                default: _withCtx(() => [
                  (item.icon)
                    ? (_openBlock(), _createBlock(Icon, {
                        key: 0,
                        name: item.icon,
                        class: _normalizeClass(_ctx.$style.links_item_icon)
                      }, null, 8, ["name", "class"]))
                    : _createCommentVNode("", true),
                  _createTextVNode(" " + _toDisplayString(item.title), 1)
                ]),
                _: 2
              }, 1032, ["target", "externalTo", "color", "class"])
            ]))
          }), 128))
        ]),
        _: 1
      }, 8, ["class"])
    ], 2),
    _createVNode(AdBlock, {
      type: "sidebar",
      refreshSec: 90,
      class: _normalizeClass(_ctx.$style.ad)
    }, null, 8, ["class"])
  ], 2))
}
}

})