<template>
    <Layout>
        <ErrorContent
            :type="type"
            :randomGameHRU="randomGameHRU"
            showButtons
        />
    </Layout>
</template>
<script setup lang="ts">
import { onMounted, onServerPrefetch, watch } from 'vue'
import { storeToRefs } from 'pinia'
import Layout from '@/layouts/ModernTwoColumnsLayout.vue'
import { useCategoriesStore } from '@/store/categories-store'
import ErrorContent from '@/components_new/ErrorContent/ErrorContent.vue'
import { type ErrorStatus } from '@/store/app-store'
import { useI18nAddon } from '@/i18n/i18n'

const categoriesStore = useCategoriesStore()
const { randomGameHRU } = storeToRefs(categoriesStore)

defineProps<{
    type: ErrorStatus
}>()

onServerPrefetch(async () => {
    await categoriesStore.setMainData()
})

onMounted(async () => {
    await categoriesStore.setMainData()
})

const { currentLocale } = useI18nAddon()
watch(currentLocale, categoriesStore.setMainData)
</script>
