<template>
    <a
        v-if="props.externalTo"
        :href="externalTo"
        :target="props.target"
        :class="[$style.baseLink, props.disabled ? $style.disabled : '']"
        @click="emit('click', $event)"
    >
        <slot />
    </a>
    <RouterLink
        v-else-if="computedTo"
        :to="keepRequiredParams(computedTo)"
        :target="props.target"
        :class="[$style.baseLink, props.disabled ? $style.disabled : '']"
        @click="emit('click', $event)"
    >
        <slot />
    </RouterLink>
</template>

<script lang="ts" setup>
import { RouteLocationRaw, RouterLink } from 'vue-router'
import { LinkTarget } from '@/types'
import { useUrl, useGetExternalUrl } from '@/composables/url-helpers'
import { computed } from 'vue'

export interface BaseLinkProps {
    disabled?: boolean
    to?: RouteLocationRaw
    externalTo?: string
    target?: LinkTarget
}

const props = defineProps<BaseLinkProps>()
const { keepRequiredParams } = useUrl()
const getExternalUrl = useGetExternalUrl()

const computedTo = computed(() => {
    if (props.target === '_blank' && props.to) {
        return getExternalUrl(props.to)
    }
    return props.to
})

const emit = defineEmits<{ click: [value: Event] }>()
</script>

<style module>
.baseLink.disabled,
.baseLink.disabled:hover,
.baseLink.disabled:focus-within {
    cursor: default;
}
</style>
