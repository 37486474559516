import { defineComponent as _defineComponent } from 'vue'
import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

import { computed, onBeforeUnmount, onMounted, ref, watch, useId } from 'vue'
import { useAdsStore } from '@/store/ads-store'
import type { Banner } from '@/modules/adv/banner'
import type { PageAdSize, PageAdType } from '@/modules/adv'
import { useResizeObserver, useIntersectionObserver, useDebounceFn } from '@vueuse/core'
import { useIsMounted } from '@/utils/vue-hooks/use-is-mounted'

export type AdBlockProps = {
    type: PageAdType
    sizes?: PageAdSize[]
    /**
     * Class to add when the ad is not loaded
     * Should not hide element with "display: none", otherwise the ad will never be loaded
     */
    emptyClass?: string
    intersectionRootMargin?: string
    refreshSec?: number
}


export default /*@__PURE__*/_defineComponent({
  __name: 'AdBlock',
  props: {
    type: {},
    sizes: {},
    emptyClass: {},
    intersectionRootMargin: {},
    refreshSec: {}
  },
  setup(__props: any) {

const adsStore = useAdsStore()

const defaultSlotSizes: Record<PageAdType, PageAdSize[]> = {
    sidebar: [[300, 250], [300, 300], 'fluid'],
    sidebar_bottom: [[300, 250], [300, 300], 'fluid'],
    leaderboard: [[728, 90], [950, 90], [960, 90], [970, 90], [980, 90], 'fluid'],
    catalog_mobile: [[300, 300], [336, 280], [300, 250], 'fluid'],
    sticky_portrait: ['fluid'],
    sticky_mobile: [[300, 50], [320, 50], 'fluid'],

    widget_sidebar: [[300, 250], [300, 300], 'fluid'],
    widget_sidebar_bottom: [[300, 250], 'fluid'],
    widget_horizontal: [[970, 90], [960, 90], [950, 90], [980, 90], [728, 90], 'fluid'],
    widget_horizontal_2: [[970, 90], [960, 90], [950, 90], [980, 90], [728, 90], 'fluid'],
    widget_sticky_mobile: [[300, 50], [320, 50], 'fluid'],
}

const props = __props
const isMounted = useIsMounted()
const sizes = computed(() => props.sizes || defaultSlotSizes[props.type])

// get sorted sizes in descending width order, "fliud" is always the last — it can be any size
const sortedSizesDesc = computed(() =>
    sizes.value.slice().sort((a, b) => {
        if (a === 'fluid') {
            return 1
        }
        if (b === 'fluid') {
            return -1
        }
        return b[0] - a[0]
    }),
)

const banner = ref<Banner>()
const bannerEmpty = ref(true)
const bannerVisible = computed(() => !!banner.value && !bannerEmpty.value)

const id = `ad_${useId()}`
const containerRef = ref<HTMLElement | null>(null)
const lastMaxWidthIndex = ref(-1)
const readyForAdRequest = ref(props.intersectionRootMargin === undefined)

async function updateBanner() {
    if (!readyForAdRequest.value) {
        return
    }

    if (!adsStore.adMediator) {
        return
    }

    const container = containerRef.value
    if (!container) {
        return
    }

    const rect = container.getBoundingClientRect()

    // find the index of the largest size that fits the container
    const maxWidthIndex = sortedSizesDesc.value.findIndex((adSize) => {
        if (adSize === 'fluid') {
            return rect.width > 0
        }
        return rect.width >= adSize[0]
    })

    // do nothing, if the max size is the same
    if (maxWidthIndex === lastMaxWidthIndex.value) {
        return
    }

    lastMaxWidthIndex.value = maxWidthIndex

    // destroy the previous banner
    banner.value?.destroy()
    banner.value = undefined

    // prepare a new banner if there are sizes
    if (maxWidthIndex > -1 && rect.width > 0) {
        banner.value = await adsStore.adMediator?.prepareAd({
            type: props.type,
            refresh: props.refreshSec,
            el: id,
            sizes: sortedSizesDesc.value.slice(maxWidthIndex),
        })

        if (!isMounted.value) {
            banner.value.destroy()
            return
        }

        // these event listeners will be collected by GC when banner is destroyed/recreated
        banner.value?.addEventListener('rendered', () => {
            bannerEmpty.value = false
        })
        banner.value?.addEventListener('closed', () => {
            bannerEmpty.value = true
        })
        banner.value?.addEventListener('empty', () => {
            bannerEmpty.value = true
        })
    }
}
const updateBannerDebounced = useDebounceFn(updateBanner, 1000)

function checkEmptyStyles() {
    if (!props.emptyClass) {
        return
    }

    const elem = containerRef.value?.appendChild(document.createElement('div'))
    if (elem) {
        elem.classList.add(props.emptyClass)
        if (window.getComputedStyle(elem).display === 'none') {
            console.error(
                `AdBlock: class .${props.emptyClass} sets display to "none". That breaks tracking of the future size changes.\n\nUse negative margins to hide empty ad in the layout.`,
            )
        }
        elem.remove()
    }
}

onMounted(() => {
    checkEmptyStyles()
    updateBanner()
})

watch(
    () => props.emptyClass,
    () => {
        checkEmptyStyles()
    },
)

watch(
    () => props.sizes,
    () => {
        lastMaxWidthIndex.value = -1
        updateBanner()
    },
)

useResizeObserver(containerRef, updateBannerDebounced)

if (props.intersectionRootMargin !== undefined) {
    const { stop } = useIntersectionObserver(
        containerRef,
        ([entry]) => {
            if (entry.isIntersecting) {
                readyForAdRequest.value = true
                updateBanner()
                stop()
            }
        },
        {
            rootMargin: props.intersectionRootMargin,
        },
    )
}

onBeforeUnmount(() => {
    banner.value?.destroy()
})

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    ref_key: "containerRef",
    ref: containerRef,
    class: _normalizeClass({ [props.emptyClass || '']: props.emptyClass && !bannerVisible.value })
  }, [
    _createElementVNode("div", {
      class: _normalizeClass(_ctx.$style.adBlockWrap)
    }, [
      _createElementVNode("div", {
        id: id,
        class: _normalizeClass(_ctx.$style.adBlock)
      }, null, 2)
    ], 2)
  ], 2))
}
}

})