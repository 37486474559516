import {
    PaginationType,
    CategoryDataType,
    PlatformType,
    GameRecommendationType,
    APICategoryDataType,
    APICategorySeoInfo,
    APIGameRecommendation,
    APICategoryShortInfo,
    CategoryType,
    APIGameInfoDTO,
} from '@/types'
import { request } from '@/utils/requests/request'

type GamesAndCategoriesRequestParams = {
    categoriesIds?: string[]
    platform: PlatformType
    pagination?: PaginationType
    locale: string
}

const DEFAULT_PAGE_SIZE = '16'

export async function getGamesByCategories({
    categoriesIds,
    platform,
    pagination,
    locale,
}: GamesAndCategoriesRequestParams): Promise<CategoryDataType> {
    const path = '/api/v1/categories/games'
    const search = new URLSearchParams()
    search.set('pagination', JSON.stringify(pagination))
    search.set('platform', platform)
    search.set('locale', locale)
    if (categoriesIds) {
        search.set('categoriesIds', JSON.stringify(categoriesIds))
    }
    const { originalResponse, data } = await request(`${path}?${search}`)

    if (!originalResponse.ok) {
        return Promise.reject(originalResponse.status)
    }

    const gamesByCategories: APICategoryDataType = data
    return {
        categories: gamesByCategories.categories.map((category) => ({
            ...category,
            games: category.games,
        })),
        pagination: gamesByCategories.pagination,
    }
}

export async function getMainPageGamesByCategories({
    platform,
    locale,
}: GamesAndCategoriesRequestParams): Promise<CategoryDataType> {
    const path = '/api/v1/categories/main-page'
    const search = new URLSearchParams()
    search.set('platform', platform)
    search.set('pageSize', DEFAULT_PAGE_SIZE)
    search.set('locale', locale)
    const { originalResponse, data } = await request(`${path}?${search}`)

    if (!originalResponse.ok) {
        return Promise.reject(originalResponse.status)
    }

    const gamesByCategories: APICategoryDataType = data
    return {
        categories: gamesByCategories.categories.map((category) => ({
            ...category,
            games: category.games,
        })),
        pagination: gamesByCategories.pagination,
    }
}

export async function getGameByParam(param: string, locale: string): Promise<APIGameInfoDTO> {
    const URL = `/api/v1/games/info/${param}?locale=${locale}`
    try {
        const { originalResponse, data } = await request<APIGameInfoDTO>(URL)
        if (!originalResponse.ok) {
            return Promise.reject(originalResponse.status)
        }

        return data
    } catch (error) {
        console.error({ URL, error })
        // eslint-disable-next-line prefer-promise-reject-errors
        return Promise.reject(500)
    }
}

export async function getGamesCategoriesShortInfo(type: CategoryType, locale: string): Promise<APICategoryShortInfo[]> {
    const path = `/api/v1/categories/short-info/${type}?locale=${locale}`
    try {
        const { originalResponse, data } = await request<APICategoryShortInfo[]>(path)
        if (!originalResponse.ok) {
            return Promise.reject(originalResponse.status)
        }
        return data
    } catch (error) {
        console.error({ URL, error })
        // eslint-disable-next-line prefer-promise-reject-errors
        return Promise.reject(500)
    }
}

export async function getGamesCategoriesSeoById(id: string, locale: string): Promise<APICategorySeoInfo> {
    const path = `/api/v1/categories/seo/${id}?locale=${locale}`
    try {
        const { originalResponse, data } = await request<APICategorySeoInfo>(path)
        if (!originalResponse.ok) {
            return Promise.reject(originalResponse.status)
        }
        return data
    } catch (error) {
        console.error({ URL, error })
        // eslint-disable-next-line prefer-promise-reject-errors
        return Promise.reject(500)
    }
}

export async function getGameRecommendations<T extends GameRecommendationType[]>({
    hru,
    types,
    platform,
    locale,
    limit = 16,
}: {
    hru: string
    types: T
    platform: PlatformType
    locale: string
    limit?: number
}): Promise<APIGameRecommendation<T>> {
    const search = new URLSearchParams()
    search.set('rec_types', types.join(','))
    search.set('limit', String(limit))
    search.set('platform', platform)
    search.set('locale', locale)

    const URL = `/api/v1/games/recommendations/${hru}?${search}`
    try {
        const { originalResponse, data } = await request(URL)
        if (!originalResponse.ok) {
            // возможно логировать причину - непонятно
            return Promise.reject(originalResponse.status)
        }

        return data
    } catch (error) {
        console.error({ URL, error })
        // eslint-disable-next-line prefer-promise-reject-errors
        return Promise.reject(500)
    }
}
