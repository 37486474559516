import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, createBlock as _createBlock, renderSlot as _renderSlot } from "vue"

import { storeToRefs } from 'pinia'
import Typography from '@/components_new/Typography.vue'
import { computed } from 'vue'
import TaskWidget from '@/components_new/TaskItems/TaskWidget.vue'
import Button from '@/components_new/Button.vue'
import { useTasksStore } from '@/components_new/TaskItems/store'
import { useUserStore } from '@/store/user-store/user-store'
import { ROUTE_NAMES } from '@/router/constants'
import { RouteLocationRaw, useRouter } from 'vue-router'

interface TasksWidgetProps {
    showOnlyFirst?: boolean
    redirect?: boolean
}


export default /*@__PURE__*/_defineComponent({
  __name: 'TasksWidget',
  props: {
    showOnlyFirst: { type: Boolean },
    redirect: { type: Boolean }
  },
  setup(__props: any) {

const props = __props

const tasksStore = useTasksStore()
const userStore = useUserStore()
const { tasksError, showTasks, allTasksClaimed, notClaimedTasks } = storeToRefs(tasksStore)

const { isAuthorized } = storeToRefs(userStore)

const tasksToShow = computed(() => {
    if (allTasksClaimed.value) {
        return []
    }
    return props.showOnlyFirst ? notClaimedTasks.value.slice(0, 1) : notClaimedTasks.value
})

const router = useRouter()
const onTaskClick = () => {
    if (props.redirect) {
        router.push({ name: ROUTE_NAMES.PROFILE })
    }
}
const onTaskStart = ({ taskId, link }: { taskId: string; link: RouteLocationRaw }) => {
    tasksStore.onTaskStart(taskId)
    router.push(link)
}

return (_ctx: any,_cache: any) => {
  return (_unref(isAuthorized) && (_unref(showTasks) || (_unref(allTasksClaimed) && _ctx.$slots.default)))
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: _normalizeClass(_ctx.$style.task_widgets),
        "data-interface-section": "tasks-widget"
      }, [
        (_unref(tasksError))
          ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
              _createElementVNode("span", {
                class: _normalizeClass(_ctx.$style.inner_sep)
              }, null, 2),
              _createElementVNode("div", {
                class: _normalizeClass(_ctx.$style.error_container)
              }, [
                _createVNode(Typography, {
                  type: "label",
                  size: "l"
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$tr('tasks.error_title')), 1)
                  ]),
                  _: 1
                }),
                _createVNode(Button, {
                  color: "carbon-400",
                  visual: "fill",
                  interfaceTarget: "reload-tasks",
                  onClick: _unref(tasksStore).onReloadClick
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$tr('tasks.error_button')), 1)
                  ]),
                  _: 1
                }, 8, ["onClick"])
              ], 2)
            ], 64))
          : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
              (!_unref(allTasksClaimed))
                ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(tasksToShow.value, (task) => {
                    return (_openBlock(), _createBlock(TaskWidget, {
                      key: task.task_id,
                      task: task,
                      class: _normalizeClass({ [_ctx.$style.taskClickable]: props.redirect }),
                      onClaimTask: _unref(tasksStore).onTaskClaimed,
                      onTaskStart: onTaskStart,
                      onTaskClick: onTaskClick
                    }, null, 8, ["task", "class", "onClaimTask"]))
                  }), 128))
                : _renderSlot(_ctx.$slots, "default", { key: 1 })
            ], 64))
      ], 2))
    : _createCommentVNode("", true)
}
}

})