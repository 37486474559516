/**
 * This utils cover basic functionality for displaying PWA install prompt:
 * detect safari browser and standalone mode
 * once you need more sophisticated UA parsing, check:
 * https://github.com/faisalman/ua-parser-js/blob/master/src/main/ua-parser.js
 * https://github.com/philfung/add-to-homescreen/blob/main/src/index.ts
 */

function _matchesUserAgent(regex: RegExp): boolean {
    return !!window.navigator.userAgent.match(regex)
}

/* Mozilla/5.0 (iPhone; CPU iPhone OS 10_3 like Mac OS X)
     AppleWebKit/602.1.50 (KHTML, like Gecko) CriOS/56.0.2924.75
     Mobile/14E5239e Safari/602.1 */
export function isBrowserIOSChrome(): boolean {
    return isDeviceIOS() && _matchesUserAgent(/CriOS/)
}

/* Mozilla/5.0 (iPhone; CPU iPhone OS 16_5 like Mac OS X)
  AppleWebKit/605.1.15 (KHTML, like Gecko) FxiOS/114.1 Mobile/15E148 Safari/605.1.15 */
export function isBrowserIOSFirefox(): boolean {
    return isDeviceIOS() && _matchesUserAgent(/FxiOS/)
}

export function isDesktopMac(): boolean {
    return window.navigator.userAgent.includes('Macintosh')
}

export function isDeviceIOS(): boolean {
    return _matchesUserAgent(/iPhone|iPad|iPod/) || isBrowserIOSIPadSafari()
}

export function isBrowserIOSIPadSafari(): boolean {
    return !!(
        _matchesUserAgent(/iPad/) || // iPad Mini
        // iPad Air, iPad Pro
        (_matchesUserAgent(/Macintosh/) && navigator.maxTouchPoints && navigator.maxTouchPoints > 1)
    )
}

/* Mozilla/5.0 (iPhone; CPU iPhone OS 10_3 like Mac OS X)
   AppleWebKit/603.1.23 (KHTML, like Gecko) Version/10.0
   Mobile/14E5239e Safari/602.1 */
export function isBrowserIOSSafari(): boolean {
    return isDeviceIOS() && _matchesUserAgent(/Safari/) && !isBrowserIOSChrome() && !isBrowserIOSFirefox()
}

export function isDesktopSafari(): boolean {
    const isSafari =
        window.navigator.userAgent.includes('Safari') &&
        !window.navigator.userAgent.includes('Chrome') &&
        !window.navigator.userAgent.includes('Edg')

    return isSafari && isDesktopMac()
}

export function isDesktopChrome(): boolean {
    const isChrome = window.navigator.userAgent.includes('Chrome') && !window.navigator.userAgent.includes('Edg') // Exclude Edge browser
    const isDesktop =
        window.navigator.userAgent.includes('Windows') ||
        window.navigator.userAgent.includes('Macintosh') ||
        window.navigator.userAgent.includes('Linux')

    return isChrome && isDesktop
}

export function isDesktopEdge(): boolean {
    return window.navigator.userAgent.includes('Edg/')
}

export function isPWARuntime(): boolean {
    if (typeof window === 'undefined' || !window.navigator) {
        return false
    }

    return (
        !!('standalone' in window.navigator && window.navigator.standalone) || // IOS (TODO: detect iPad 13)
        !!window.matchMedia('(display-mode: standalone)').matches
    ) // Android and Desktop Chrome/Safari/Edge
}
