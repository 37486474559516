import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers, createElementBlock as _createElementBlock } from "vue"

import { computed } from 'vue'
import type {
    DailyTaskDto,
    GameTaskDto,
    RandomTaskDto,
    ReferralTaskDto,
    ShortGameType,
    TaskDto,
    TaskStatus,
    TaskType,
} from '@/types'
import Button from '@/components_new/Button.vue'
import Typography from '@/components_new/Typography.vue'
import Icon from '@/components_new/Icon/Icon.vue'
import { useCategoriesStore } from '@/store/categories-store'
import { storeToRefs } from 'pinia'
import { useTr } from '@/composables/use-tr'
import { RouteLocationRaw } from 'vue-router'
import { ROUTE_NAMES } from '@/router/constants'

interface TaskProps {
    task: TaskDto
}

type Task = {
    id: string
    status: TaskStatus
    coins: number
    description: string
    buttonLabel: string
    gameLink?: RouteLocationRaw
    // we might not need this game key anywhere
    game?: ShortGameType
    type: TaskType
}


export default /*@__PURE__*/_defineComponent({
  __name: 'TaskWidget',
  props: {
    task: {}
  },
  emits: ["claimTask", "taskStart", "taskClick"],
  setup(__props: any, { emit: __emit }) {

const props = __props

const emit = __emit
const tr = useTr()

const categoriesStore = useCategoriesStore()
const { randomGameHRU } = storeToRefs(categoriesStore)

function getDailyBonusTask(task: DailyTaskDto): Task {
    const { status, coins, task_id, type } = task
    return {
        status,
        coins,
        id: task_id,
        type,
        buttonLabel: tr('tasks.claim'),
        description: tr('tasks.daily_bonus_description'),
    }
}

function getReferralTask(task: ReferralTaskDto): Task {
    const {
        status,
        coins,
        task_id,
        type,
        settings: { invitations_not_claimed },
    } = task
    const coinsPerFriend = invitations_not_claimed > 1 ? Math.floor(coins / invitations_not_claimed) : coins
    const description =
        invitations_not_claimed > 1
            ? tr('tasks.collect_invite_widget_many', {
                  friends: String(invitations_not_claimed),
                  coins: String(coinsPerFriend),
              })
            : tr('tasks.collect_invite_widget', { coins: String(coinsPerFriend) })
    return {
        status,
        coins,
        id: task_id,
        type,
        buttonLabel: tr('tasks.claim'),
        description,
    }
}

function getRandomGameTask(task: RandomTaskDto): Task {
    const { status, coins, settings, task_id, type } = task
    const min = Math.ceil(settings.seconds_to_play / 60)
    return {
        status,
        type,
        id: task_id,
        coins,
        description: tr('tasks.random_game_description', { min: String(min) }),
        buttonLabel: tr('tasks.play'),
        ...(randomGameHRU.value && {
            gameLink: { name: ROUTE_NAMES.GAME_PAGE, params: { game: randomGameHRU.value } },
        }),
    }
}

function getGameTask(task: GameTaskDto): Task {
    const {
        status,
        coins,
        settings: { game, seconds_to_play },
        task_id,
        type,
    } = task
    const min = Math.ceil(seconds_to_play / 60)
    return {
        status,
        type,
        coins,
        id: task_id,
        game,
        description: game.title,
        buttonLabel: tr('tasks.button_label', { min: String(min) }),
        ...(game.hru && { gameLink: { name: ROUTE_NAMES.GAME_PAGE, params: { game: game.hru } } }),
    }
}

const task = computed(() => {
    if (props.task.type === 'VISIT_EVERYDAY') {
        return getDailyBonusTask(props.task)
    }

    if (props.task.type === 'RANDOM_GAME') {
        return getRandomGameTask(props.task)
    }

    if (props.task.type === 'PLAY_GAME') {
        return getGameTask(props.task)
    }
    return getReferralTask(props.task)
})

const activeTask = computed(() => task.value.status === 'ACTIVE')

function onClaim() {
    emit('claimTask', task.value.id)
}

function onTaskClick() {
    if (activeTask.value && task.value.gameLink) {
        emit('taskStart', { taskId: task.value.id, link: task.value.gameLink })
    } else {
        emit('taskClick')
    }
}

return (_ctx: any,_cache: any) => {
  return (task.value)
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: _normalizeClass(_ctx.$style.task),
        onClick: onTaskClick
      }, [
        _createElementVNode("div", {
          class: _normalizeClass(_ctx.$style.task_content)
        }, [
          _createVNode(Typography, {
            type: "caption_all-caps",
            class: _normalizeClass(_ctx.$style.description),
            responsive: false
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(task.value.description), 1)
            ]),
            _: 1
          }, 8, ["class"]),
          _createElementVNode("div", {
            class: _normalizeClass(_ctx.$style.fennecs)
          }, [
            _createVNode(Icon, { name: "silver_fennec_coin_small" }),
            _createVNode(Typography, {
              type: "header",
              size: "s",
              active: "",
              responsive: false
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(task.value.coins), 1)
              ]),
              _: 1
            })
          ], 2)
        ], 2),
        (activeTask.value)
          ? (_openBlock(), _createBlock(Button, {
              key: 0,
              interfaceTarget: "active-task",
              size: "m",
              color: "purple-400"
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(task.value.buttonLabel), 1)
              ]),
              _: 1
            }))
          : (_openBlock(), _createBlock(Button, {
              key: 1,
              color: "golden",
              interfaceTarget: "claim-task",
              size: "m",
              onClick: _withModifiers(onClaim, ["stop"])
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$tr('tasks.claim')), 1)
              ]),
              _: 1
            }))
      ], 2))
    : _createCommentVNode("", true)
}
}

})