// category icons
import Action from './categories-icons/action.svg?inline'
import AllGames from './categories-icons/all_games.svg?inline'
import AllSeries from './categories-icons/all_series.svg?inline'
import AllTags from './categories-icons/all_tags.svg?inline'
import Balloons from './categories-icons/balloons.svg?inline'
import Boys from './categories-icons/boys.svg?inline'
import Solitaire from './categories-icons/solitaire.svg?inline'
import Clicker from './categories-icons/clicker.svg?inline'
import Idle from './categories-icons/idle.svg?inline'
import Skill from './categories-icons/skill.svg?inline'
import Girls from './categories-icons/girls.svg?inline'
import Horror from './categories-icons/horror.svg?inline'
import Io from './categories-icons/io.svg?inline'
import New from './categories-icons/new.svg?inline'
import Puzzle from './categories-icons/puzzle.svg?inline'
import Car from './categories-icons/car.svg?inline'
import Random from './categories-icons/random.svg?inline'
import Recommended from './categories-icons/recommended.svg?inline'
import Shooting from './categories-icons/shooting.svg?inline'
import Simulation from './categories-icons/simulation.svg?inline'
import Soccer from './categories-icons/soccer.svg?inline'
import Sports from './categories-icons/sports.svg?inline'
import Strategy from './categories-icons/strategy.svg?inline'
import TrendingNow from './categories-icons/trending_now.svg?inline'
import TwoPlayer from './categories-icons/two_player.svg?inline'
import Arcade from './categories-icons/arcade.svg?inline'
import Multiplayer from './categories-icons/multiplayer.svg?inline'
import Snake from './categories-icons/snake.svg?inline'
import Gun from './categories-icons/gun.svg?inline'
import Drawing from './categories-icons/drawing.svg?inline'
import Funny from './categories-icons/funny.svg?inline'
// common icons
import Check from './icons/check.svg?inline'
import ChevronDown from './icons/chevron-down.svg?inline'
import ChevronL from './icons/chevron-l.svg?inline'
import ChevronR from './icons/chevron-r.svg?inline'
import ChevronUp from './icons/chevron-up.svg?inline'
import Close from './icons/close.svg?inline'
import Collapse from './icons/collapse.svg?inline'
import FennekCircle from './icons/fennek-circle.svg?inline'
import Fennek from './icons/fennek.svg?inline'
import Fullscreen from './icons/fullscreen.svg?inline'
import Hand from './icons/hand.svg?inline'
import Help from './icons/help.svg?inline'
import Home from './icons/home.svg?inline'
import Key from './icons/key.svg?inline'
import Loader from './icons/loader.svg?inline'
import Logout from './icons/logout.svg?inline'
import Menu from './icons/menu.svg?inline'
import Play from './icons/play.svg?inline'
import Purchase from './icons/purchase.svg?inline'
import Report from './icons/report.svg?inline'
import Sdk from './icons/sdk.svg?inline'
import Send from './icons/send.svg?inline'
import Share from './icons/share.svg?inline'
import Timer from './icons/timer.svg?inline'
import Trash from './icons/trash.svg?inline'
import UserAdd from './icons/user-add.svg?inline'
import Wallet from './icons/wallet.svg?inline'
import Shop from './icons/shop.svg?inline'
import GoldFennecCoinSmall from './icons/gold-fennec-coin-small.webp'
import GoldFennecCoinLarge from './icons/gold-fennec-coin-large.webp'
import SilverFennecCoinLarge from './icons/silver-fennec-coin-large.webp'
import SilverFennecCoinSmall from './icons/silver-fennec-coin-small.webp'
import LinkedIn from './icons/linked-in.svg?inline'
import Plus from './icons/plus.svg?inline'
import Discord from './icons/discord.svg?inline'
import Telegram from './icons/telegram.svg?inline'
import Facebook from './icons/facebook.svg?inline'
import CompleteReg from './icons/complete_reg.svg?inline'
import Smile from './icons/smile.svg?inline'
import Grid from './icons/grid.svg?inline'
// Added new SVG — run `npx svgo -f src/components_new/Icon/icons`

export const icons = {
    // category icons
    action: Action,
    arcade: Arcade,
    all_games: AllGames,
    all_series: AllSeries,
    all_tags: AllTags,
    boys: Boys,
    'bubble-shooter': Balloons,
    solitaire: Solitaire,
    car: Car,
    clicker: Clicker,
    idle: Idle,
    skill: Skill,
    girls: Girls,
    horror: Horror,
    io: Io,
    multiplayer: Multiplayer,
    new: New,
    puzzle: Puzzle,
    random: Random,
    recommended: Recommended,
    shooting: Shooting,
    simulation: Simulation,
    soccer: Soccer,
    sports: Sports,
    strategy: Strategy,
    trending_now: TrendingNow,
    'two-player': TwoPlayer,
    snake: Snake,
    funny: Funny,
    gun: Gun,
    drawing: Drawing,
    // common icons
    check: Check,
    chevron_down: ChevronDown,
    chevron_l: ChevronL,
    chevron_r: ChevronR,
    chevron_up: ChevronUp,
    close: Close,
    purchase: Purchase,
    complete_reg: CompleteReg,
    collapse: Collapse,
    fennek_circle: FennekCircle,
    fennek: Fennek,
    fullscreen: Fullscreen,
    hand: Hand,
    help: Help,
    home: Home,
    key: Key,
    loader: Loader,
    logout: Logout,
    menu: Menu,
    play: Play,
    report: Report,
    sdk: Sdk,
    send: Send,
    share: Share,
    timer: Timer,
    trash: Trash,
    user_add: UserAdd,
    wallet: Wallet,
    gold_fennec_coin_small: GoldFennecCoinSmall,
    gold_fennec_coin_large: GoldFennecCoinLarge,
    silver_fennec_coin_small: SilverFennecCoinSmall,
    silver_fennec_coin_large: SilverFennecCoinLarge,
    shop: Shop,
    linked_in: LinkedIn,
    plus: Plus,
    discord: Discord,
    telegram: Telegram,
    facebook: Facebook,
    smile: Smile,
    grid: Grid,
}

export type IconNameType = keyof typeof icons
