import { defineStore } from 'pinia'
import { ref } from 'vue'
import type { User, UserSocialsType, TaskDto } from '@/types'
import { request } from '@/utils/requests/request'
import { claimTask, getTasks } from '@/utils/requests/tasks'
import { sortTaskByPriority } from '@/store/user-store/utils'
import { useLayoutStore } from '../layout-store'

const DEFAULT_COINS = 1000
const MINIMUM_PURCHASE = 10

export const useUserStore = defineStore('user', () => {
    const user = ref<User | null>(null)
    const userLoadingPromise = ref<Promise<User | null> | null>(null)
    const isUserInfoLoaded = ref(false)
    const isAuthorized = ref<boolean>(false)
    const tasks = ref<TaskDto[]>([])
    const tasksError = ref<boolean>(false)
    const userBalance = ref<number>(0)

    const layoutStore = useLayoutStore()

    function fetchUserInfo(): Promise<User | null> {
        isUserInfoLoaded.value = false
        userLoadingPromise.value = Promise.resolve().then(async () => {
            let data
            if (typeof window !== 'undefined') {
                ;({ data } = await request<User>('/api/v1/user/current'))
            }
            isUserInfoLoaded.value = true
            if (!data) {
                isAuthorized.value = false
                user.value = null
                return null
            }

            isAuthorized.value = true
            user.value = data
            return data
        })

        return userLoadingPromise.value
    }

    function getUser(): Promise<User | null> {
        if (userLoadingPromise.value) {
            return userLoadingPromise.value
        }

        if (typeof window !== 'undefined' && window?.additionalServerData?.hasToken !== false) {
            userLoadingPromise.value = fetchUserInfo()
        } else {
            userLoadingPromise.value = Promise.resolve(null)
            isUserInfoLoaded.value = true
        }

        return userLoadingPromise.value
    }

    async function authorizeUser(): ReturnType<typeof getUser> {
        await getUser()
        if (!user.value) {
            await new Promise<void>((resolve) => {
                layoutStore.setSignInModalOpened(true)
                layoutStore.$subscribe((mutation, state) => {
                    if (mutation.type === 'direct' && !state.signInModalOpened) {
                        fetchUserInfo().then(() => resolve())
                    }
                })
            })
        }

        if (user.value) {
            return { ...user.value }
        }

        throw new Error("User didn't complete login")
    }

    async function chargeUser(amount: number): ReturnType<typeof getUser> {
        if (user.value && amount) {
            await new Promise<void>((resolve) => {
                layoutStore.setPaymentModalAmount(
                    Math.max(amount - (user.value?.goldFenecBalance || 0), MINIMUM_PURCHASE),
                )
                layoutStore.$subscribe((mutation, state) => {
                    if (mutation.type === 'direct' && state.paymentModalAmount === 0) {
                        fetchUserInfo().then(() => {
                            resolve()
                        })
                    }
                })
            })
        }
        return fetchUserInfo()
    }

    async function getUserTasks() {
        try {
            const data = await getTasks()
            tasksError.value = false
            tasks.value = sortTaskByPriority(data.tasks)
            userBalance.value = data.wallet.coins
        } catch {
            tasksError.value = true
            tasks.value = []
            userBalance.value = DEFAULT_COINS
        }
    }

    async function claimUserTasks(taskId: string): Promise<void> {
        const oldUserBalance = userBalance.value
        const oldTasksData = tasks.value

        userBalance.value += tasks.value.find(({ task_id }) => task_id === taskId)!.coins
        tasks.value = tasks.value.map((task) => {
            if (task.task_id === taskId) {
                return { ...task, status: 'CLAIMED' }
            }
            return task
        })

        try {
            const data = await claimTask(taskId)
            tasks.value = sortTaskByPriority(data.tasks)
            userBalance.value = data.wallet.coins
        } catch {
            tasks.value = oldTasksData
            userBalance.value = oldUserBalance
            tasksError.value = true
        }
    }

    async function logout() {
        userLoadingPromise.value = Promise.resolve(null)
        await request('/api/v1/auth/logout', { method: 'post' })
        user.value = null
        isAuthorized.value = false
        tasks.value = []
    }

    async function updateSocials({ key, account }: UserSocialsType) {
        const { originalResponse } = await request('/api/v1/user', {
            method: 'post',
            body: JSON.stringify({
                [key]: account,
            }),
        })
        if (originalResponse.ok) {
            user.value![key] = account
        } else {
            // ???
        }
    }

    return {
        user,
        isAuthorized,
        isUserInfoLoaded,
        getUser,
        getUserTasks,
        tasks,
        logout,
        updateSocials,
        tasksError,
        userBalance,
        claimUserTasks,
        authorizeUser,
        fetchUserInfo,
        chargeUser,
    }
})
