import type { RouteLocationNormalized, NavigationGuardNext } from 'vue-router'
import { useUserStore } from '@/store/user-store/user-store'
import { ROUTE_NAMES } from './constants'

// It always redirects everyone, which is why it works incorrectly - we will also fix this after we teach SSR to recognize the user.
export async function nonAuthorizedOnlyGuard(
    _to: RouteLocationNormalized,
    _from: RouteLocationNormalized,
    next: NavigationGuardNext,
): Promise<void> {
    const userStore = useUserStore()
    const user = await userStore.getUser()
    if (user) {
        next({ name: ROUTE_NAMES.MAIN })
    }
    next()
}

/* It doesn't work because the server doesn't know anything about the client yet - it will be fixed when we start passing cookies with SSR.
For now, we are implementing the guard at the component level of UserProfile in onMounted */
/* export const authorizedOnlyGuard = ({ redirectTo }:{redirectTo: string}) => (_to: RouteLocationNormalized, _from: RouteLocationNormalized, next: NavigationGuardNext) => {
    const userStore = useUserStore()
    userStore.getUser().then((user) => {
        if (!user) {
            next(redirectTo)
        }
        next()
    })
} */
