import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Transition as _Transition } from "vue"

import { ref } from 'vue'
import { storeToRefs } from 'pinia'
import Button from '@/components_new/Button.vue'
import Icon from '@/components_new/Icon/Icon.vue'
import MobileHeader from '@/components_new/MobileHeader.vue'
import { useCategoriesStore } from '@/store/categories-store'
import { useLayoutStore } from '@/store/layout-store'
import Typography from '@/components_new/Typography.vue'
import BaseLink from '@/components_new/BaseLink.vue'
import { DEVELOPERS_LANDPAGE_PATH, NAVIGATION_LINKS, NAVIGATION_ALL_GAMES_LINKS } from '@/router/navigation-constants'
import { useDynamicHover } from './dynamicHover'
import { ROUTE_NAMES } from '@/router/constants'


export default /*@__PURE__*/_defineComponent({
  __name: 'ModalCategories',
  setup(__props) {

const layoutStore = useLayoutStore()
const { setCategoriesModalOpened } = layoutStore

const { categoriesModalOpened } = storeToRefs(layoutStore)

const categoriesStore = useCategoriesStore()

const { menuCategoriesList, menuHighlightCategoriesList, randomGameHRU } = storeToRefs(categoriesStore)

const dialogOnMount = (el: unknown | null) => (el as HTMLDialogElement)?.showModal()

// trigger exit animation
function requestCloseModal(e?: Event) {
    e?.preventDefault()
    setCategoriesModalOpened(false)
}

const scrollTop = ref(0)
function onBeforeEnter() {
    scrollTop.value = document.documentElement.scrollTop
    requestAnimationFrame(() => {
        document.body.scrollTop = scrollTop.value
        // ios safari has bug with bottom navigation pannel.
        // Sometimes it causes dialog element
        // to be shifted up after applying "overflow: hidden" on body.
        // Solution: set random positive scroll after repaint
        document.documentElement.scrollTop = 1
    })
}

function onAfterLeave() {
    document.body.scrollTop = 0
    document.documentElement.scrollTop = scrollTop.value
}

function clickLink() {
    requestCloseModal()
}

function clickRandomGame() {
    categoriesStore.updateRandomGame()
    clickLink()
}

function outsideClick(e: MouseEvent) {
    if (e.target === e.currentTarget) {
        requestCloseModal(e)
    }
}

const { itemDynamicHover, updateItemDynamicHoverPositionDebounced, updateItemDynamicHoverPosition } = useDynamicHover()

function itemMouseEnterOrFocus(e: (MouseEvent | KeyboardEvent) & { currentTarget: HTMLElement }) {
    const { currentTarget } = e
    if ('pageX' in e) {
        updateItemDynamicHoverPositionDebounced({
            pageX: e.pageX,
            pageY: e.pageY,
            currentTarget,
        })
    } else {
        updateItemDynamicHoverPositionDebounced({ currentTarget })
    }
}

function itemMouseMove(e: MouseEvent & { currentTarget: HTMLElement }) {
    const { currentTarget } = e
    updateItemDynamicHoverPosition({ pageX: e.pageX, pageY: e.pageY, currentTarget })
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_Transition, {
    enterFromClass: _ctx.$style.transition_enter_from,
    enterActiveClass: _ctx.$style.transition_enter_active,
    leaveActiveClass: _ctx.$style.transition_leave_active,
    leaveToClass: _ctx.$style.transition_leave_to,
    appear: "",
    onBeforeEnter: onBeforeEnter,
    onAfterLeave: onAfterLeave
  }, {
    default: _withCtx(() => [
      (_unref(categoriesModalOpened))
        ? (_openBlock(), _createElementBlock("dialog", {
            key: 0,
            ref: dialogOnMount,
            class: _normalizeClass(_ctx.$style.modal),
            "data-interface-section": "modal-categories",
            onCancel: requestCloseModal
          }, [
            _createElementVNode("div", {
              class: _normalizeClass(_ctx.$style.mobile_header)
            }, [
              _createVNode(MobileHeader, { categoriesModalMode: "" })
            ], 2),
            _createElementVNode("div", {
              class: _normalizeClass(_ctx.$style.modalBody)
            }, [
              _createVNode(Button, {
                icon: "close",
                color: "carbon-400",
                class: _normalizeClass(_ctx.$style.close),
                interfaceTarget: "close",
                onClick: requestCloseModal
              }, null, 8, ["class"]),
              _createVNode(Button, {
                externalTo: _unref(DEVELOPERS_LANDPAGE_PATH),
                target: "_blank",
                iconLeft: "plus",
                external: "",
                interfaceTarget: "navigation-link",
                class: _normalizeClass(_ctx.$style.add_game)
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$tr('modalCategories.add_game')), 1)
                ]),
                _: 1
              }, 8, ["externalTo", "class"]),
              _createElementVNode("div", {
                class: _normalizeClass(_ctx.$style.scroller),
                onClick: outsideClick
              }, [
                _createElementVNode("div", {
                  class: _normalizeClass(_ctx.$style.grid_wrap),
                  onClick: outsideClick
                }, [
                  _createElementVNode("div", {
                    ref_key: "itemDynamicHover",
                    ref: itemDynamicHover,
                    class: _normalizeClass(_ctx.$style.item_dynamic_hover)
                  }, null, 2),
                  _createElementVNode("div", {
                    class: _normalizeClass(_ctx.$style.grid)
                  }, [
                    _createVNode(BaseLink, {
                      to: { name: _unref(ROUTE_NAMES).SHOP },
                      class: _normalizeClass(_ctx.$style.item),
                      "data-interface-target": "shop-link",
                      onClick: clickLink,
                      onMouseenter: itemMouseEnterOrFocus,
                      onMousemove: itemMouseMove,
                      onFocus: itemMouseEnterOrFocus
                    }, {
                      default: _withCtx(() => [
                        _createVNode(Icon, {
                          name: "shop",
                          class: _normalizeClass(_ctx.$style.item_icon)
                        }, null, 8, ["class"]),
                        _createVNode(Typography, {
                          type: "label",
                          size: "m",
                          accent: true,
                          responsive: false
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.$tr('modalCategories.shop')), 1)
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    }, 8, ["to", "class"]),
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(NAVIGATION_ALL_GAMES_LINKS)(_ctx.$tr), (navItem) => {
                      return (_openBlock(), _createBlock(BaseLink, {
                        key: navItem.title,
                        "data-interface-target": "navigation-link",
                        to: navItem.to,
                        class: _normalizeClass(_ctx.$style.item),
                        onClick: clickLink,
                        onMouseenter: itemMouseEnterOrFocus,
                        onMousemove: itemMouseMove,
                        onFocus: itemMouseEnterOrFocus
                      }, {
                        default: _withCtx(() => [
                          _createVNode(Icon, {
                            name: navItem.icon,
                            class: _normalizeClass(_ctx.$style.item_icon)
                          }, null, 8, ["name", "class"]),
                          _createVNode(Typography, {
                            type: "label",
                            size: "m",
                            accent: true,
                            responsive: false
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(navItem.title), 1)
                            ]),
                            _: 2
                          }, 1024)
                        ]),
                        _: 2
                      }, 1032, ["to", "class"]))
                    }), 128)),
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(menuHighlightCategoriesList), (item, index) => {
                      return (_openBlock(), _createElementBlock(_Fragment, {
                        key: item.title
                      }, [
                        (index === 1)
                          ? (_openBlock(), _createBlock(BaseLink, {
                              key: 0,
                              to: { name: _unref(ROUTE_NAMES).GAME_PAGE, params: { game: _unref(randomGameHRU) } },
                              class: _normalizeClass(_ctx.$style.item),
                              "data-interface-target": "random-game",
                              onClick: clickRandomGame,
                              onMouseenter: itemMouseEnterOrFocus,
                              onMousemove: itemMouseMove,
                              onFocus: itemMouseEnterOrFocus
                            }, {
                              default: _withCtx(() => [
                                _createVNode(Icon, {
                                  name: "random",
                                  class: _normalizeClass(_ctx.$style.item_icon)
                                }, null, 8, ["class"]),
                                _createVNode(Typography, {
                                  type: "label",
                                  size: "m",
                                  accent: true,
                                  responsive: false
                                }, {
                                  default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(_ctx.$tr('modalCategories.random')), 1)
                                  ]),
                                  _: 1
                                })
                              ]),
                              _: 2
                            }, 1032, ["to", "class"]))
                          : _createCommentVNode("", true),
                        (item.location)
                          ? (_openBlock(), _createBlock(BaseLink, {
                              key: 1,
                              "data-interface-target": "category",
                              to: item.location,
                              class: _normalizeClass(_ctx.$style.item),
                              onClick: clickLink,
                              onMouseenter: itemMouseEnterOrFocus,
                              onMousemove: itemMouseMove,
                              onFocus: itemMouseEnterOrFocus
                            }, {
                              default: _withCtx(() => [
                                _createVNode(Icon, {
                                  name: item.id,
                                  class: _normalizeClass(_ctx.$style.item_icon)
                                }, null, 8, ["name", "class"]),
                                _createVNode(Typography, {
                                  type: "label",
                                  size: "m",
                                  accent: true,
                                  responsive: false
                                }, {
                                  default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(item.title), 1)
                                  ]),
                                  _: 2
                                }, 1024)
                              ]),
                              _: 2
                            }, 1032, ["to", "class"]))
                          : _createCommentVNode("", true)
                      ], 64))
                    }), 128)),
                    _createVNode(BaseLink, {
                      to: _unref(DEVELOPERS_LANDPAGE_PATH),
                      target: "_blank",
                      "data-interface-target": "navigation-link",
                      class: _normalizeClass([_ctx.$style.item, _ctx.$style.mobile]),
                      onMouseenter: itemMouseEnterOrFocus,
                      onMousemove: itemMouseMove,
                      onFocus: itemMouseEnterOrFocus
                    }, {
                      default: _withCtx(() => [
                        _createVNode(Icon, {
                          name: "plus",
                          class: _normalizeClass(_ctx.$style.item_icon)
                        }, null, 8, ["class"]),
                        _createVNode(Typography, {
                          type: "label",
                          size: "m",
                          accent: "",
                          responsive: false
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.$tr('modalCategories.add_game')), 1)
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    }, 8, ["to", "class"])
                  ], 2),
                  _createElementVNode("div", {
                    class: _normalizeClass(_ctx.$style.sep)
                  }, null, 2),
                  _createElementVNode("div", {
                    class: _normalizeClass(_ctx.$style.grid)
                  }, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(menuCategoriesList), (item) => {
                      return (_openBlock(), _createElementBlock(_Fragment, {
                        key: item.id
                      }, [
                        (item.location)
                          ? (_openBlock(), _createBlock(BaseLink, {
                              key: 0,
                              to: item.location,
                              "data-interface-target": "navigation-link",
                              class: _normalizeClass(_ctx.$style.item),
                              onClick: clickLink,
                              onMouseenter: itemMouseEnterOrFocus,
                              onMousemove: itemMouseMove,
                              onFocus: itemMouseEnterOrFocus
                            }, {
                              default: _withCtx(() => [
                                _createVNode(Icon, {
                                  name: item.id,
                                  class: _normalizeClass(_ctx.$style.item_icon)
                                }, null, 8, ["name", "class"]),
                                _createVNode(Typography, {
                                  type: "label",
                                  size: "m",
                                  accent: true,
                                  responsive: false
                                }, {
                                  default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(item.title), 1)
                                  ]),
                                  _: 2
                                }, 1024)
                              ]),
                              _: 2
                            }, 1032, ["to", "class"]))
                          : _createCommentVNode("", true)
                      ], 64))
                    }), 128))
                  ], 2),
                  _createElementVNode("div", {
                    class: _normalizeClass([_ctx.$style.sep, _ctx.$style.non_desktop])
                  }, null, 2),
                  _createElementVNode("div", {
                    class: _normalizeClass([_ctx.$style.grid, _ctx.$style.non_desktop])
                  }, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(NAVIGATION_LINKS)(_ctx.$tr), (item) => {
                      return (_openBlock(), _createBlock(BaseLink, {
                        key: item.title,
                        externalTo: item.externalTo,
                        target: item.target,
                        "data-interface-target": "navigation-link",
                        class: _normalizeClass(_ctx.$style.item),
                        onClick: clickLink,
                        onMouseenter: itemMouseEnterOrFocus,
                        onMousemove: itemMouseMove,
                        onFocus: itemMouseEnterOrFocus
                      }, {
                        default: _withCtx(() => [
                          _createVNode(Typography, {
                            type: "label",
                            size: "m",
                            accent: true,
                            responsive: false
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(item.title), 1)
                            ]),
                            _: 2
                          }, 1024)
                        ]),
                        _: 2
                      }, 1032, ["externalTo", "target", "class"]))
                    }), 128))
                  ], 2)
                ], 2)
              ], 2)
            ], 2)
          ], 34))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["enterFromClass", "enterActiveClass", "leaveActiveClass", "leaveToClass"]))
}
}

})