import type { RouteLocationNormalizedLoaded } from 'vue-router'
import { type GameEvent, GameProviders, type PlatformType } from '@/types'
import { RewardedBanner } from '@/modules/adv/rewarded-banner'
import { DebugAdsProvider } from '@/modules/debug-provider'
import { GameDistributionConnector } from '../gd-connector/gd-connector'
import { GoogleAdsService } from '../google-ads'
import { EmptyAdsProvider } from '../empty-ads'
import {
    AdProviderStatus,
    AdvAction,
    AdvService as AdvProvider,
    type AdvServiceConfig,
    type ManualAdOptions,
    type OutOfPageAdType,
    type PageAdOptions,
} from '../adv'
import EventBus from '../event-bus'
import { type AdProviderType, config } from './config'
import { EventForLoggersType } from '@/types/logger.types'

export type AdMediatorOptions = {
    configKey: keyof typeof config
    targeting?: Record<string, string | string[]>
    logEvent: (event: EventForLoggersType) => void
    route?: RouteLocationNormalizedLoaded
    adRequestTimeoutMs: number
    gamAccount: string
    platform: PlatformType
}

export const INNER_MESSAGE_ID = 'inner_call'
const PREROLL_DELAY_MS = 3000
const DISABLE_FIRST_INTERSTITIAL_MS = 20_000
const OUT_OF_PAGE_TYPES = ['fullscreen', 'interstitial', 'interstitial_preroll', 'rewarded']

type AdStatus = 'start' | 'error' | 'empty' | 'done' | 'open' | 'show' | 'rewarded' | 'close'

type AdResponsePayload = {
    status: AdStatus
    error?: string
}

const TYPE_SETTINGS: Record<AdvAction, OutOfPageAdType> = {
    [AdvAction.preloadRewarded]: 'rewarded',
    [AdvAction.showRewarded]: 'rewarded',
    [AdvAction.preloadInterstitial]: 'interstitial',
    [AdvAction.showInterstitial]: 'interstitial',
}

type ShortGameEvent = {
    data: {
        id: string
        action: AdvAction
        payload?: Record<string, unknown>
        responseToId?: string
        type: 'adv' | 'liveness' | 'error'
    }
}

export class AdMediator {
    private isShowingFullscreenAd = false

    private prerollStatus: AdStatus | undefined

    private currentInterstitialId: string | undefined

    private config: AdProviderType

    private banners: Map<OutOfPageAdType, RewardedBanner>

    private adProvider: AdvProvider

    private prerollTimerId: ReturnType<typeof setTimeout> | undefined

    private interstitialBlockedTimerId: ReturnType<typeof setTimeout> | undefined

    private adFallback: AdvProvider | undefined

    private originalMessages: Map<string, ShortGameEvent>

    private readonly serviceReadyPromise: Promise<AdProviderStatus>

    private logEvent: (event: EventForLoggersType) => void

    private route?: RouteLocationNormalizedLoaded

    private adRequestTimeoutMs: number
    private gamAccount: string
    private platform: PlatformType

    eventBus: EventBus

    constructor(options: AdMediatorOptions) {
        this.config = config[options.configKey]
        this.logEvent = options.logEvent
        this.route = options.route
        this.adRequestTimeoutMs = options.adRequestTimeoutMs
        this.gamAccount = options.gamAccount
        this.platform = options.platform

        this.adProvider = this.getProvider(this.config.adProvider, {
            targeting: options.targeting,
            platform: this.platform,
        })
        if (this.config.fallback) {
            this.adFallback = this.getProvider(this.config.fallback, {
                targeting: options.targeting,
                platform: this.platform,
            })
        }
        this.banners = new Map()
        this.eventBus = new EventBus()
        this.originalMessages = new Map()
        this.serviceReadyPromise = new Promise((res, rej) => {
            this.adProvider.serviceStatus.then(
                () => {
                    res(AdProviderStatus.online)
                },
                () => {
                    if (this.adFallback) {
                        this.adProvider = this.adFallback
                        this.adFallback = undefined
                        this.adProvider.serviceStatus.then(
                            () => {
                                res(AdProviderStatus.online)
                            },
                            () => {
                                rej(AdProviderStatus.offline)
                            },
                        )
                    } else {
                        rej(AdProviderStatus.offline)
                    }
                },
            )
        })
    }

    preloadIntroAds() {
        this.adProvider.preloadIntroAds()
    }

    getProvider(adProvider: GameProviders, advServiceConfig: AdvServiceConfig) {
        switch (adProvider) {
            case GameProviders.GAME_DISTRIBUTION:
                return new GameDistributionConnector()
            case GameProviders.DEBUG_PROVIDER:
                return new DebugAdsProvider()
            case GameProviders.EMPTY_PROVIDER:
                return new EmptyAdsProvider()
            case GameProviders.GOOGLE_AD:
            default:
                return new GoogleAdsService({
                    ...advServiceConfig,
                    gamAccount: this.gamAccount,
                })
        }
    }

    handleMessage(event: GameEvent): Promise<unknown> {
        this.originalMessages.set(event.data.id, event)

        switch (event.data.action) {
            case 'preloadInterstitial':
                return this.preloadAd('interstitial', event.data.id)
            case 'preloadRewarded':
                return this.preloadAd('rewarded', event.data.id)
            case 'showInterstitial':
                return this.handleInterstitial(event.data.id)
            case 'showRewarded':
                this.clearPreroll()
                return this.showAd('rewarded', event.data.id)
            default:
                this.eventBus.dispatch('adMessage', {
                    originalMessageEvent: event as GameEvent,
                    payload: {
                        error: `Unknown message action "${event.data.action}"`,
                    },
                    type: 'error',
                })
                this.originalMessages.delete(event.data.id)
        }

        return Promise.resolve()
    }

    delayPreroll(): Promise<void> {
        clearTimeout(this.prerollTimerId)
        return new Promise((resolve) => {
            this.prerollTimerId = setTimeout(() => {
                resolve(this.showPreroll())
            }, PREROLL_DELAY_MS)
        })
    }

    showPreroll(): Promise<void> {
        this.originalMessages.set(INNER_MESSAGE_ID, {
            data: {
                id: INNER_MESSAGE_ID,
                action: AdvAction.showInterstitial,
                type: 'adv',
            },
        })
        return this.showAd('interstitial_preroll', INNER_MESSAGE_ID)
    }

    async prepareAd(options: ManualAdOptions) {
        const adEvent: Omit<EventForLoggersType, 'action'> = {
            event: 'custom_event',
            eventName: 'ad_request',
            adPlacement: options.type,
            pageName: (this.route?.name as string) || undefined,
        }

        this.logEvent({
            ...adEvent,
            action: 'start',
        })

        try {
            await this.serviceReadyPromise
        } catch {
            this.logEvent({
                ...adEvent,
                action: 'error',
            })
            // eslint-disable-next-line prefer-promise-reject-errors
            return Promise.reject()
        }

        try {
            const banner =
                OUT_OF_PAGE_TYPES.indexOf(options.type) > -1
                    ? await this.adProvider.prepareOutOfPageAd(options.type as OutOfPageAdType)
                    : await this.adProvider.requestPageAd(options as PageAdOptions)
            banner.addEventListener('ready', () => {
                if (banner instanceof RewardedBanner && banner.show) {
                    banner.show()
                }
            })

            banner.addEventListener('empty', () =>
                this.logEvent({
                    ...adEvent,
                    action: 'empty',
                }),
            )

            banner.addEventListener('viewable', () => {
                this.logEvent({
                    ...adEvent,
                    action: 'show',
                })
                this.logEvent({
                    event: 'custom_event',
                    eventName: 'ad_show',
                    adPlacement: options.type,
                    action: 'show',
                    pageName: (this.route?.name as string) || undefined,
                })
            })

            return banner
        } catch {
            this.logEvent({
                ...adEvent,
                action: 'error',
            })
            // eslint-disable-next-line prefer-promise-reject-errors
            return Promise.reject()
        }
    }

    private async showAd(type: OutOfPageAdType, messageId: string) {
        if (this.isShowingFullscreenAd) {
            this.triggerMessage(
                {
                    status: 'error',
                    error: 'Another ad is already open',
                },
                messageId,
            )
            return
        }

        let banner = this.banners.get(type)
        if (!banner) {
            try {
                banner = await this.preloadAd(type, messageId)
            } catch {
                // Messages have been sent from the method
                this.isShowingFullscreenAd = false
                return
            }
        }
        this.listenToRenderedBannerEvents(banner, messageId)

        this.isShowingFullscreenAd = true

        banner.addEventListener('empty', () => {
            // sometimes we don't know a banner is empty till show it.
            // we can't move this code to the general empty banner handler
            // because an ad may be loading while showing other ad
            this.isShowingFullscreenAd = false
            this.triggerMessage({ status: 'close' }, messageId)
        })

        // the banner is in the ready status
        banner.show()

        // we don't have to keep it anymore, listeners are setted
        this.banners.delete(type)
    }

    /**
     * @return Promise<RewardedBanner> - a ready-to-show banner.
     * If there was an error or slot was empty - rejection will be returned
     */
    private async preloadAd(type: OutOfPageAdType, messageId: string): Promise<RewardedBanner> {
        try {
            await this.serviceReadyPromise
        } catch {
            // сегодня без рекламы
            // eslint-disable-next-line prefer-promise-reject-errors
            return Promise.reject()
        }
        const message = this.originalMessages.get(messageId)
        if (!message) {
            // eslint-disable-next-line prefer-promise-reject-errors
            return Promise.reject()
        }
        let banner: RewardedBanner
        this.triggerMessage({ status: 'start' }, messageId)
        this.logEvent({
            event: 'custom_event',
            eventName: 'ad_request',
            adPlacement: type,
            action: 'start',
            pageName: (this.route?.name as string) || undefined,
        })
        // eslint-disable-next-line no-async-promise-executor
        return new Promise(async (resolve, reject) => {
            try {
                banner = await this.adProvider.prepareOutOfPageAd(type, this.adRequestTimeoutMs)
            } catch {
                if (!this.adFallback) {
                    this.triggerMessage({ status: 'error', error: 'Cannot show the ad' }, messageId)
                    // eslint-disable-next-line prefer-promise-reject-errors
                    reject()
                    this.originalMessages.delete(messageId)
                    return
                }
                try {
                    banner = await this.adFallback.prepareOutOfPageAd(type, this.adRequestTimeoutMs)
                } catch {
                    this.triggerMessage({ status: 'error', error: 'Cannot show the ad' }, messageId)
                    // eslint-disable-next-line prefer-promise-reject-errors
                    reject()
                    this.originalMessages.delete(messageId)
                    return
                }
            }

            banner.addEventListener('empty', async () => {
                if (this.adFallback) {
                    let fallback: RewardedBanner
                    try {
                        fallback = await this.adFallback.prepareOutOfPageAd(type, this.adRequestTimeoutMs)
                    } catch {
                        // errors in fallback shouldn't change empty status to error
                        this.triggerMessage({ status: 'empty' }, messageId)
                        // eslint-disable-next-line prefer-promise-reject-errors
                        reject()
                        this.originalMessages.delete(messageId)
                        this.isShowingFullscreenAd = false
                        this.logEvent({
                            event: 'custom_event',
                            eventName: 'ad_request',
                            adPlacement: type,
                            action: 'empty',
                            pageName: (this.route?.name as string) || undefined,
                        })
                        return
                    }
                    fallback.addEventListener('empty', () => {
                        this.triggerMessage({ status: 'empty' }, messageId)
                        // eslint-disable-next-line prefer-promise-reject-errors
                        reject()
                        this.originalMessages.delete(messageId)
                        this.isShowingFullscreenAd = false
                        this.logEvent({
                            event: 'custom_event',
                            eventName: 'ad_request',
                            adPlacement: type,
                            action: 'empty',
                            pageName: (this.route?.name as string) || undefined,
                        })
                    })

                    fallback.addEventListener('ready', () => {
                        this.triggerMessage({ status: 'done' }, messageId)
                        this.banners.set(type, fallback)
                        resolve(fallback)
                    })
                } else {
                    this.triggerMessage({ status: 'empty' }, messageId)
                    this.logEvent({
                        event: 'custom_event',
                        eventName: 'ad_request',
                        adPlacement: type,
                        action: 'empty',
                        pageName: (this.route?.name as string) || undefined,
                    })
                    // eslint-disable-next-line prefer-promise-reject-errors
                    reject()
                }
            })

            banner.addEventListener('ready', () => {
                this.triggerMessage({ status: 'done' }, messageId)
                this.banners.set(type, banner)
                resolve(banner)
            })
        })
    }

    private listenToRenderedBannerEvents(banner: RewardedBanner, messageId: string) {
        const message = this.originalMessages.get(messageId)
        if (!message) {
            return
        }
        const { action } = message.data
        const type = TYPE_SETTINGS[action]

        if (type === 'rewarded') {
            banner.addEventListener('rewarded', () => {
                this.logEvent({
                    event: 'custom_event',
                    eventName: 'ad_request',
                    adPlacement: type,
                    action: 'rewarded',
                    pageName: (this.route?.name as string) || undefined,
                })

                this.triggerMessage({ status: 'rewarded' }, messageId)
            })
        }

        banner.addEventListener('closed', () => {
            this.triggerMessage({ status: 'close' }, messageId)
            this.isShowingFullscreenAd = false
            this.originalMessages.delete(messageId)
            this.logEvent({
                event: 'custom_event',
                eventName: 'ad_request',
                adPlacement: type,
                action: 'close',
                pageName: (this.route?.name as string) || undefined,
            })
        })

        banner.addEventListener('rendered', () => {
            this.triggerMessage({ status: 'open' }, messageId)
        })

        banner.addEventListener('viewable', () => {
            this.logEvent({
                event: 'custom_event',
                eventName: 'ad_request',
                adPlacement: type,
                action: 'show',
                pageName: (this.route?.name as string) || undefined,
            })

            this.logEvent({
                event: 'custom_event',
                eventName: 'ad_show',
                adPlacement: type,
                action: 'show',
                pageName: (this.route?.name as string) || undefined,
            })

            this.triggerMessage({ status: 'show' }, messageId)
        })
    }

    private triggerMessage(payload: AdResponsePayload, messageId: string) {
        const isInnerMessageId = messageId === INNER_MESSAGE_ID
        if (isInnerMessageId) {
            this.dispatchInnerMessage(payload.status)
        }
        if (payload.status === 'close') {
            this.eventBus.dispatch('adClose', {})
        }

        const message = this.originalMessages.get(messageId)
        if (!message || isInnerMessageId) {
            return
        }
        const { action } = message.data

        this.eventBus.dispatch('adMessage', {
            action,
            type: 'adv',
            originalMessageEvent: message,
            payload,
        })
    }

    updateTargeting(targeting: AdvServiceConfig['targeting']) {
        this.adProvider.updateTargeting(targeting)
        if (this.adFallback) {
            this.adFallback.updateTargeting(targeting)
        }
    }

    private handleInterstitial(id: string): Promise<void> {
        // interstitial blocked - put fake events to game for 20 sec
        if (this.interstitialBlockedTimerId) {
            return Promise.resolve(this.handleBlockedInterstitial(id))
        }
        // preroll hasn't started  - show interstitial_preroll with ad id from game
        if (this.prerollTimerId && !this.prerollStatus) {
            this.clearPreroll()
            return this.showAd('interstitial_preroll', id)
        }
        // cleared proroll and no block time - show interstitial from game
        if (!this.prerollTimerId) {
            return this.showAd('interstitial', id)
        }
        // in other cases remember current interstitial id
        this.currentInterstitialId = id
        // watch in progress preroll in case interstitialId from adv game we get after 'show' status dispatched
        return Promise.resolve(this.handleInProgressPreroll())
    }

    private handleBlockedInterstitial(id: string) {
        this.triggerMessage({ status: 'start' }, id)
        setTimeout(() => {
            this.triggerMessage({ status: 'done' }, id)
        }, 300)
        setTimeout(() => {
            this.triggerMessage({ status: 'open' }, id)
        }, 600)
        setTimeout(() => {
            this.triggerMessage({ status: 'show' }, id)
        }, 1_000)
        setTimeout(() => {
            this.triggerMessage({ status: 'close' }, id)
            this.originalMessages.delete(id)
            this.currentInterstitialId = undefined
        }, 3_000)
    }
    // we don't close interstital ad if preroll in progress to prevent resuming game - we wait dispatching status 'close' from preroll
    private fakeShowCurrentInterstitial() {
        const id = this.currentInterstitialId as string
        this.triggerMessage({ status: 'start' }, id)
        this.triggerMessage({ status: 'done' }, id)
        this.triggerMessage({ status: 'open' }, id)
        this.triggerMessage({ status: 'show' }, id)
    }
    // here we catch close status from preroll
    private fakeCloseCurrentInterstitial() {
        const id = this.currentInterstitialId as string
        this.triggerMessage({ status: 'close' }, id)
        this.originalMessages.delete(id)
        this.currentInterstitialId = undefined
    }

    private clearPreroll() {
        clearTimeout(this.prerollTimerId)
        this.prerollTimerId = undefined
        this.prerollStatus = undefined
    }

    private clearInterstitialBlockedTimer() {
        clearTimeout(this.interstitialBlockedTimerId)
        this.interstitialBlockedTimerId = undefined
    }

    private dispatchInnerMessage(status: AdStatus) {
        this.prerollStatus = status
        // watch in progress preroll in case interstitialId from adv game we get before 'show' status dispatched
        this.handleInProgressPreroll()

        if (status === 'close') {
            this.handleClosePreroll()
        }
        if (['empty', 'error'].includes(status)) {
            this.handlePrerollOnErrorOrEmpty()
        }
    }

    /* 
        if we got interstitial while preroll in progress and we still not finish 
        preroll by this time = do not get error/empty/close status
        so we assume that here we can get only if we have status up to 'show' 
    */
    private handleInProgressPreroll() {
        if (this.prerollStatus === 'show' && this.currentInterstitialId) {
            this.fakeShowCurrentInterstitial()
        }
    }

    private handleClosePreroll() {
        this.clearPreroll()
        // if have adv id from game trigger close event for game to resume game - no need 20 sec block
        if (this.currentInterstitialId) {
            this.fakeCloseCurrentInterstitial()
        } else {
            // in other case block adv fo 20 sec
            this.interstitialBlockedTimerId = setTimeout(() => {
                this.clearInterstitialBlockedTimer()
            }, DISABLE_FIRST_INTERSTITIAL_MS)
        }
    }

    // if something happens before showing preroll and by this time we have adv id from game show interstitial
    private handlePrerollOnErrorOrEmpty() {
        this.clearPreroll()
        if (this.currentInterstitialId) {
            this.showAd('interstitial', this.currentInterstitialId)
        }
    }
}
