<template>
    <Teleport to="body">
        <Transition
            :enterActiveClass="$style.transition_enter_active"
            :enterFromClass="$style.transition_enter_from"
            :leaveActiveClass="$style.transition_leave_active"
            :leaveToClass="$style.transition_leave_to"
            appear
        >
            <div
                v-if="toastStore.isVisible && toastStore.toast"
                :class="$style.toast_container"
            >
                <div :class="$style.toast">
                    <Button
                        icon="close"
                        color="white-60"
                        visual="ghost"
                        size="m"
                        :class="$style.close"
                        interfaceTarget="close-toast"
                        @click="toastStore.closeToast"
                    />
                    <div :class="$style.instructions">
                        <div
                            v-if="toastStore.toast.animation"
                            :class="$style.image"
                        >
                            <Vue3Lottie
                                v-if="isMounted"
                                :animationData="toastStore.toast.animation"
                            />
                        </div>

                        <img
                            v-if="toastStore.toast.image"
                            :class="$style.image"
                            :src="toastStore.toast.image"
                            :alt="tr('toast-pwa.description')"
                            loading="lazy"
                        />

                        <Typography
                            type="header"
                            size="xs"
                            accent
                            :responsive="false"
                            :class="$style.header"
                        >
                            {{ toastStore.toast.title }}
                        </Typography>

                        <Typography
                            type="paragraph"
                            size="s"
                            :responsive="false"
                            :class="$style.paragraph"
                        >
                            {{ toastStore.toast.description }}
                        </Typography>

                        <Button
                            v-if="toastStore.toast.buttonText"
                            color="purple-400"
                            size="m"
                            :class="$style.accept"
                            interfaceTarget="accept-toast"
                            @click="toastStore.acceptToast"
                        >
                            {{ toastStore.toast.buttonText }}
                        </Button>
                    </div>
                </div>
            </div>
        </Transition>
    </Teleport>
</template>

<script setup lang="ts">
import { defineAsyncComponent } from 'vue'
import Button from '@/components_new/Button.vue'
import Typography from '@/components_new/Typography.vue'
import { useToastStore } from './store'
import { useIsMounted } from '@/utils/vue-hooks/use-is-mounted'
import { useTr } from '@/composables/use-tr'

const tr = useTr()

// load lottie only if animation is present
const Vue3Lottie = defineAsyncComponent(() => import('vue3-lottie').then((mod) => mod.Vue3Lottie))

const toastStore = useToastStore()
const isMounted = useIsMounted()
</script>

<style module>
.transition_enter_active,
.transition_leave_active {
    pointer-events: none;
    transition: all 0.4s cubic-bezier(0.17, 0.7, 0.17, 1);
    backdrop-filter: blur(16px);
}

.transition_enter_from,
.transition_leave_to {
    opacity: 0;
    transform: translateY(100%);
}

@media (--tablet-plus) {
    .transition_enter_from,
    .transition_leave_to {
        transform: translateX(100%);
    }
}

.toast_container {
    box-sizing: border-box;
    z-index: 9999;
    position: fixed;
    bottom: 0;
    width: 100%;
    min-height: 80px;
    padding: var(--gap-12);

    @media (--tablet-plus) {
        top: var(--gap-12);
        right: var(--gap-12);
        bottom: auto;
        width: 300px;
        padding: 0;
    }
}

.toast {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    max-width: 480px;
    margin: 0 auto;
    padding: var(--gap-16) var(--gap-16) var(--gap-20);
    background: rgba(23, 23, 26, 0.9);
    border-radius: var(--radius-16);
    backdrop-filter: blur(var(--radius-16));
}

.close {
    position: absolute;
    top: 0;
    right: 0;
}

.instructions {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.image {
    height: 120px;
    margin-top: var(--gap-24);
    margin-bottom: var(--gap-12);
    display: flex;
    justify-content: center;
    align-items: center;
}

.header {
    color: var(--white);
    margin-bottom: 6px;
    text-align: center;
}

.paragraph {
    color: var(--white-60);
}

.accept {
    margin-top: var(--gap-16);
}
</style>
