import { computed, shallowRef, type Raw } from 'vue'
import { defineStore, storeToRefs } from 'pinia'
import { AdMediator, type AdMediatorOptions } from '@/modules/adv-service'
import { useConfig } from '@/composables/config'
import { useAppStore } from './app-store'
import { useRoute, type RouteLocationNormalizedLoaded } from 'vue-router'
import { GameProviders } from '@/types'
import { ROUTES_ACCEPTING_NO_ADS_PARAM } from '@/router/constants'
import { useLoggerStore } from '@/store/logger'

const DISABLED_ADS = 'use_no_ads'

export const useAdsStore = defineStore('ads', () => {
    const { VUE_APP_AD_REQUEST_TIMEOUT_MS, VUE_APP_ADMANAGER_ACCOUNT } = useConfig()
    const { logEvent } = useLoggerStore()
    const { clid, widgetId, userAcquisitionParams, isGameDistribution, query, platform } = storeToRefs(useAppStore())
    const adMediator = shallowRef<Raw<AdMediator> | null>(null)
    const commonOptions = computed(() => ({
        adRequestTimeoutMs: VUE_APP_AD_REQUEST_TIMEOUT_MS,
        gamAccount: VUE_APP_ADMANAGER_ACCOUNT,
        logEvent,
    }))
    const currentRoute = useRoute()
    const adsDisabled = computed(
        () => !!query.value?.[DISABLED_ADS] && ROUTES_ACCEPTING_NO_ADS_PARAM.includes(currentRoute.name as string),
    )
    const configKey = computed(() => {
        if (isGameDistribution.value) {
            return GameProviders.GAME_DISTRIBUTION
        }
        if (adsDisabled.value) {
            return GameProviders.EMPTY_PROVIDER
        }
        return GameProviders.GOOGLE_AD
    })

    const commonTargeting = computed(() => ({
        playgama_clid: clid.value,
        widget_id: widgetId.value || '',
        ...userAcquisitionParams.value,
    }))

    function setAdServiceConfig(options: {
        targeting?: Record<string, string | string[]>
        route: RouteLocationNormalizedLoaded
    }) {
        const targetingFromSource = options.targeting || {}
        const targeting = { ...commonTargeting.value, ...targetingFromSource }

        adMediator.value = new AdMediator({
            ...commonOptions.value,
            targeting,
            route: options.route,
            configKey: configKey.value,
            platform: platform.value,
        })
    }

    function updateTargeting(targeting: AdMediatorOptions['targeting']) {
        if (adMediator.value) {
            adMediator.value.updateTargeting(targeting)
        }
    }

    return {
        adMediator,
        setAdServiceConfig,
        updateTargeting,
    }
})
