import { computed } from 'vue'
import { defineStore, storeToRefs } from 'pinia'
import { useUserStore } from '@/store/user-store/user-store'
import { useLoggerStore } from '@/store/logger'
import { useCategoriesStore } from '@/store/categories-store'

export const useTasksStore = defineStore('tasks', () => {
    const userStore = useUserStore()
    const loggerStore = useLoggerStore()
    const categoriesStore = useCategoriesStore()
    const { tasks, tasksError } = storeToRefs(userStore)

    const notClaimedTasks = computed(() => tasks.value.filter((task) => task.status !== 'CLAIMED'))
    const allTasksClaimed = computed(() => tasks.value.length > 0 && notClaimedTasks.value.length === 0)
    const showTasks = computed(() => tasks.value.length > 0 || tasksError.value)

    async function onTaskClaimed(taskId: string) {
        loggerStore.logEvent({
            event: 'custom_event',
            eventName: 'claim',
            action: 'click',
        })
        await userStore.claimUserTasks(taskId)
    }

    function onTaskStart(taskId: string) {
        const task = tasks.value.find(({ task_id }) => task_id === taskId)
        if (!task) {
            return
        }
        if (task.type === 'RANDOM_GAME') {
            categoriesStore.updateRandomGame()
        }
        loggerStore.logEvent({
            event: 'custom_event',
            eventName: 'do-task',
            action: 'click',
        })
    }

    async function onReloadClick() {
        await userStore.getUserTasks()
    }
    return {
        onTaskClaimed,
        onTaskStart,
        onReloadClick,
        allTasksClaimed,
        notClaimedTasks,
        tasksError,
        showTasks,
        tasks,
    }
})
