import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, normalizeClass as _normalizeClass, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, Transition as _Transition, Teleport as _Teleport } from "vue"

const _hoisted_1 = ["src", "alt"]

import { defineAsyncComponent } from 'vue'
import Button from '@/components_new/Button.vue'
import Typography from '@/components_new/Typography.vue'
import { useToastStore } from './store'
import { useIsMounted } from '@/utils/vue-hooks/use-is-mounted'
import { useTr } from '@/composables/use-tr'


export default /*@__PURE__*/_defineComponent({
  __name: 'Toaster',
  setup(__props) {

const tr = useTr()

// load lottie only if animation is present
const Vue3Lottie = defineAsyncComponent(() => import('vue3-lottie').then((mod) => mod.Vue3Lottie))

const toastStore = useToastStore()
const isMounted = useIsMounted()

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_Teleport, { to: "body" }, [
    _createVNode(_Transition, {
      enterActiveClass: _ctx.$style.transition_enter_active,
      enterFromClass: _ctx.$style.transition_enter_from,
      leaveActiveClass: _ctx.$style.transition_leave_active,
      leaveToClass: _ctx.$style.transition_leave_to,
      appear: ""
    }, {
      default: _withCtx(() => [
        (_unref(toastStore).isVisible && _unref(toastStore).toast)
          ? (_openBlock(), _createElementBlock("div", {
              key: 0,
              class: _normalizeClass(_ctx.$style.toast_container)
            }, [
              _createElementVNode("div", {
                class: _normalizeClass(_ctx.$style.toast)
              }, [
                _createVNode(Button, {
                  icon: "close",
                  color: "white-60",
                  visual: "ghost",
                  size: "m",
                  class: _normalizeClass(_ctx.$style.close),
                  interfaceTarget: "close-toast",
                  onClick: _unref(toastStore).closeToast
                }, null, 8, ["class", "onClick"]),
                _createElementVNode("div", {
                  class: _normalizeClass(_ctx.$style.instructions)
                }, [
                  (_unref(toastStore).toast.animation)
                    ? (_openBlock(), _createElementBlock("div", {
                        key: 0,
                        class: _normalizeClass(_ctx.$style.image)
                      }, [
                        (_unref(isMounted))
                          ? (_openBlock(), _createBlock(_unref(Vue3Lottie), {
                              key: 0,
                              animationData: _unref(toastStore).toast.animation
                            }, null, 8, ["animationData"]))
                          : _createCommentVNode("", true)
                      ], 2))
                    : _createCommentVNode("", true),
                  (_unref(toastStore).toast.image)
                    ? (_openBlock(), _createElementBlock("img", {
                        key: 1,
                        class: _normalizeClass(_ctx.$style.image),
                        src: _unref(toastStore).toast.image,
                        alt: _unref(tr)('toast-pwa.description'),
                        loading: "lazy"
                      }, null, 10, _hoisted_1))
                    : _createCommentVNode("", true),
                  _createVNode(Typography, {
                    type: "header",
                    size: "xs",
                    accent: "",
                    responsive: false,
                    class: _normalizeClass(_ctx.$style.header)
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_unref(toastStore).toast.title), 1)
                    ]),
                    _: 1
                  }, 8, ["class"]),
                  _createVNode(Typography, {
                    type: "paragraph",
                    size: "s",
                    responsive: false,
                    class: _normalizeClass(_ctx.$style.paragraph)
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_unref(toastStore).toast.description), 1)
                    ]),
                    _: 1
                  }, 8, ["class"]),
                  (_unref(toastStore).toast.buttonText)
                    ? (_openBlock(), _createBlock(Button, {
                        key: 2,
                        color: "purple-400",
                        size: "m",
                        class: _normalizeClass(_ctx.$style.accept),
                        interfaceTarget: "accept-toast",
                        onClick: _unref(toastStore).acceptToast
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_unref(toastStore).toast.buttonText), 1)
                        ]),
                        _: 1
                      }, 8, ["class", "onClick"]))
                    : _createCommentVNode("", true)
                ], 2)
              ], 2)
            ], 2))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }, 8, ["enterActiveClass", "enterFromClass", "leaveActiveClass", "leaveToClass"])
  ]))
}
}

})