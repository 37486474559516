import { defineComponent as _defineComponent } from 'vue'
import { normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

import { onMounted, ref, computed } from 'vue'
import { useUserStore } from '@/store/user-store/user-store'
import { useHead } from '@unhead/vue'
import { useLoggerStore } from '@/store/logger'
import { useConfig } from '@/composables/config'

interface GoogleAuthProps {
    onFinish: () => void
}

export default /*@__PURE__*/_defineComponent({
  __name: 'GoogleAuth',
  props: {
    onFinish: { type: Function }
  },
  setup(__props: any) {

const { VUE_APP_GAUTH_CLID, VUE_APP_GAUTH_REDIRECT } = useConfig()

const props = __props

const googleBtn = ref<HTMLElement | null>(null)

const userStore = useUserStore()
const loggerStore = useLoggerStore()

const initSignIn = () => {
    if (!window.google || !googleBtn.value) {
        return
    }

    window.google.accounts.id.initialize({
        client_id: VUE_APP_GAUTH_CLID || '',
        auto_select: false,
        callback: async ({ credential }) => {
            await fetch(VUE_APP_GAUTH_REDIRECT || '', {
                method: 'POST',
                headers: { 'content-type': 'application/json' },
                body: JSON.stringify({ credential }),
            })

            userStore.fetchUserInfo()
            loggerStore.logEvent({
                event: 'custom_event',
                eventName: 'login',
                action: userStore.isAuthorized ? 'success' : 'fail',
            })

            props.onFinish()
        },
    })

    window.google.accounts.id.renderButton(googleBtn.value, {
        type: 'standard',
        text: 'signin_with',
        theme: 'outline',
        shape: 'pill',
        size: 'large',
        logo_alignment: 'left',
    })
}

const script = computed(() => ({
    src: 'https://accounts.google.com/gsi/client',
    async: true,
    onload: initSignIn,
}))
useHead({ script: [script] })

onMounted(() => {
    initSignIn()
})

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    ref_key: "googleBtn",
    ref: googleBtn,
    class: _normalizeClass(_ctx.$style.google_signin)
  }, null, 2))
}
}

})