<template>
    <ModalContainer
        :title="$tr('modalSignIn.title')"
        :description="$tr('modalSignIn.description')"
        :image="SignInImg"
        :alt="$tr('modalSignIn.alt')"
        :isOpened="signInModalOpened"
        @closeModal="onCloseModal"
    >
        <template #actions>
            <GoogleAuth
                :class="$style.button_sing_in"
                :onFinish="onCloseModal"
            />
        </template>
    </ModalContainer>
</template>
<script setup lang="ts">
import ModalContainer from '@/components_new/ModalItems/ModalContainer.vue'
import SignInImg from '@/components_new/ModalItems/images/sign-in.webp'
import { useLayoutStore } from '@/store/layout-store'
import { storeToRefs } from 'pinia'
import GoogleAuth from '@/components_new/GoogleAuth.vue'

const layoutStore = useLayoutStore()
const { signInModalOpened } = storeToRefs(layoutStore)

function onCloseModal() {
    layoutStore.setSignInModalOpened(false)
}
</script>
<style module>
.button_sing_in {
    margin-bottom: 24px;
    margin-top: 8px;
}
</style>
