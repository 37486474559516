<template>
    <!-- Emty tag to make ssr rehydration happy — it complains if the template is empty -->
    <div data-head-meta />
</template>
<script setup lang="ts">
import { type ReactiveHead, useHead } from '@unhead/vue'
import { computed } from 'vue'
import { useRoute } from 'vue-router'
// without '?file' webpack tries to inline into base64, since the files are relatevely small
import PGFaviconWhite32 from '@/assets/icons/PG-favicon-white-32.webp?file'
import PGFaviconWhite48 from '@/assets/icons/PG-favicon-white-48.webp?file'
import PGFaviconWhite96 from '@/assets/icons/PG-favicon-white-96.webp?file'
import PGFaviconWhite144 from '@/assets/icons/PG-favicon-white-144.webp?file'
import PGFaviconWhite180 from '@/assets/icons/PG-favicon-white-180.webp?file'
import PGFaviconWhite192 from '@/assets/icons/PG-favicon-white-192.webp?file'
import PGFaviconWhite512 from '@/assets/icons/PG-favicon-white-512.webp?file'
import PGFaviconWhiteSVG from '@/assets/icons/PG-favicon-white.svg?file'
import PGFaviconBlackICO from '@/assets/icons/PG-favicon-black.ico?file'
import PGFaviconBlack32 from '@/assets/icons/PG-favicon-black-32.webp?file'
import PGFaviconBlack48 from '@/assets/icons/PG-favicon-black-48.webp?file'
import PGFaviconBlack96 from '@/assets/icons/PG-favicon-black-96.webp?file'
import PGFaviconBlack144 from '@/assets/icons/PG-favicon-black-144.webp?file'
import PGFaviconBlack180 from '@/assets/icons/PG-favicon-black-180.webp?file'
import PGFaviconBlack192 from '@/assets/icons/PG-favicon-black-192.webp?file'
import PGFaviconBlack512 from '@/assets/icons/PG-favicon-black-512.webp?file'
import PGFaviconBlackSVG from '@/assets/icons/PG-favicon-black.svg?file'
import PGFaviconWhiteICO from '@/assets/icons/PG-favicon-white.ico?file'
import PGFaviconMaskSVG from '@/assets/icons/PG-favicon-mask.svg?file'
import PlaygamaOG from '@/assets/icons/Playgama-OG.webp?file'
import { useConfig } from '@/composables/config'
import { GAM_SOURCE } from '@/modules/google-ads'
import { useTr } from '@/composables/use-tr'
import { MANIFEST_META_KEY } from '@/constants/general'
import { useAppStore } from '@/store/app-store'
import { storeToRefs } from 'pinia'

const iconsMap = {
    PGFaviconWhite32,
    PGFaviconWhite48,
    PGFaviconWhite96,
    PGFaviconWhite144,
    PGFaviconWhite180,
    PGFaviconWhite192,
    PGFaviconWhite512,
    PGFaviconWhiteSVG,
    PGFaviconWhiteICO,
    PGFaviconBlack32,
    PGFaviconBlack48,
    PGFaviconBlack96,
    PGFaviconBlack144,
    PGFaviconBlack180,
    PGFaviconBlack192,
    PGFaviconBlack512,
    PGFaviconBlackSVG,
    PGFaviconBlackICO,
}

const props = defineProps<{ loadGoogleAssets: boolean }>()

const tr = useTr()
const route = useRoute()

const appStore = useAppStore()
const { platform } = storeToRefs(appStore)

const organizationSchema = computed(() => ({
    '@context': 'https://schema.org',
    '@type': 'Organization',
    '@id': 'https://playgama.com/#organization',
    url: 'https://playgama.com',
    name: 'Playgama',
    description: tr('headMeta.description'),
    logo: PGFaviconWhiteSVG,
    brand: {
        '@type': 'Brand',
        '@id': 'https://playgama.com',
        name: 'Playgama',
        slogan: tr('headMeta.slogan'),
    },
    email: 'support@playgama.com',
    sameAs: [
        'https://www.facebook.com/playgamagames',
        'https://www.linkedin.com/company/playgama',
        'https://x.com/playgamacom',
    ],
}))

const adaptiveIcons = (() => {
    const sizes = [
        ['ICO', 'image/x-icon'],
        ['SVG', 'image/svg+xml'],
        [32, 'image/webp'],
        [48, 'image/webp'],
        [96, 'image/webp'],
        [144, 'image/webp'],
        [180, 'image/webp'],
        [192, 'image/webp'],
        [512, 'image/webp'],
    ] as const
    const colorSchemes = ['dark', 'light'] as const
    const res: { rel: string; type: string; sizes?: string; href: string; media: string }[] = []
    sizes.forEach(([size, type]) => {
        colorSchemes.forEach((colorScheme) => {
            const color = colorScheme === 'dark' ? 'Black' : 'White'
            const newSizes = typeof size === 'number' ? `${size}x${size}` : undefined
            res.push({
                rel: 'icon',
                type,
                sizes: newSizes,
                href: iconsMap[`PGFavicon${color}${size}`],
                media: `(prefers-color-scheme:${colorScheme})`,
            })
        })
    })
    return res
})()

const { VUE_APP_HOST_URL, ROBOTS_DISALLOW, VUE_APP_GA_MEASUREMENT_ID } = useConfig()

const pageScripts = computed(() => {
    const scripts: ReactiveHead['script'] = [
        {
            type: 'application/ld+json',
            innerHTML: organizationSchema,
        },
        // window.dataLayer is used later in the app regardless of the "props.loadGoogleAssets"
        'window.dataLayer = window.dataLayer || [];',
    ]
    if (props.loadGoogleAssets) {
        scripts.push(
            {
                src: `https://www.googletagmanager.com/gtm.js?id=${VUE_APP_GA_MEASUREMENT_ID}`,
                async: true,
                fetchpriority: 'high',
            },
            `window.dataLayer.push({'gtm.start':new Date().getTime(),event:'gtm.js'})`,
            {
                src: GAM_SOURCE,
                async: true,
                // inline callbacks to make it work with SSR
                // the google ad module needs to know if this script has failed to load.
                onerror: `this.dataset.state="error"`,
                onload: `this.dataset.state="loaded"`,
                // the callbacks above can add data-attribute on the tag before the client hydration happens
                // in that case useHead will not recogise existing tag and will insert a new one again
                // this key forces the deduplication
                key: 'gam-source',
                fetchpriority: 'high',
            },
        )
    }

    return scripts
})

const pageMeta = computed(() => {
    const meta: ReactiveHead['meta'] = [
        {
            name: 'description',
            content: tr('headMeta.description'),
        },
        {
            name: 'validation-token',
            content: 'hb42hn0e5uyz0bfp',
        },
        {
            name: 'color-scheme"',
            content: 'dark',
        },

        // Progressive Web App
        {
            name: 'mobile-web-app-capable',
            content: 'yes',
        },
        {
            name: 'apple-mobile-web-app-capable',
            content: 'yes',
        },
        {
            name: 'apple-mobile-web-app-status-bar-style',
            content: 'black-translucent',
        },
        {
            name: 'theme-color',
            content: '#000000',
        },
        // temporary OG image
        // TODO better OG card
        {
            property: 'og:image',
            content: PlaygamaOG,
        },
        {
            property: 'og:image:alt',
            content: tr('headMeta.alt_image'),
        },
        {
            property: 'og:title',
            content: tr('headMeta.title'),
        },
        {
            property: 'og:description',
            content: tr('headMeta.description'),
        },
        {
            property: 'og:type',
            content: 'website',
        },
        {
            property: 'og:url',
            content: `${VUE_APP_HOST_URL}${route.fullPath === '/' ? '' : route.fullPath}`,
        },
        {
            property: 'og:site_name',
            content: 'Playgama.com',
        },
        {
            name: 'twitter:card',
            content: 'summary',
        },
        {
            name: 'twitter:title',
            content: tr('headMeta.title'),
        },
        {
            name: 'twitter:description',
            content: tr('headMeta.description'),
        },
        {
            name: 'twitter:image',
            content: PlaygamaOG,
        },
        {
            name: 'twitter:image:alt',
            content: tr('headMeta.alt_image'),
        },
    ]

    if (ROBOTS_DISALLOW === 'DISALLOW') {
        meta.push({
            name: 'robots',
            content: 'noindex',
        })
    }
    return meta
})

const pageLink = computed(() => {
    const links: ReactiveHead['link'] = [
        ...(platform.value !== 'ios'
            ? [
                  {
                      rel: 'manifest',
                      href: '/manifest/main.webmanifest',
                      key: MANIFEST_META_KEY,
                  },
              ]
            : []),
        ...adaptiveIcons,
        {
            rel: 'mask-icon',
            href: PGFaviconMaskSVG,
            color: '#9747FF',
            media: '(prefers-color-scheme:light)',
        },
        {
            rel: 'mask-icon',
            href: PGFaviconMaskSVG,
            color: '#AA76FF',
            media: '(prefers-color-scheme:dark)',
        },
        {
            rel: 'apple-touch-icon',
            sizes: '180x180',
            href: PGFaviconWhite180,
            media: '(prefers-color-scheme:light)',
            key: 'apple-touch-icon-light',
        },
        {
            rel: 'apple-touch-icon',
            sizes: '180x180',
            href: PGFaviconBlack180,
            media: '(prefers-color-scheme:dark)',
            key: 'apple-touch-icon-dark',
        },
    ]

    if (props.loadGoogleAssets) {
        links.push(
            // Google ad preconnects
            {
                rel: 'preconnect',
                href: 'https://securepubads.g.doubleclick.net',
                as: 'script',
            },
            {
                rel: 'preconnect',
                href: 'https://pagead2.googlesyndication.com',
                as: 'script',
            },
        )
    }

    return links
})

const pageHead = computed(() => {
    return {
        title: tr('headMeta.title'),
        meta: pageMeta.value,
        link: pageLink.value,
        script: pageScripts.value,
    }
})
useHead(pageHead)
</script>
