<template>
    <div
        :class="$style.footer"
        data-interface-section="footer"
    >
        <div :class="[$style.links_group, $style.links_menu_highlight]">
            <Typography
                is="ul"
                type="paragraph"
                size="xs"
                :class="$style.links"
            >
                <li
                    v-for="item in menuHighlightCategoriesList"
                    :key="item.title"
                >
                    <Link
                        v-if="item.location"
                        :to="item.location"
                        data-interface-target="category"
                        :class="$style.links_item"
                        color="white-60"
                    >
                        {{ item.title }}
                    </Link>
                </li>
                <li v-if="randomGamePath && isMounted">
                    <Link
                        :to="randomGamePath"
                        :class="$style.links_item"
                        data-interface-section="random-game"
                        color="white-60"
                        @click="categoriesStore.updateRandomGame"
                    >
                        {{ $tr('navigation.random') }}
                    </Link>
                </li>
                <li>
                    <Link
                        :to="allGamesNavigation.allTags.to"
                        :class="$style.links_item"
                        color="white-60"
                        data-interface-target="navigation-link"
                    >
                        {{ allGamesNavigation.allTags.title }}
                    </Link>
                </li>
                <li>
                    <Link
                        :to="allGamesNavigation.allSeries.to"
                        :class="$style.links_item"
                        data-interface-target="navigation-link"
                        color="white-60"
                    >
                        {{ allGamesNavigation.allSeries.title }}
                    </Link>
                </li>
            </Typography>
        </div>
        <div :class="$style.main_links">
            <div :class="[$style.links_group, $style.links_topic_categories]">
                <Typography
                    is="ul"
                    type="paragraph"
                    size="xs"
                    :class="$style.links"
                >
                    <li
                        v-for="item in menuCategoriesList"
                        :key="item.title"
                    >
                        <Link
                            v-if="item.location"
                            :to="item.location"
                            :class="$style.links_item"
                            color="white-60"
                            data-interface-target="navigation-link"
                        >
                            {{ item.title }}
                        </Link>
                    </li>
                    <li>
                        <Link
                            :to="allGamesNavigation.allGames.to"
                            :class="$style.links_item"
                            color="white-60"
                            data-interface-target="navigation-link"
                        >
                            {{ allGamesNavigation.allGames.title }}
                        </Link>
                    </li>
                </Typography>
            </div>
            <div :class="$style.links_group">
                <Typography
                    is="ul"
                    type="paragraph"
                    size="xs"
                    :class="$style.links"
                >
                    <li>
                        <Link
                            :to="{ name: ROUTE_NAMES.SHOP }"
                            :class="$style.links_item"
                            color="white-60"
                            data-interface-target="shop-link"
                        >
                            {{ $tr('navigation.shop') }}
                        </Link>
                    </li>
                    <li>
                        <Link
                            :to="BLOG_PATH"
                            :class="$style.links_item"
                            color="white-60"
                            external
                            target="_blank"
                            data-interface-target="navigation-link"
                        >
                            {{ $tr('navigation.blog') }}
                        </Link>
                    </li>
                    <li
                        v-for="item in NAVIGATION_LINKS($tr)"
                        :key="item.title"
                    >
                        <Link
                            :externalTo="item.externalTo"
                            :target="item.target"
                            :class="$style.links_item"
                            color="white-60"
                            data-interface-target="navigation-link"
                        >
                            {{ item.title }}
                        </Link>
                    </li>
                </Typography>
            </div>
        </div>
        <Typography
            is="div"
            type="paragraph"
            size="xs"
            :class="$style.copyright"
        >
            <Link
                :to="{ name: ROUTE_NAMES.MAIN }"
                :class="$style.links_item"
                color="white-40"
            >
                {{ $tr('navigation.copyright', { year: String(new Date().getFullYear()) }) }}
            </Link>
            <template v-if="i18nEnabled">
                <Link
                    color="white-40"
                    :to="{ params: { locale: '' } }"
                    @click="updateLocale('en')"
                >
                    en
                </Link>
                <Link
                    color="white-40"
                    :to="{ params: { locale: 'es' } }"
                    @click="updateLocale('es')"
                >
                    es
                </Link>
            </template>
        </Typography>
    </div>
</template>

<script setup lang="ts">
import { useCategoriesStore } from '@/store/categories-store'
import { storeToRefs } from 'pinia'
import Typography from '@/components_new/Typography.vue'
import Link from '@/components_new/Link.vue'
import { NAVIGATION_LINKS, BLOG_PATH, NAVIGATION_ALL_GAMES_LINKS } from '@/router/navigation-constants'
import { ROUTE_NAMES } from '@/router/constants'
import { computed } from 'vue'
import { useIsMounted } from '@/utils/vue-hooks/use-is-mounted'
import { useTr } from '@/composables/use-tr'
import { useConfig } from '@/composables/config'
import { updateLocaleCookie } from '@/utils/cookie-parser'
import { AvailableLocale } from '@/i18n/types'
import { useI18nAddon } from '@/i18n/i18n'

const tr = useTr()

const categoriesStore = useCategoriesStore()
const { menuCategoriesList, menuHighlightCategoriesList, randomGameHRU } = storeToRefs(categoriesStore)

const randomGamePath = computed(
    () => randomGameHRU.value && { name: ROUTE_NAMES.GAME_PAGE, params: { game: randomGameHRU.value } },
)

const isMounted = useIsMounted()

const allGamesNavigation = computed(() => NAVIGATION_ALL_GAMES_LINKS(tr))

const i18nEnabled = useConfig().VUE_APP_ENABLE_I18N
const i18nAddon = useI18nAddon()

function updateLocale(locale: AvailableLocale) {
    i18nAddon.setPreferredLocale(locale)
    updateLocaleCookie(locale)
}
</script>

<style module>
.footer {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    padding-bottom: 60px;
}

.main_links,
.links_group,
.copyright {
    grid-column: span 2;
}

.links_group {
    border-top: 1px solid var(--white-20);
    margin-top: 16px;
    padding-top: 16px;
}

.links {
    columns: 2 auto;
}

.links_item {
    display: block;
    padding: 4px 0;
}

.copyright {
    margin-top: 24px;
}

@media (--tablet-plus) {
    .footer {
        grid-template-columns: repeat(5, 1fr);
    }

    .links_group {
        border-top: none;
        margin-top: unset;
        padding-top: unset;
        grid-column: auto;
    }

    .links_menu_highlight {
        grid-column: span 5;
        column-count: 5;
    }

    .links_menu_highlight,
    .main_links {
        border-top: 1px solid var(--white-20);
        margin-top: 16px;
        padding-top: 16px;
    }

    .links_topic_categories {
        column-count: 4;
    }

    .main_links {
        padding-top: 24px;
        display: grid;
        grid-column: span 5;
        grid-template-columns: 4fr 1fr;
    }

    .links {
        columns: unset;
    }
}
</style>
