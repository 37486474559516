import { createSSRApp, createApp as createClientSideApp } from 'vue'
import { createHead, VueHeadMixin } from '@unhead/vue'
import createRouter from '@/router'
import FloatingVue from 'floating-vue'
import FloatingThemes from '@/components_new/Floating/floating-themes'
import App from '@/App.vue'
import { isPWARuntime } from '@/components_new/PWAInstall/utils'
import { createI18nWithAddon, isSupportedLocale } from '@/i18n/i18n'
import createPinia from '@/pinia'
import { createConfigPlugin } from '@/composables/config'
import { setAPIHost } from '@/utils/requests/request'
import { createWebHistory, createWebHashHistory, RouterHistory } from 'vue-router'
import { getCurrentLocaleFromCookies } from './utils/cookie-parser'
import { AvailableLocale } from './i18n/types'

const run = async () => {
    let appConfig = window.additionalServerData?.config

    // this is to simulate SSR redirects in development
    let DEV_detectedLocale: AvailableLocale | undefined = undefined

    if (process.env.NODE_ENV !== 'production') {
        // TODO https://playgama.atlassian.net/browse/DEV-1032
        appConfig = (await import('@/modules/config/createConfig')).createVueAppConfig(process.env)

        // to test specific locale you can hardcode it to any lang-code in BCP47 standard format
        const locale = getCurrentLocaleFromCookies(document.cookie)
        if (isSupportedLocale(locale)) {
            DEV_detectedLocale = locale
        }
    }
    if (!appConfig) {
        throw new Error('No app config provided')
    }

    // TODO: refactor utils/requests
    setAPIHost(appConfig.API_HOST)

    const configPlugin = createConfigPlugin(appConfig)
    const head = createHead()

    const { vueI18n, i18nAddon } = createI18nWithAddon({
        enableI18n: appConfig.VUE_APP_ENABLE_I18N,
        detectedLocale: DEV_detectedLocale,
    })

    let routerHistory: RouterHistory
    if (isPWARuntime()) {
        // we use hash history for PWA to keep the URL same and not cause browser UI to appear
        routerHistory = createWebHashHistory()
        routerHistory.push(location.pathname + location.search)
    } else {
        routerHistory = createWebHistory()
    }
    const { router } = createRouter({
        history: routerHistory,
        i18nAddon,
        registerImport: (x) => () => x(),
    })
    const pinia = createPinia()
    pinia.state.value = window.__pinia || {}

    const createApp = process.env.NODE_ENV === 'production' ? createSSRApp : createClientSideApp
    const app = createApp(App)
        .use(configPlugin)
        .use(vueI18n)
        .use(i18nAddon)
        .use(router)
        .use(pinia)
        .use(head)
        .use(FloatingVue, FloatingThemes)
        .mixin(VueHeadMixin)

    await router.isReady()

    app.mount('#app')
}

run()
