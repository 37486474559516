<template>
    <div
        v-if="task"
        :class="$style.task"
        @click="onTaskClick"
    >
        <div :class="$style.task_content">
            <Typography
                type="caption_all-caps"
                :class="$style.description"
                :responsive="false"
            >
                {{ task.description }}
            </Typography>
            <div :class="$style.fennecs">
                <Icon name="silver_fennec_coin_small" />
                <Typography
                    type="header"
                    size="s"
                    active
                    :responsive="false"
                >
                    {{ task.coins }}
                </Typography>
            </div>
        </div>
        <Button
            v-if="activeTask"
            interfaceTarget="active-task"
            size="m"
            color="purple-400"
        >
            {{ task.buttonLabel }}
        </Button>
        <Button
            v-else
            color="golden"
            interfaceTarget="claim-task"
            size="m"
            @click.stop="onClaim"
        >
            {{ $tr('tasks.claim') }}
        </Button>
    </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import type {
    DailyTaskDto,
    GameTaskDto,
    RandomTaskDto,
    ReferralTaskDto,
    ShortGameType,
    TaskDto,
    TaskStatus,
    TaskType,
} from '@/types'
import Button from '@/components_new/Button.vue'
import Typography from '@/components_new/Typography.vue'
import Icon from '@/components_new/Icon/Icon.vue'
import { useCategoriesStore } from '@/store/categories-store'
import { storeToRefs } from 'pinia'
import { useTr } from '@/composables/use-tr'
import { RouteLocationRaw } from 'vue-router'
import { ROUTE_NAMES } from '@/router/constants'

interface TaskProps {
    task: TaskDto
}

type Task = {
    id: string
    status: TaskStatus
    coins: number
    description: string
    buttonLabel: string
    gameLink?: RouteLocationRaw
    // we might not need this game key anywhere
    game?: ShortGameType
    type: TaskType
}

const props = defineProps<TaskProps>()

const emit = defineEmits<{
    claimTask: [taskId: string]
    taskStart: [{ taskId: string; link: RouteLocationRaw }]
    taskClick: []
}>()
const tr = useTr()

const categoriesStore = useCategoriesStore()
const { randomGameHRU } = storeToRefs(categoriesStore)

function getDailyBonusTask(task: DailyTaskDto): Task {
    const { status, coins, task_id, type } = task
    return {
        status,
        coins,
        id: task_id,
        type,
        buttonLabel: tr('tasks.claim'),
        description: tr('tasks.daily_bonus_description'),
    }
}

function getReferralTask(task: ReferralTaskDto): Task {
    const {
        status,
        coins,
        task_id,
        type,
        settings: { invitations_not_claimed },
    } = task
    const coinsPerFriend = invitations_not_claimed > 1 ? Math.floor(coins / invitations_not_claimed) : coins
    const description =
        invitations_not_claimed > 1
            ? tr('tasks.collect_invite_widget_many', {
                  friends: String(invitations_not_claimed),
                  coins: String(coinsPerFriend),
              })
            : tr('tasks.collect_invite_widget', { coins: String(coinsPerFriend) })
    return {
        status,
        coins,
        id: task_id,
        type,
        buttonLabel: tr('tasks.claim'),
        description,
    }
}

function getRandomGameTask(task: RandomTaskDto): Task {
    const { status, coins, settings, task_id, type } = task
    const min = Math.ceil(settings.seconds_to_play / 60)
    return {
        status,
        type,
        id: task_id,
        coins,
        description: tr('tasks.random_game_description', { min: String(min) }),
        buttonLabel: tr('tasks.play'),
        ...(randomGameHRU.value && {
            gameLink: { name: ROUTE_NAMES.GAME_PAGE, params: { game: randomGameHRU.value } },
        }),
    }
}

function getGameTask(task: GameTaskDto): Task {
    const {
        status,
        coins,
        settings: { game, seconds_to_play },
        task_id,
        type,
    } = task
    const min = Math.ceil(seconds_to_play / 60)
    return {
        status,
        type,
        coins,
        id: task_id,
        game,
        description: game.title,
        buttonLabel: tr('tasks.button_label', { min: String(min) }),
        ...(game.hru && { gameLink: { name: ROUTE_NAMES.GAME_PAGE, params: { game: game.hru } } }),
    }
}

const task = computed(() => {
    if (props.task.type === 'VISIT_EVERYDAY') {
        return getDailyBonusTask(props.task)
    }

    if (props.task.type === 'RANDOM_GAME') {
        return getRandomGameTask(props.task)
    }

    if (props.task.type === 'PLAY_GAME') {
        return getGameTask(props.task)
    }
    return getReferralTask(props.task)
})

const activeTask = computed(() => task.value.status === 'ACTIVE')

function onClaim() {
    emit('claimTask', task.value.id)
}

function onTaskClick() {
    if (activeTask.value && task.value.gameLink) {
        emit('taskStart', { taskId: task.value.id, link: task.value.gameLink })
    } else {
        emit('taskClick')
    }
}
</script>

<style module>
.task {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 4px;
    background-color: var(--carbon-300);
}

.task_content {
    display: flex;
    flex-direction: column;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    gap: 4px;
}

.fennecs {
    display: flex;
    align-items: center;
    flex-direction: row;
    gap: 6px;
}

.description {
    color: rgba(255, 255, 255, 0.6);
}
</style>
