import { defineComponent as _defineComponent } from 'vue'
import { renderSlot as _renderSlot, normalizeClass as _normalizeClass, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import BaseLink from '@/components_new/BaseLink.vue'
import { LinkTarget } from '@/types'
import { RouteLocationRaw } from 'vue-router'

export interface LinkProps {
    disabled?: boolean
    to?: RouteLocationRaw
    externalTo?: string
    target?: LinkTarget
    color?: 'purple-500' | 'purple-600' | 'golden' | 'white' | 'white-60' | 'white-80' | 'white-40'
}


export default /*@__PURE__*/_defineComponent({
  __name: 'Link',
  props: {
    disabled: { type: Boolean },
    to: {},
    externalTo: {},
    target: {},
    color: {}
  },
  emits: ["click"],
  setup(__props: any, { emit: __emit }) {

const props = __props

const emit = __emit

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(BaseLink, {
    to: props.to,
    externalTo: props.externalTo,
    target: props.target,
    class: _normalizeClass([_ctx.$style.link, props.color && _ctx.$style[`color_${props.color}`], props.disabled ? _ctx.$style.disabled : '']),
    disabled: props.disabled,
    onClick: _cache[0] || (_cache[0] = ($event: any) => (emit('click', $event)))
  }, {
    default: _withCtx(() => [
      _renderSlot(_ctx.$slots, "default")
    ]),
    _: 3
  }, 8, ["to", "externalTo", "target", "class", "disabled"]))
}
}

})