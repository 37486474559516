import { defineComponent as _defineComponent } from 'vue'
import { renderSlot as _renderSlot, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, unref as _unref, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = ["href", "target"]

import { RouteLocationRaw, RouterLink } from 'vue-router'
import { LinkTarget } from '@/types'
import { useUrl, useGetExternalUrl } from '@/composables/url-helpers'
import { computed } from 'vue'

export interface BaseLinkProps {
    disabled?: boolean
    to?: RouteLocationRaw
    externalTo?: string
    target?: LinkTarget
}


export default /*@__PURE__*/_defineComponent({
  __name: 'BaseLink',
  props: {
    disabled: { type: Boolean },
    to: {},
    externalTo: {},
    target: {}
  },
  emits: ["click"],
  setup(__props: any, { emit: __emit }) {

const props = __props
const { keepRequiredParams } = useUrl()
const getExternalUrl = useGetExternalUrl()

const computedTo = computed(() => {
    if (props.target === '_blank' && props.to) {
        return getExternalUrl(props.to)
    }
    return props.to
})

const emit = __emit

return (_ctx: any,_cache: any) => {
  return (props.externalTo)
    ? (_openBlock(), _createElementBlock("a", {
        key: 0,
        href: _ctx.externalTo,
        target: props.target,
        class: _normalizeClass([_ctx.$style.baseLink, props.disabled ? _ctx.$style.disabled : '']),
        onClick: _cache[0] || (_cache[0] = ($event: any) => (emit('click', $event)))
      }, [
        _renderSlot(_ctx.$slots, "default")
      ], 10, _hoisted_1))
    : (computedTo.value)
      ? (_openBlock(), _createBlock(_unref(RouterLink), {
          key: 1,
          to: _unref(keepRequiredParams)(computedTo.value),
          target: props.target,
          class: _normalizeClass([_ctx.$style.baseLink, props.disabled ? _ctx.$style.disabled : '']),
          onClick: _cache[1] || (_cache[1] = ($event: any) => (emit('click', $event)))
        }, {
          default: _withCtx(() => [
            _renderSlot(_ctx.$slots, "default")
          ]),
          _: 3
        }, 8, ["to", "target", "class"]))
      : _createCommentVNode("", true)
}
}

})