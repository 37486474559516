import { defineComponent as _defineComponent } from 'vue'
import { normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveDynamicComponent as _resolveDynamicComponent, createBlock as _createBlock } from "vue"

const _hoisted_1 = ["src", "alt"]

import { computed } from 'vue'
import { type IconNameType, icons } from '@/components_new/Icon/index'

export type { IconNameType }


export default /*@__PURE__*/_defineComponent({
  __name: 'Icon',
  props: {
    name: {},
    alt: {}
  },
  setup(__props: any) {

const props = __props

const icon = computed(() => icons[props.name])

const altText = computed(() => props.alt || `${props.name} icon`)

return (_ctx: any,_cache: any) => {
  return (typeof icon.value === 'string')
    ? (_openBlock(), _createElementBlock("img", {
        key: 0,
        src: icon.value,
        alt: altText.value,
        class: _normalizeClass([_ctx.$style.icon, _ctx.$style.img, _ctx.$style[`icon-${props.name}`]])
      }, null, 10, _hoisted_1))
    : (_openBlock(), _createBlock(_resolveDynamicComponent(icon.value), {
        key: 1,
        class: _normalizeClass([_ctx.$style.icon, _ctx.$style[`icon-${props.name}`]]),
        role: "img",
        "aria-label": altText.value
      }, null, 8, ["class", "aria-label"]))
}
}

})