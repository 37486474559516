import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { key: 0 }

import { useCategoriesStore } from '@/store/categories-store'
import { storeToRefs } from 'pinia'
import Typography from '@/components_new/Typography.vue'
import Link from '@/components_new/Link.vue'
import { NAVIGATION_LINKS, BLOG_PATH, NAVIGATION_ALL_GAMES_LINKS } from '@/router/navigation-constants'
import { ROUTE_NAMES } from '@/router/constants'
import { computed } from 'vue'
import { useIsMounted } from '@/utils/vue-hooks/use-is-mounted'
import { useTr } from '@/composables/use-tr'
import { useConfig } from '@/composables/config'
import { updateLocaleCookie } from '@/utils/cookie-parser'
import { AvailableLocale } from '@/i18n/types'
import { useI18nAddon } from '@/i18n/i18n'


export default /*@__PURE__*/_defineComponent({
  __name: 'Footer',
  setup(__props) {

const tr = useTr()

const categoriesStore = useCategoriesStore()
const { menuCategoriesList, menuHighlightCategoriesList, randomGameHRU } = storeToRefs(categoriesStore)

const randomGamePath = computed(
    () => randomGameHRU.value && { name: ROUTE_NAMES.GAME_PAGE, params: { game: randomGameHRU.value } },
)

const isMounted = useIsMounted()

const allGamesNavigation = computed(() => NAVIGATION_ALL_GAMES_LINKS(tr))

const i18nEnabled = useConfig().VUE_APP_ENABLE_I18N
const i18nAddon = useI18nAddon()

function updateLocale(locale: AvailableLocale) {
    i18nAddon.setPreferredLocale(locale)
    updateLocaleCookie(locale)
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_ctx.$style.footer),
    "data-interface-section": "footer"
  }, [
    _createElementVNode("div", {
      class: _normalizeClass([_ctx.$style.links_group, _ctx.$style.links_menu_highlight])
    }, [
      _createVNode(Typography, {
        is: "ul",
        type: "paragraph",
        size: "xs",
        class: _normalizeClass(_ctx.$style.links)
      }, {
        default: _withCtx(() => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(menuHighlightCategoriesList), (item) => {
            return (_openBlock(), _createElementBlock("li", {
              key: item.title
            }, [
              (item.location)
                ? (_openBlock(), _createBlock(Link, {
                    key: 0,
                    to: item.location,
                    "data-interface-target": "category",
                    class: _normalizeClass(_ctx.$style.links_item),
                    color: "white-60"
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(item.title), 1)
                    ]),
                    _: 2
                  }, 1032, ["to", "class"]))
                : _createCommentVNode("", true)
            ]))
          }), 128)),
          (randomGamePath.value && _unref(isMounted))
            ? (_openBlock(), _createElementBlock("li", _hoisted_1, [
                _createVNode(Link, {
                  to: randomGamePath.value,
                  class: _normalizeClass(_ctx.$style.links_item),
                  "data-interface-section": "random-game",
                  color: "white-60",
                  onClick: _unref(categoriesStore).updateRandomGame
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$tr('navigation.random')), 1)
                  ]),
                  _: 1
                }, 8, ["to", "class", "onClick"])
              ]))
            : _createCommentVNode("", true),
          _createElementVNode("li", null, [
            _createVNode(Link, {
              to: allGamesNavigation.value.allTags.to,
              class: _normalizeClass(_ctx.$style.links_item),
              color: "white-60",
              "data-interface-target": "navigation-link"
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(allGamesNavigation.value.allTags.title), 1)
              ]),
              _: 1
            }, 8, ["to", "class"])
          ]),
          _createElementVNode("li", null, [
            _createVNode(Link, {
              to: allGamesNavigation.value.allSeries.to,
              class: _normalizeClass(_ctx.$style.links_item),
              "data-interface-target": "navigation-link",
              color: "white-60"
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(allGamesNavigation.value.allSeries.title), 1)
              ]),
              _: 1
            }, 8, ["to", "class"])
          ])
        ]),
        _: 1
      }, 8, ["class"])
    ], 2),
    _createElementVNode("div", {
      class: _normalizeClass(_ctx.$style.main_links)
    }, [
      _createElementVNode("div", {
        class: _normalizeClass([_ctx.$style.links_group, _ctx.$style.links_topic_categories])
      }, [
        _createVNode(Typography, {
          is: "ul",
          type: "paragraph",
          size: "xs",
          class: _normalizeClass(_ctx.$style.links)
        }, {
          default: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(menuCategoriesList), (item) => {
              return (_openBlock(), _createElementBlock("li", {
                key: item.title
              }, [
                (item.location)
                  ? (_openBlock(), _createBlock(Link, {
                      key: 0,
                      to: item.location,
                      class: _normalizeClass(_ctx.$style.links_item),
                      color: "white-60",
                      "data-interface-target": "navigation-link"
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(item.title), 1)
                      ]),
                      _: 2
                    }, 1032, ["to", "class"]))
                  : _createCommentVNode("", true)
              ]))
            }), 128)),
            _createElementVNode("li", null, [
              _createVNode(Link, {
                to: allGamesNavigation.value.allGames.to,
                class: _normalizeClass(_ctx.$style.links_item),
                color: "white-60",
                "data-interface-target": "navigation-link"
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(allGamesNavigation.value.allGames.title), 1)
                ]),
                _: 1
              }, 8, ["to", "class"])
            ])
          ]),
          _: 1
        }, 8, ["class"])
      ], 2),
      _createElementVNode("div", {
        class: _normalizeClass(_ctx.$style.links_group)
      }, [
        _createVNode(Typography, {
          is: "ul",
          type: "paragraph",
          size: "xs",
          class: _normalizeClass(_ctx.$style.links)
        }, {
          default: _withCtx(() => [
            _createElementVNode("li", null, [
              _createVNode(Link, {
                to: { name: _unref(ROUTE_NAMES).SHOP },
                class: _normalizeClass(_ctx.$style.links_item),
                color: "white-60",
                "data-interface-target": "shop-link"
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$tr('navigation.shop')), 1)
                ]),
                _: 1
              }, 8, ["to", "class"])
            ]),
            _createElementVNode("li", null, [
              _createVNode(Link, {
                to: _unref(BLOG_PATH),
                class: _normalizeClass(_ctx.$style.links_item),
                color: "white-60",
                external: "",
                target: "_blank",
                "data-interface-target": "navigation-link"
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$tr('navigation.blog')), 1)
                ]),
                _: 1
              }, 8, ["to", "class"])
            ]),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(NAVIGATION_LINKS)(_ctx.$tr), (item) => {
              return (_openBlock(), _createElementBlock("li", {
                key: item.title
              }, [
                _createVNode(Link, {
                  externalTo: item.externalTo,
                  target: item.target,
                  class: _normalizeClass(_ctx.$style.links_item),
                  color: "white-60",
                  "data-interface-target": "navigation-link"
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(item.title), 1)
                  ]),
                  _: 2
                }, 1032, ["externalTo", "target", "class"])
              ]))
            }), 128))
          ]),
          _: 1
        }, 8, ["class"])
      ], 2)
    ], 2),
    _createVNode(Typography, {
      is: "div",
      type: "paragraph",
      size: "xs",
      class: _normalizeClass(_ctx.$style.copyright)
    }, {
      default: _withCtx(() => [
        _createVNode(Link, {
          to: { name: _unref(ROUTE_NAMES).MAIN },
          class: _normalizeClass(_ctx.$style.links_item),
          color: "white-40"
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$tr('navigation.copyright', { year: String(new Date().getFullYear()) })), 1)
          ]),
          _: 1
        }, 8, ["to", "class"]),
        (_unref(i18nEnabled))
          ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
              _createVNode(Link, {
                color: "white-40",
                to: { params: { locale: '' } },
                onClick: _cache[0] || (_cache[0] = ($event: any) => (updateLocale('en')))
              }, {
                default: _withCtx(() => _cache[2] || (_cache[2] = [
                  _createTextVNode(" en ")
                ])),
                _: 1
              }),
              _createVNode(Link, {
                color: "white-40",
                to: { params: { locale: 'es' } },
                onClick: _cache[1] || (_cache[1] = ($event: any) => (updateLocale('es')))
              }, {
                default: _withCtx(() => _cache[3] || (_cache[3] = [
                  _createTextVNode(" es ")
                ])),
                _: 1
              })
            ], 64))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }, 8, ["class"])
  ], 2))
}
}

})