import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = ["src"]

import ModalContainer from '@/components_new/ModalItems/ModalContainer.vue'
import { useLayoutStore } from '@/store/layout-store'
import { useUserStore } from '@/store/user-store/user-store'
import Typography from '@/components_new/Typography.vue'
import { storeToRefs } from 'pinia'
import { computed, onMounted, onUnmounted, ref, watch } from 'vue'
import TextInput from '@/components_new/TextInput/TextInput.vue'
import { request } from '@/utils/requests/request'
import { useDebounceFn } from '@vueuse/core'
import Icon from '@/components_new/Icon/Icon.vue'


export default /*@__PURE__*/_defineComponent({
  __name: 'ModalPayment',
  setup(__props) {

const layoutStore = useLayoutStore()
const userStore = useUserStore()
const { isPaymentModalOpen, paymentModalAmount } = storeToRefs(layoutStore)

const xsollaIframeToken = ref('')
const abortRef = ref()
const xsollaIframeSrc = computed(
    () => `https://sandbox-secure.xsolla.com/paystation4/?token=${xsollaIframeToken.value}`,
)
const tokenReady = computed(() => Boolean(xsollaIframeToken.value))

const localAmount = ref(paymentModalAmount.value)
watch(paymentModalAmount, (val) => {
    localAmount.value = val
})
const updateAmount = (val: number) => {
    localAmount.value = Math.max(paymentModalAmount.value, val)
}

const debouncedUpdateAmount = useDebounceFn(updateAmount, 200)
const onAmountChange = (val: number) => {
    xsollaIframeToken.value = ''
    if (abortRef.value) {
        abortRef.value.abort('New request sent')
        abortRef.value = undefined
    }
    debouncedUpdateAmount(val)
}

watch(localAmount, async (amount) => {
    if (amount > 0) {
        abortRef.value = new AbortController()
        try {
            const res = await request('/api/v1/in-game-payments/createXsollaToken', {
                method: 'POST',
                // Uncomment this locally
                /*
                headers: {
                    'content-type': 'application/json',
                    'cf-ipcountry': 'ES',
                },
                */
                body: JSON.stringify({ amount }),
                signal: abortRef.value.signal,
            })

            if (res.errors.length === 0) {
                xsollaIframeToken.value = res.data
                abortRef.value = undefined
            }
        } catch (e) {
            console.error(e)
            abortRef.value = undefined
        }
    }
})

function onCloseModal() {
    layoutStore.setPaymentModalAmount(0)
    xsollaIframeToken.value = ''
}

function modalEventListener(event: MessageEvent) {
    try {
        const eventData = JSON.parse(event.data)
        if (eventData.command === 'open-status-success') {
            setTimeout(() => {
                layoutStore.setPaymentModalAmount(0)
            }, 1000)
            userStore.fetchUserInfo()
        }
    } catch {}
}

onMounted(() => {
    window.addEventListener('message', modalEventListener)
})
onUnmounted(() => {
    window.removeEventListener('message', modalEventListener)
})

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(ModalContainer, {
    wide: "",
    title: _ctx.$tr('paymentModal.header'),
    isOpened: _unref(isPaymentModalOpen),
    onCloseModal: onCloseModal
  }, {
    "custom-content": _withCtx(() => [
      _createElementVNode("div", {
        class: _normalizeClass(_ctx.$style.row)
      }, [
        _createElementVNode("div", {
          class: _normalizeClass(_ctx.$style.column)
        }, [
          _createVNode(Typography, {
            type: "header",
            size: "s",
            responsive: false
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$tr('paymentModal.title')), 1)
            ]),
            _: 1
          }),
          _createVNode(TextInput, {
            id: "gold-amount",
            name: "gold-amount",
            type: "number",
            value: localAmount.value,
            class: _normalizeClass(_ctx.$style.margin),
            "onUpdate:modelValue": onAmountChange
          }, null, 8, ["value", "class"]),
          _createVNode(Typography, {
            is: "p",
            size: "s",
            responsive: false,
            class: _normalizeClass(_ctx.$style.margin)
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$tr('paymentModal.description', { amount: _unref(paymentModalAmount).toString() })), 1)
            ]),
            _: 1
          }, 8, ["class"])
        ], 2),
        _createElementVNode("div", {
          class: _normalizeClass([_ctx.$style.row, _ctx.$style.iframe])
        }, [
          (tokenReady.value)
            ? (_openBlock(), _createElementBlock("iframe", {
                key: 0,
                src: xsollaIframeSrc.value,
                width: "375",
                height: "600",
                allow: "clipboard-read; clipboard-write; payment"
              }, null, 8, _hoisted_1))
            : _createCommentVNode("", true),
          (!tokenReady.value)
            ? (_openBlock(), _createBlock(Icon, {
                key: 1,
                name: "loader",
                class: _normalizeClass(_ctx.$style.loader)
              }, null, 8, ["class"]))
            : _createCommentVNode("", true)
        ], 2)
      ], 2)
    ]),
    _: 1
  }, 8, ["title", "isOpened"]))
}
}

})