import { defineComponent as _defineComponent } from 'vue'
import { normalizeClass as _normalizeClass, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, withModifiers as _withModifiers, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

import { computed } from 'vue'
import UserAvatar from '@/components_new/UserAvatar.vue'
import Typography from '@/components_new/Typography.vue'
import Icon from '@/components_new/Icon/Icon.vue'
import Balance from '@/components_new/Balance.vue'
import type { User } from '@/types'


export default /*@__PURE__*/_defineComponent({
  __name: 'UserAndBalance',
  props: {
    user: {},
    userBalance: {},
    isProfilePage: { type: Boolean }
  },
  emits: ["logout"],
  setup(__props: any, { emit: __emit }) {

const props = __props

const emit = __emit

const userName = computed(() => `${props.user.firstName || 'Anonimous'} ${props.user.lastName || ''}`)

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass([_ctx.$style.container, _ctx.isProfilePage && _ctx.$style.profilePage])
  }, [
    (_ctx.isProfilePage)
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
          _createVNode(UserAvatar, {
            class: _normalizeClass(_ctx.$style.avatar),
            avatar: props.user.avatar,
            firstName: props.user.firstName,
            lastName: props.user.lastName
          }, null, 8, ["class", "avatar", "firstName", "lastName"]),
          _createElementVNode("div", {
            class: _normalizeClass(_ctx.$style.textColumn)
          }, [
            _createVNode(Typography, {
              type: "label",
              size: "l",
              accent: "",
              responsive: false
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(userName.value), 1)
              ]),
              _: 1
            }),
            _createVNode(Typography, {
              type: "caption",
              size: "s",
              class: _normalizeClass(_ctx.$style.caption),
              responsive: false
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(props.user.email), 1)
              ]),
              _: 1
            }, 8, ["class"])
          ], 2),
          _createVNode(Icon, {
            name: "logout",
            class: _normalizeClass(_ctx.$style.logout),
            "data-interface-target": "logout",
            onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (emit('logout', $event)), ["stop","prevent"]))
          }, null, 8, ["class"])
        ], 64))
      : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
          _createElementVNode("div", {
            class: _normalizeClass(_ctx.$style.textColumn)
          }, [
            _createVNode(Typography, {
              type: "caption_all-caps",
              class: _normalizeClass(_ctx.$style.caption),
              responsive: false
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$tr('userAndBalance.my_balance')), 1)
              ]),
              _: 1
            }, 8, ["class"]),
            _createElementVNode("div", {
              class: _normalizeClass(_ctx.$style.balance)
            }, [
              _createVNode(Icon, { name: "silver_fennec_coin_small" }),
              _createVNode(Typography, {
                type: "header",
                size: "s",
                class: _normalizeClass(_ctx.$style.balance),
                responsive: false
              }, {
                default: _withCtx(() => [
                  _createVNode(Balance, { balance: _ctx.userBalance }, null, 8, ["balance"])
                ]),
                _: 1
              }, 8, ["class"])
            ], 2)
          ], 2),
          _createVNode(UserAvatar, {
            class: _normalizeClass(_ctx.$style.avatar),
            avatar: props.user.avatar,
            firstName: props.user.firstName,
            lastName: props.user.lastName
          }, null, 8, ["class", "avatar", "firstName", "lastName"])
        ], 64))
  ], 2))
}
}

})