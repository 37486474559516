import { RouteLocationRaw, useRouter } from 'vue-router'
import { useAppStore } from '@/store/app-store'
import { ROUTE_NAMES, ROUTE_NAMES_GAME_GROUPS } from '@/router/constants'
import { CategoryType } from '@/types'

const TYPE_GAME_GROUP_TO_PAGE_NAME: Record<CategoryType, ROUTE_NAMES_GAME_GROUPS> = {
    tag: ROUTE_NAMES.TAG,
    series: ROUTE_NAMES.SERIES,
    category: ROUTE_NAMES.CATEGORY,
}

export function useUrl() {
    const URL_PARAMS_TO_KEEP = ['clid', 'autoplay']
    const { queryParams } = useAppStore()
    function keepRequiredParams(url: string | RouteLocationRaw): string | RouteLocationRaw {
        try {
            const paramsToAdd = new URLSearchParams()

            queryParams.forEach(([key, value]) => {
                if (URL_PARAMS_TO_KEEP.includes(key)) {
                    paramsToAdd.set(key, value as string)
                }
            })

            if (typeof url === 'string') {
                const [basePath, searchPart] = url.split('?') as [string, string | undefined]

                const urlSearchParams = new URLSearchParams(searchPart)

                paramsToAdd.forEach((value, key) => {
                    urlSearchParams.set(key, value)
                })

                const queryString = urlSearchParams.toString()
                return queryString ? `${basePath}?${queryString}` : basePath
            } else {
                const resultQuery = url.query ? { ...url.query } : {}

                paramsToAdd.forEach((value, key) => {
                    resultQuery[key] = value
                })

                return {
                    ...url,
                    query: resultQuery,
                }
            }
        } catch {
            throw Error('Could not retrieve URL params')
        }
    }

    function getCategoryRoute({ hru, type }: { hru: string; type: CategoryType }): RouteLocationRaw {
        return { name: TYPE_GAME_GROUP_TO_PAGE_NAME[type], params: { hru } }
    }

    return { keepRequiredParams, getCategoryRoute }
}

export const useGetExternalUrl = () => {
    const router = useRouter()
    return (to: RouteLocationRaw) => {
        let { href } = router.resolve(to)
        // we have a HashHistory in PWA, so when building a url for external link or iFrame, we need to remove the hash
        if (href.startsWith('#')) {
            href = href.slice(1)
        }
        return href
    }
}
