<template>
    <div
        v-if="isAuthorized && (showTasks || (allTasksClaimed && $slots.default))"
        :class="$style.task_widgets"
        data-interface-section="tasks-widget"
    >
        <template v-if="tasksError">
            <span :class="$style.inner_sep" />
            <div :class="$style.error_container">
                <Typography
                    type="label"
                    size="l"
                >
                    {{ $tr('tasks.error_title') }}
                </Typography>
                <Button
                    color="carbon-400"
                    visual="fill"
                    interfaceTarget="reload-tasks"
                    @click="tasksStore.onReloadClick"
                >
                    {{ $tr('tasks.error_button') }}
                </Button>
            </div>
        </template>
        <template v-else>
            <template v-if="!allTasksClaimed">
                <template
                    v-for="task in tasksToShow"
                    :key="task.task_id"
                >
                    <TaskWidget
                        :task="task"
                        :class="{ [$style.taskClickable]: props.redirect }"
                        @claimTask="tasksStore.onTaskClaimed"
                        @taskStart="onTaskStart"
                        @taskClick="onTaskClick"
                    />
                </template>
            </template>
            <slot v-else />
        </template>
    </div>
</template>

<script setup lang="ts">
import { storeToRefs } from 'pinia'
import Typography from '@/components_new/Typography.vue'
import { computed } from 'vue'
import TaskWidget from '@/components_new/TaskItems/TaskWidget.vue'
import Button from '@/components_new/Button.vue'
import { useTasksStore } from '@/components_new/TaskItems/store'
import { useUserStore } from '@/store/user-store/user-store'
import { ROUTE_NAMES } from '@/router/constants'
import { RouteLocationRaw, useRouter } from 'vue-router'

interface TasksWidgetProps {
    showOnlyFirst?: boolean
    redirect?: boolean
}

const props = defineProps<TasksWidgetProps>()

const tasksStore = useTasksStore()
const userStore = useUserStore()
const { tasksError, showTasks, allTasksClaimed, notClaimedTasks } = storeToRefs(tasksStore)

const { isAuthorized } = storeToRefs(userStore)

const tasksToShow = computed(() => {
    if (allTasksClaimed.value) {
        return []
    }
    return props.showOnlyFirst ? notClaimedTasks.value.slice(0, 1) : notClaimedTasks.value
})

const router = useRouter()
const onTaskClick = () => {
    if (props.redirect) {
        router.push({ name: ROUTE_NAMES.PROFILE })
    }
}
const onTaskStart = ({ taskId, link }: { taskId: string; link: RouteLocationRaw }) => {
    tasksStore.onTaskStart(taskId)
    router.push(link)
}
</script>

<style module>
.task_widgets {
    border-radius: 16px;
    background-color: var(--carbon-300);
    padding: 12px;
}

.error_container {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    text-align: center;
    border-radius: 12px;
    background-color: var(--carbon-300);
    padding: 12px;
    gap: 8px;
}

.taskClickable {
    cursor: pointer;
}
</style>
