import { defineStore } from 'pinia'
import { computed, ref } from 'vue'
import { type PlatformType } from '@/types'
import { LocationQuery } from 'vue-router'
import { DEFAULT_CLIDS } from '@/constants/general'
import { useConfig } from '@/composables/config'

export type ErrorStatus = 404 | 500
export type PageStatus = 200 | 301 | 302 | ErrorStatus

const DEFAULT_PLATFORM = 'desktop'
const WIDGET_ID_PARAM_NAME = 'widget_id'
const CLID_PARAM = 'clid'
const GAME_DISTRIBUTION_KEY = 'use_gd_ads'

export const useAppStore = defineStore('app', () => {
    const { VUE_APP_HOST_URL } = useConfig()
    const pageStatus = ref<PageStatus>(200)
    const targetURL = ref('')
    const platform = ref<PlatformType>(DEFAULT_PLATFORM)
    const host = ref<string>()
    const userAcquisitionParams = ref<Record<string, string>>({})
    const query = ref<LocationQuery>()
    const isGameDistribution = computed(() => query.value?.[GAME_DISTRIBUTION_KEY])
    const widgetId = computed(() => {
        return query.value?.[WIDGET_ID_PARAM_NAME] as string
    })
    const clid = computed(() => {
        const fallbackClid = host.value === 'playgama.com' ? DEFAULT_CLIDS.direct : DEFAULT_CLIDS.directTest
        return (query.value?.[CLID_PARAM] as string) || fallbackClid
    })
    const queryParams = computed(() => Object.entries(query.value || {}))

    const isAuthBannerOpen = ref<boolean>(false)

    function setPageStatus(status: PageStatus) {
        pageStatus.value = status
    }
    function setUserParams(details: { userAcquisitionParams: Record<string, string> }) {
        userAcquisitionParams.value = details.userAcquisitionParams
    }

    function setTargetURL(target: string, origin: string = VUE_APP_HOST_URL) {
        targetURL.value = `${origin}${target}`
    }

    function setPlatform(plt: PlatformType) {
        platform.value = plt
    }

    function setUrlDetails(details: { query: LocationQuery; host: string }) {
        host.value = details.host
        query.value = details.query
    }

    function $reset() {
        pageStatus.value = 200
        targetURL.value = ''
        platform.value = DEFAULT_PLATFORM
    }

    return {
        setPageStatus,
        setTargetURL,
        setUrlDetails,
        pageStatus,
        targetURL,
        userAcquisitionParams,
        clid,
        host,
        queryParams,
        query,
        widgetId,
        platform,
        isAuthBannerOpen,
        isGameDistribution,
        setPlatform,
        setUserParams,
        $reset,
    }
})
