import { defineStore } from 'pinia'
import type {
    EventForLoggersType,
    EventForGa,
    CustomPGEvents,
    PlaygamaLoggerEventType,
    AvailablePGEvents,
    AvailableEventGA,
    EventGA,
} from '@/types/logger.types'
import { useUserStore } from '@/store/user-store/user-store'
import { useAppStore } from '@/store/app-store'
import { CUSTOM_PG_EVENTS } from '@/constants/general'
import { timer } from '@/utils/helpers'
import { ref } from 'vue'
import { useRoute } from 'vue-router'
import { ROUTE_NAMES } from '@/router/constants'
import { KEY_CONVERSION_AD_SHOW_TYPES, OutOfPageAdType } from '@/modules/adv'
import { useConfig } from '@/composables/config'

async function sendEvent(host: string, event: PlaygamaLoggerEventType) {
    const ANALYTICS_PATH = `${host}/api/v1/events`
    try {
        await fetch(ANALYTICS_PATH, {
            method: 'post',
            headers: {
                'content-type': 'application/json',
            },
            body: JSON.stringify(event),
            credentials: 'include',
            priority: 'low',
        })
    } catch {
        // 🤷‍♂️
    }
}

export type EventData = {
    gameHru?: string
    clid?: string
    userId?: string
    widgetId?: string
}

const EXPORT_KEY_EVENTS: { timeout: number; eventName: AvailablePGEvents }[] = [
    { timeout: 1.5 * 60 * 1000, eventName: 'export_timespent_1.5min' },
    { timeout: 3 * 60 * 1000, eventName: 'export_timespent_3min' },
]
const PORTAL_KEY_EVENTS: { timeout: number; eventName: AvailablePGEvents }[] = [
    { timeout: 5 * 60 * 1000, eventName: 'timespent_5min' },
    { timeout: 10 * 60 * 1000, eventName: 'timespent_10min' },
    { timeout: 15 * 60 * 1000, eventName: 'timespent_15min' },
]

const checkIsEventGA = (eventName: AvailablePGEvents): eventName is AvailableEventGA =>
    !CUSTOM_PG_EVENTS.includes(eventName as CustomPGEvents)

const INITIAL_DELAY = 5000 // ms
let initialDelayPromise: null | Promise<void> = null
function intialDelay() {
    if (!initialDelayPromise) {
        initialDelayPromise = timer(INITIAL_DELAY)
    }
    return initialDelayPromise
}

export const useLoggerStore = defineStore('logger', () => {
    const { ANALYTICS_API_HOST } = useConfig()
    async function getEventData(): Promise<EventData> {
        const userStore = useUserStore()
        const appStore = useAppStore()
        const user = await userStore.getUser()
        return {
            clid: appStore.clid,
            userId: user?._id,
            widgetId: appStore.widgetId,
        }
    }

    const route = useRoute()
    const adShowCounter = ref(0)
    const threeMinutesSpentOnExport = ref(false)
    const oneAndHalfMinutesSpentOnExport = ref(false)
    const threeMinutesAndThreeAdShow = ref(false)
    const oneAndHalfMinutesAndTwoAdShow = ref(false)

    function trackCompositeEvents(event: EventForLoggersType) {
        if (route.name === ROUTE_NAMES.EXPORT_GAME_PAGE) {
            if (
                event.eventName === 'ad_show' &&
                event.adPlacement &&
                KEY_CONVERSION_AD_SHOW_TYPES.includes(event.adPlacement as OutOfPageAdType)
            ) {
                adShowCounter.value++
            }
            if (event.eventName === 'export_timespent_3min') {
                threeMinutesSpentOnExport.value = true
            }
            if (event.eventName === 'export_timespent_1.5min') {
                oneAndHalfMinutesSpentOnExport.value = true
            }
            if (adShowCounter.value >= 3 && threeMinutesSpentOnExport.value && !threeMinutesAndThreeAdShow.value) {
                threeMinutesAndThreeAdShow.value = true
                logEvent({
                    event: 'custom_event',
                    eventName: '3min+3ad_show',
                })
            }
            if (adShowCounter.value >= 2 && threeMinutesSpentOnExport.value && !oneAndHalfMinutesAndTwoAdShow.value) {
                oneAndHalfMinutesAndTwoAdShow.value = true
                logEvent({
                    event: 'custom_event',
                    eventName: '1.5min+2ad_show',
                })
            }
        }
    }

    async function logEvent(event: EventForLoggersType): Promise<void> {
        await intialDelay()
        const { clid, userId, widgetId } = await getEventData()
        const { eventName } = event

        if (checkIsEventGA(eventName)) {
            const gaEvent: EventForGa = {
                event: event.event as EventGA,
                eventName,
                action: event.action || '',
                clid,
                widget_id: widgetId || '',
                game_provider: event.gameProvider || '',
                game_categories: event.gameCategories || [],
                ad_placement: event.adPlacement || '',
            }
            window.dataLayer.push(gaEvent)
        }

        const analyticsEvent: PlaygamaLoggerEventType = {
            eventName: event.eventName,
            pageName: event.pageName,
            gameHru: event.gameHru,
            userId,
            clid,
            widgetId,
        }
        sendEvent(ANALYTICS_API_HOST, analyticsEvent)

        trackCompositeEvents(event)
    }

    function startPortalKeyEventsTracking() {
        PORTAL_KEY_EVENTS.forEach(({ timeout, eventName }) => {
            setTimeout(() => {
                logEvent({
                    event: 'custom_event',
                    eventName,
                })
            }, timeout)
        })
    }

    function startExportKeyEventsTracking() {
        EXPORT_KEY_EVENTS.forEach(({ timeout, eventName }) => {
            setTimeout(() => {
                logEvent({
                    event: 'custom_event',
                    eventName,
                })
            }, timeout)
        })
    }

    async function logInterfaceEvents(props: {
        interface_target: string
        interface_path: string
        eventName: AvailableEventGA
    }) {
        const { clid, widgetId } = await getEventData()
        const event: EventForGa = {
            event: 'interface_event',
            route_name: route.name as string,
            clid,
            widget_id: widgetId || '',
            interface_path: props.interface_path,
            interface_target: props.interface_target,
            eventName: props.eventName,
        }
        window.dataLayer.push(event)
    }

    return {
        logEvent,
        startPortalKeyEventsTracking,
        startExportKeyEventsTracking,
        logInterfaceEvents,
    }
})
