<template>
    <PromoBanner
        v-if="!isAuthorized && isUserInfoLoaded"
        direction="row"
        buttonPosition="bottom"
        :title="$tr('promoItems.sign_in_title')"
        :description="$tr('promoItems.sign_in_desc')"
        :image="{ src: FennecCoinsImg, alt: $tr('promoItems.sign_in_alt') }"
        textAlign="left"
    >
        <template #default="{ handleClick }">
            <Button
                size="l"
                color="golden"
                interfaceTarget="open-sign-in-modal"
                @click="handleClick('sign_in')"
            >
                {{ $tr('promoItems.sign_in_btn') }}
            </Button>
        </template>
    </PromoBanner>
</template>
<script setup lang="ts">
import Button from '@/components_new/Button.vue'
import FennecCoinsImg from '@/components_new/PromoItems/images/fennec-coin-silver.webp'
import PromoBanner from '@/components_new/PromoItems/PromoBanner.vue'
import { useUserStore } from '@/store/user-store/user-store'
import { storeToRefs } from 'pinia'

const userStore = useUserStore()
const { isAuthorized, isUserInfoLoaded } = storeToRefs(userStore)
</script>
