// Add new route here — consider adding it to lists below
export const ROUTE_NAMES = {
    ROOT: 'root',
    MAIN: 'main',
    GAME_PAGE: 'game-page',
    EXPORT_GAME_PAGE: 'export-game-page',
    WIDGET_GAME_PAGE: 'widget-game-page',
    CATEGORY: 'category',
    TAG: 'tag',
    SERIES: 'series',
    CONFIDENTIAL: 'confidential',
    TERMS_OF_USE: 'termsofuse',
    LICENSE: 'license',
    PARTNERS_API: 'partners-api',
    CONTACTS: 'contacts',
    PROFILE: 'profile',
    SHOP: 'shop',
    INVITE: 'invite',
    ALL_CATEGORIES: 'all-categories-page',
    ALL_TAGS: 'all-tags-page',
    ALL_SERIES: 'all-series-page',
    ALL_CATEGORIES_OLD_PAGE: 'games-categories',
    TAKEDOWN_NOTICE: 'takedown-notice',
} as const

export const ROUTES_WITH_GAME_PARAM: string[] = [
    ROUTE_NAMES.GAME_PAGE,
    ROUTE_NAMES.EXPORT_GAME_PAGE,
    ROUTE_NAMES.WIDGET_GAME_PAGE,
]

export const ROUTES_FOR_PWA_TOAST: string[] = [
    ROUTE_NAMES.MAIN,
    ROUTE_NAMES.CATEGORY,
    ROUTE_NAMES.TAG,
    ROUTE_NAMES.SERIES,
    ROUTE_NAMES.PROFILE,
    ROUTE_NAMES.GAME_PAGE,
]

export const ROUTES_WITHOUT_PORTAL_FULSCREEN_AD: string[] = [
    ROUTE_NAMES.GAME_PAGE,
    ROUTE_NAMES.EXPORT_GAME_PAGE,
    ROUTE_NAMES.WIDGET_GAME_PAGE,
]

export const ROUTES_ACCEPTING_NO_ADS_PARAM: string[] = [ROUTE_NAMES.EXPORT_GAME_PAGE]

export const ROUTES_HIDE_PORTAL_STICKY: string[] = [ROUTE_NAMES.EXPORT_GAME_PAGE, ROUTE_NAMES.WIDGET_GAME_PAGE]

export type ROUTE_NAMES_VALUES = (typeof ROUTE_NAMES)[keyof typeof ROUTE_NAMES]
export type ROUTE_NAMES_GAME_GROUPS =
    | (typeof ROUTE_NAMES)['CATEGORY']
    | (typeof ROUTE_NAMES)['TAG']
    | (typeof ROUTE_NAMES)['SERIES']
