<template>
    <div :class="[$style.container, isProfilePage && $style.profilePage]">
        <template v-if="isProfilePage">
            <UserAvatar
                :class="$style.avatar"
                :avatar="props.user.avatar"
                :firstName="props.user.firstName"
                :lastName="props.user.lastName"
            />
            <div :class="$style.textColumn">
                <Typography
                    type="label"
                    size="l"
                    accent
                    :responsive="false"
                >
                    {{ userName }}
                </Typography>
                <Typography
                    type="caption"
                    size="s"
                    :class="$style.caption"
                    :responsive="false"
                >
                    {{ props.user.email }}
                </Typography>
            </div>
            <Icon
                name="logout"
                :class="$style.logout"
                data-interface-target="logout"
                @click.stop.prevent="emit('logout', $event)"
            />
        </template>
        <template v-else>
            <div :class="$style.textColumn">
                <Typography
                    type="caption_all-caps"
                    :class="$style.caption"
                    :responsive="false"
                >
                    {{ $tr('userAndBalance.my_balance') }}
                </Typography>
                <div :class="$style.balance">
                    <Icon name="silver_fennec_coin_small" />
                    <Typography
                        type="header"
                        size="s"
                        :class="$style.balance"
                        :responsive="false"
                    >
                        <Balance :balance="userBalance" />
                    </Typography>
                </div>
            </div>
            <UserAvatar
                :class="$style.avatar"
                :avatar="props.user.avatar"
                :firstName="props.user.firstName"
                :lastName="props.user.lastName"
            />
        </template>
    </div>
</template>
<script setup lang="ts">
import { computed } from 'vue'
import UserAvatar from '@/components_new/UserAvatar.vue'
import Typography from '@/components_new/Typography.vue'
import Icon from '@/components_new/Icon/Icon.vue'
import Balance from '@/components_new/Balance.vue'
import type { User } from '@/types'

const props = defineProps<{
    user: User
    userBalance: number
    isProfilePage: boolean
}>()

const emit = defineEmits<{ logout: [value: Event] }>()

const userName = computed(() => `${props.user.firstName || 'Anonimous'} ${props.user.lastName || ''}`)
</script>
<style module>
.container {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 12px;
    padding: 12px;
    height: 48px;
}

.container.profilePage {
    padding: 12px 16px;
}

.textColumn {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    gap: 4px;
}

.spacer {
    flex-grow: 1;
}

.logout {
    cursor: pointer;
}

.caption {
    color: rgba(255, 255, 255, 0.6);
}

.balance {
    display: flex;
    align-items: center;
    gap: 6px;
    flex-grow: 1;
}

.avatar {
    width: 40px;
}
</style>
