import { defineComponent as _defineComponent } from 'vue'
import { normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, withCtx as _withCtx, createElementVNode as _createElementVNode, resolveDynamicComponent as _resolveDynamicComponent, mergeProps as _mergeProps } from "vue"

import { type RouteLocationRaw } from 'vue-router'
import { ref } from 'vue'
import Icon from '@/components_new/Icon/Icon.vue'
import Typography from '@/components_new/Typography.vue'
import BaseLink from '@/components_new/BaseLink.vue'
import { type IconNameType } from '@/components_new/Icon'
import { LinkTarget } from '@/types'

interface ButtonProps {
    type?: 'button' | 'submit'
    disabled?: boolean
    size?: 's' | 'm' | 'l'
    width?: 'normal' | 'full'
    visual?: 'fill' | 'outline' | 'text' | 'ghost'
    color?:
        | 'purple-400'
        | 'golden'
        | 'white'
        | 'white-60'
        | 'carbon-300'
        | 'carbon-400'
        | 'purple-500'
        | 'purple-600'
        | 'lime'
        | 'rose-400'
        | 'purple-graphite'
    effect?: 'bevel' | 'no'
    target?: LinkTarget
    to?: RouteLocationRaw
    externalTo?: string
    iconLeft?: IconNameType
    iconRight?: IconNameType
    icon?: IconNameType
    interfaceTarget: string
}


export default /*@__PURE__*/_defineComponent({
  __name: 'Button',
  props: {
    type: { default: 'button' },
    disabled: { type: Boolean, default: false },
    size: { default: 'm' },
    width: { default: 'normal' },
    visual: { default: 'fill' },
    color: { default: 'purple-400' },
    effect: { default: 'no' },
    target: {},
    to: {},
    externalTo: {},
    iconLeft: {},
    iconRight: {},
    icon: {},
    interfaceTarget: {}
  },
  emits: ["click"],
  setup(__props: any, { emit: __emit }) {

const props = __props

const emit = __emit

const contentRef = ref<HTMLDivElement | null>(null)

const labelSizesMap = {
    xs: 'xs',
    s: 's',
    m: 'm',
    l: 'm',
} as const

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_resolveDynamicComponent((_ctx.to ?? _ctx.externalTo) ? BaseLink : 'button'), _mergeProps((_ctx.to ?? _ctx.externalTo) ? { to: _ctx.to, externalTo: _ctx.externalTo } : {}, {
    target: _ctx.target,
    class: [
            _ctx.$style.button,
            _ctx.$style[`size_${props.size}`],
            _ctx.$style[`width_${props.width}`],
            _ctx.$style[`effect_${props.effect}`],
            _ctx.$style[`visual_${props.visual}`],
            _ctx.$style[`color_${props.color}`],
            props.disabled ? _ctx.$style.disabled : '',
        ],
    type: props.type,
    disabled: props.disabled,
    "data-interface-target": _ctx.interfaceTarget,
    onClick: _cache[0] || (_cache[0] = ($event: any) => (emit('click', $event)))
  }), {
    default: _withCtx(() => [
      _createElementVNode("div", {
        ref_key: "contentRef",
        ref: contentRef,
        class: _normalizeClass(_ctx.$style.content)
      }, [
        (props.iconLeft)
          ? (_openBlock(), _createBlock(Icon, {
              key: 0,
              name: props.iconLeft,
              class: _normalizeClass(_ctx.$style.iconLeft)
            }, null, 8, ["name", "class"]))
          : _createCommentVNode("", true),
        (_ctx.$slots.default)
          ? (_openBlock(), _createBlock(Typography, {
              key: 1,
              type: "label",
              accent: true,
              size: labelSizesMap[props.size],
              responsive: false
            }, {
              default: _withCtx(() => [
                _renderSlot(_ctx.$slots, "default")
              ]),
              _: 3
            }, 8, ["size"]))
          : _createCommentVNode("", true),
        (props.iconRight)
          ? (_openBlock(), _createBlock(Icon, {
              key: 2,
              name: props.iconRight,
              class: _normalizeClass(_ctx.$style.iconRight)
            }, null, 8, ["name", "class"]))
          : _createCommentVNode("", true),
        (props.icon)
          ? (_openBlock(), _createBlock(Icon, {
              key: 3,
              name: props.icon,
              class: _normalizeClass(_ctx.$style.iconCenter)
            }, null, 8, ["name", "class"]))
          : _createCommentVNode("", true)
      ], 2)
    ]),
    _: 3
  }, 16, ["target", "class", "type", "disabled", "data-interface-target"]))
}
}

})