<template>
    <div
        :class="$style.sidebar"
        data-interface-section="sidebar"
    >
        <Logo
            :class="$style.logo"
            :noLink="isMain"
        />
        <div :class="$style.sections">
            <template v-if="isUserInfoLoaded">
                <BaseLink
                    v-if="isAuthorized && user"
                    :to="{ name: ROUTE_NAMES.PROFILE }"
                    :disabled="isUserProfilePage"
                    :class="$style.section"
                    data-interface-target="profile-link"
                >
                    <UserAndBalance
                        :user="user"
                        :userBalance="userBalance"
                        :isProfilePage="isUserProfilePage"
                        @logout="logout"
                    />
                </BaseLink>
                <PromoSignInHorizontalShort />
            </template>
            <TasksWidget
                v-if="!isUserProfilePage"
                showOnlyFirst
                :redirect="!isUserProfilePage"
            >
                <PromoInviteVertical />
            </TasksWidget>
            <PromoInviteVertical v-if="isUserProfilePage" />
            <Link
                :class="$style.section"
                data-interface-target="shop-link"
                :to="{ name: ROUTE_NAMES.SHOP }"
            >
                <Rewards />
            </Link>
            <Typography
                is="ul"
                :class="[$style.section, $style.links]"
                type="label"
                size="s"
                :accent="true"
                :responsive="false"
            >
                <li
                    v-for="(item, index) in restMenuItems"
                    :key="item.title"
                >
                    <span
                        v-if="index !== 0"
                        :class="$style.inner_sep"
                    />
                    <Link
                        :target="item.target"
                        data-interface-target="navigation-link"
                        :externalTo="item.externalTo"
                        :color="item.color || 'white'"
                        :class="$style.links_item"
                    >
                        <Icon
                            v-if="item.icon"
                            :name="item.icon"
                            :class="$style.links_item_icon"
                        />
                        {{ item.title }}
                    </Link>
                </li>
            </Typography>
        </div>
        <AdBlock
            type="sidebar"
            :refreshSec="90"
            :class="$style.ad"
        />
    </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import { storeToRefs } from 'pinia'
import { RouteLocationRaw, useRoute, useRouter } from 'vue-router'
import { useUserStore } from '@/store/user-store/user-store'
import Logo from '@/components_new/Logo/Logo.vue'
import Icon from '@/components_new/Icon/Icon.vue'
import Typography from '@/components_new/Typography.vue'
import PromoSignInHorizontalShort from '@/components_new/PromoItems/components/PromoSignInHorizontalShort.vue'
import PromoInviteVertical from '@/components_new/PromoItems/components/PromoInviteVertical.vue'
import { DEVELOPERS_LANDPAGE_PATH, PARTNERS_LANDPAGE_PATH } from '@/router/navigation-constants'
import Link, { type LinkProps } from '@/components_new/Link.vue'
import BaseLink from '@/components_new/BaseLink.vue'
import TasksWidget from '@/components_new/TaskItems/TasksWidget.vue'
import AdBlock from '@/components_new/AdBlock.vue'
import { useTr } from '@/composables/use-tr'
import { type IconNameType } from '@/components_new/Icon'
import { ROUTE_NAMES } from '@/router/constants'
import { LinkTarget } from '@/types'
import UserAndBalance from './UserAndBalance.vue'
import Rewards from './Rewards.vue'

const tr = useTr()
type MenuItem = {
    title: string
    to?: RouteLocationRaw
    externalTo?: string
    target?: LinkTarget
    icon?: IconNameType
    color?: LinkProps['color']
}

const restMenuItems = computed((): MenuItem[] => [
    {
        title: tr('navigation.developers'),
        icon: 'sdk',
        externalTo: DEVELOPERS_LANDPAGE_PATH,
        target: '_blank' as const,
    },
    {
        title: tr('navigation.publishers'),
        icon: 'smile',
        externalTo: PARTNERS_LANDPAGE_PATH,
        target: '_blank' as const,
    },
])

const userStore = useUserStore()
const route = useRoute()
const router = useRouter()
const isUserProfilePage = computed(() => route.name === ROUTE_NAMES.PROFILE)
const isMain = computed(() => route.name === ROUTE_NAMES.MAIN)

const { user, isAuthorized, userBalance, isUserInfoLoaded } = storeToRefs(userStore)

async function logout() {
    await userStore.logout()
    router.replace({ name: ROUTE_NAMES.MAIN })
}
</script>

<style module>
.sidebar {
    display: flex;
    flex-direction: column;
}

.logo {
    margin: 0 auto;
    box-sizing: border-box;
    padding: 24px 0;
    width: 184px;
    height: auto;
}

.sections {
    display: flex;
    flex-direction: column;
    gap: 4px;
}

.section {
    border-radius: 16px;
    background: var(--carbon-300);
}

.links {
    padding: 4px 12px;
}

.links_item {
    display: flex;
    align-items: center;
    padding: 12px 0;
    gap: 8px;
}

.links_item_icon {
    width: 22px;
    height: 22px;
}

.inner_sep {
    height: 1px;
    display: block;
    background: rgba(255, 255, 255, 0.08);
}

.ad {
    margin-top: 12px;
}
</style>
