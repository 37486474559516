import { ImportWrapper } from '@/types'
import { inject, Plugin, Ref } from 'vue'
import { createI18n } from 'vue-i18n'
import { AvailableLocale, BaseLocaleMessages } from './types'

export type I18NAddon = {
    currentLocale: Ref<string>
    getSupportedLocales: () => AvailableLocale[]
    getDefaultLocale: () => AvailableLocale
    getPreferredLocale: () => AvailableLocale | undefined
    setPreferredLocale: (locale: AvailableLocale) => void
    loadLocale: (params: { locale: string; registerImport: ImportWrapper }) => Promise<AvailableLocale>
}

const DEFAULT_LOCALE: AvailableLocale = 'en'
export const SUPPORTED_LOCALES: AvailableLocale[] = ['en', 'es']
export function isSupportedLocale(locale: string): locale is AvailableLocale {
    return SUPPORTED_LOCALES.includes(locale as AvailableLocale)
}

const I18N_SYMBOL = Symbol('i18nAddon')

export const createI18nWithAddon = ({
    enableI18n,
    detectedLocale,
}: {
    enableI18n: boolean
    detectedLocale?: AvailableLocale
}) => {
    const vueI18n = createI18n({
        legacy: false,
        locale: '',
        messages: {},
    })

    let preferredLocale = detectedLocale

    // TODO turn into class
    const i18nAddon: I18NAddon & Plugin = {
        currentLocale: vueI18n.global.locale,
        getSupportedLocales() {
            if (enableI18n) {
                return SUPPORTED_LOCALES
            }
            return [DEFAULT_LOCALE]
        },
        getDefaultLocale() {
            return DEFAULT_LOCALE
        },
        getPreferredLocale() {
            if (enableI18n) {
                return preferredLocale
            }
            return undefined
        },
        setPreferredLocale(locale) {
            preferredLocale = locale
        },
        async loadLocale({ locale, registerImport }) {
            let loadedLocale: AvailableLocale
            let json: BaseLocaleMessages
            switch (locale) {
                case 'en':
                    loadedLocale = 'en'
                    json = (await registerImport(() => import('@/locales/en.json'))()).default
                    break
                case 'es':
                    loadedLocale = 'es'
                    json = (await registerImport(() => import('@/locales/es.json'))()).default
                    break
                default:
                    loadedLocale = 'en'
                    json = (await registerImport(() => import('@/locales/en.json'))()).default
                    break
            }
            vueI18n.global.setLocaleMessage(loadedLocale, json)
            vueI18n.global.locale.value = loadedLocale
            return loadedLocale
        },
        install(app) {
            app.provide(I18N_SYMBOL, i18nAddon)

            app.mixin({
                beforeCreate() {
                    // re-exporting $t from i18n with strict types
                    // eslint-disable-next-line
                    this.$tr = this.$t
                },
                unmounted() {
                    delete this.$tr
                },
            })
        },
    }

    return { vueI18n, i18nAddon }
}

export const useI18nAddon = (): I18NAddon => {
    const i18nAddon = inject<I18NAddon>(I18N_SYMBOL)
    if (!i18nAddon) {
        throw new Error('i18nAddon not found')
    }
    return i18nAddon
}
