import { defineComponent as _defineComponent } from 'vue'
import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, unref as _unref, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["src"]

import Typography from '@/components_new/Typography.vue'
import AmazonIcon from './images/amazon.svg'
import Icon from '../Icon/Icon.vue'

export default /*@__PURE__*/_defineComponent({
  __name: 'Rewards',
  setup(__props) {


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_ctx.$style.container)
  }, [
    _createElementVNode("div", {
      class: _normalizeClass(_ctx.$style.cards)
    }, [
      _createElementVNode("div", {
        class: _normalizeClass([_ctx.$style.card, _ctx.$style.card1])
      }, null, 2),
      _createElementVNode("div", {
        class: _normalizeClass([_ctx.$style.card, _ctx.$style.card2])
      }, null, 2),
      _createElementVNode("div", {
        class: _normalizeClass([_ctx.$style.card, _ctx.$style.card3])
      }, [
        _createElementVNode("div", {
          class: _normalizeClass(_ctx.$style.card_shine)
        }, null, 2),
        _createElementVNode("img", {
          src: _unref(AmazonIcon),
          class: _normalizeClass(_ctx.$style.card_icon),
          loading: "lazy"
        }, null, 10, _hoisted_1)
      ], 2)
    ], 2),
    _createVNode(Typography, {
      type: "header",
      size: "xs"
    }, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.$tr('rewards.title')), 1)
      ]),
      _: 1
    }),
    _createElementVNode("div", {
      class: _normalizeClass(_ctx.$style.spacer)
    }, null, 2),
    _createVNode(Icon, {
      name: "chevron_r",
      class: _normalizeClass(_ctx.$style.chevron)
    }, null, 8, ["class"])
  ], 2))
}
}

})