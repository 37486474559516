import { AvailableLocale } from '@/i18n/types'

const LOCALE_COOKIE = 'selected_locale'

export function parseCookie(cookie: string) {
    return cookie.split('; ').reduce<Record<string, string>>((acc, el) => {
        const [key, value] = el.split('=')
        acc[key] = decodeURIComponent(value)
        return acc
    }, {})
}

export function getUACFromCookies(cookie: string) {
    const cookies = parseCookie(cookie)
    if (cookies.pg_uac) {
        try {
            return JSON.parse(atob(cookies.pg_uac)) as Record<string, string>
        } catch {
            return {}
        }
    }
    return {}
}

export function getCurrentLocaleFromCookies(cookie: string): string {
    const cookies = parseCookie(cookie)
    return cookies[LOCALE_COOKIE]
}

export function updateLocaleCookie(value: AvailableLocale) {
    // Set expiration date (1 year from now)
    const expires = new Date()
    expires.setTime(expires.getTime() + 365 * 24 * 60 * 60 * 1000)

    document.cookie = `${LOCALE_COOKIE}=${encodeURIComponent(value)}; expires=${expires.toUTCString()}; path=/; Secure; SameSite=none`
}
