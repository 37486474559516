<template>
    <div
        ref="googleBtn"
        :class="$style.google_signin"
    />
</template>
<script lang="ts" setup>
import { onMounted, ref, computed } from 'vue'
import { useUserStore } from '@/store/user-store/user-store'
import { useHead } from '@unhead/vue'
import { useLoggerStore } from '@/store/logger'
import { useConfig } from '@/composables/config'

const { VUE_APP_GAUTH_CLID, VUE_APP_GAUTH_REDIRECT } = useConfig()

interface GoogleAuthProps {
    onFinish: () => void
}
const props = defineProps<GoogleAuthProps>()

const googleBtn = ref<HTMLElement | null>(null)

const userStore = useUserStore()
const loggerStore = useLoggerStore()

const initSignIn = () => {
    if (!window.google || !googleBtn.value) {
        return
    }

    window.google.accounts.id.initialize({
        client_id: VUE_APP_GAUTH_CLID || '',
        auto_select: false,
        callback: async ({ credential }) => {
            await fetch(VUE_APP_GAUTH_REDIRECT || '', {
                method: 'POST',
                headers: { 'content-type': 'application/json' },
                body: JSON.stringify({ credential }),
            })

            userStore.fetchUserInfo()
            loggerStore.logEvent({
                event: 'custom_event',
                eventName: 'login',
                action: userStore.isAuthorized ? 'success' : 'fail',
            })

            props.onFinish()
        },
    })

    window.google.accounts.id.renderButton(googleBtn.value, {
        type: 'standard',
        text: 'signin_with',
        theme: 'outline',
        shape: 'pill',
        size: 'large',
        logo_alignment: 'left',
    })
}

const script = computed(() => ({
    src: 'https://accounts.google.com/gsi/client',
    async: true,
    onload: initSignIn,
}))
useHead({ script: [script] })

onMounted(() => {
    initSignIn()
})
</script>
<style module>
.google_signin {
    color-scheme: light;
    height: 44px;
    overflow: hidden;
}
</style>
