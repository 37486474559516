import { RewardedBanner } from '@/modules/adv/rewarded-banner'
import { Banner, BannerState } from '@/modules/adv/banner'
import { timer } from '@/utils/helpers'
import type { PlatformType } from '@/types'
import {
    AdProviderStatus,
    AdvService,
    type AdvServiceConfig,
    type OutOfPageAdType,
    type PageAdOptions,
    type PageAdType,
} from '../adv'

export const GAM_SOURCE = 'https://securepubads.g.doubleclick.net/tag/js/gpt.js'

function getUnitFromType(type: OutOfPageAdType | PageAdType, platform?: PlatformType): string {
    const isMobile = platform === 'ios' || platform === 'android'
    const suffix = isMobile ? '_mobile' : '_desktop'

    // Base units that need platform suffix
    const baseUnits: Record<string, string> = {
        rewarded: 'rewarded',
        interstitial: 'h5_interstitial',
        interstitial_preroll: 'h5_interstitial_preroll',
    }

    // If it's one of the base units that need platform suffix
    if (type in baseUnits) {
        return `${baseUnits[type]}${suffix}`
    }

    // Static units that don't need platform suffix
    const staticUnits: Record<string, string> = {
        fullscreen: 'interstitial',
        sticky_portrait: 'anchor_mobile_2',
        sticky_mobile: 'sticky_mobile',
        sidebar: 'sidebar',
        sidebar_bottom: 'sidebar_bottom',
        leaderboard: 'leaderboard',
        catalog_mobile: 'catalog_mobile',
        widget_sidebar: 'widget_sidebar',
        widget_sidebar_bottom: 'widget_sidebar_bottom',
        widget_horizontal: 'widget_horizontal',
        widget_horizontal_2: 'widget_horizontal_2',
        widget_sticky_mobile: 'widget_sticky_mobile',
    }

    return staticUnits[type]
}
const GAME_INTERSTITIAL_TIMEOUT = 120_000
export class GoogleAdsService implements AdvService {
    private readonly gpt: typeof googletag

    private readonly slots = new Map<googletag.Slot, Banner | RewardedBanner>()

    private readonly preloadedGameBannerCache = new Map<OutOfPageAdType, Promise<RewardedBanner>>()

    private readonly gamAccount: string

    private readonly platform?: PlatformType

    private formats: Record<OutOfPageAdType, googletag.enums.OutOfPageFormat> | null = null

    private gameManualInterstitialTriggeredAt = 0

    readonly serviceStatus: Promise<AdProviderStatus>

    constructor({ targeting = {}, gamAccount, platform }: AdvServiceConfig & { gamAccount: string }) {
        window.googletag = window.googletag || { cmd: [] }
        window.googletag.cmd = window.googletag.cmd || [] // it may be not empty after reload with cached resources

        this.gpt = window.googletag
        this.gamAccount = gamAccount
        this.platform = platform

        this.serviceStatus = new Promise((res, rej) => {
            if (process.env.NODE_ENV === 'production') {
                this.handleScript(() => rej(AdProviderStatus.offline))
            } else {
                // in dev mode the script is inserted by HeadMeta a bit later, so just wait a sec
                setTimeout(() => {
                    this.handleScript(() => rej(AdProviderStatus.offline))
                }, 1000)
            }

            this.gpt.cmd.push(() => {
                this.formats = {
                    // @ts-ignore Google doesn't care about updating their types library
                    interstitial: this.gpt.enums.OutOfPageFormat.GAME_MANUAL_INTERSTITIAL,
                    // @ts-ignore Google doesn't care about updating their types library
                    interstitial_preroll: this.gpt.enums.OutOfPageFormat.GAME_MANUAL_INTERSTITIAL,
                    // @ts-ignore Google doesn't care about updating their types library
                    fullscreen: this.gpt.enums.OutOfPageFormat.GAME_MANUAL_INTERSTITIAL,
                    rewarded: this.gpt.enums.OutOfPageFormat.REWARDED,
                    sticky_portrait: this.gpt.enums.OutOfPageFormat.BOTTOM_ANCHOR,
                }
                Object.entries(targeting).forEach(([key, value]) => {
                    this.gpt.pubads().setTargeting(key, value)
                })

                this.gpt.pubads().set('page_url', 'playgama.com')

                this.gpt.pubads().addEventListener('rewardedSlotReady', (event) => {
                    const banner = this.slots.get(event.slot) as RewardedBanner
                    if (!banner) {
                        return
                    }
                    banner.triggerReady(event.makeRewardedVisible)
                })
                // @ts-ignore Google doesn't care about updating their types library
                this.gpt.pubads().addEventListener('gameManualInterstitialSlotReady', (event) => {
                    // @ts-ignore Google doesn't care about updating their types library
                    this.slots.get(event.slot)?.triggerReady(event.makeGameManualInterstitialVisible)
                    this.gameManualInterstitialTriggeredAt = Date.now()
                })

                this.gpt.pubads().addEventListener('rewardedSlotClosed', (event) => {
                    const banner = this.slots.get(event.slot)
                    if (banner) {
                        banner.triggerClosed()
                    }
                })

                // @ts-ignore Google doesn't care about updating their types library
                this.gpt.pubads().addEventListener('gameManualInterstitialSlotClosed', (event) => {
                    const banner = this.slots.get(event.slot)
                    if (banner) {
                        banner.triggerClosed()
                    }
                })

                this.gpt.pubads().addEventListener('rewardedSlotGranted', (event) => {
                    this.slots.get(event.slot)?.triggerRewarded(event.payload)
                })

                this.gpt.pubads().addEventListener('impressionViewable', (event) => {
                    this.slots.get(event.slot)?.triggerViewable()
                })

                this.gpt.pubads().addEventListener('slotRenderEnded', (event) => {
                    if (event.isEmpty) {
                        this.slots.get(event.slot)?.triggerEmpty()
                    } else {
                        this.slots.get(event.slot)?.triggerRendered()
                    }
                })

                res(AdProviderStatus.online)
            })
        })
    }

    // eslint-disable-next-line class-methods-use-this
    preloadIntroAds() {
        // this.preloadAd('interstitial_preroll')
        // this.preloadAd('rewarded')
    }

    // eslint-disable-next-line class-methods-use-this
    handleScript(onError: () => void) {
        let script = Array.from(document.scripts).find((s) => s.src === GAM_SOURCE)
        if (!script) {
            console.error('GAM script is expected to already be rendered')
            script = document.createElement('script')
            script.async = true
            script.src = GAM_SOURCE
            script.addEventListener('error', onError)
            document.body.appendChild(script)
        } else {
            const { state = 'unknown' } = script.dataset as { state?: 'error' | 'loaded' }
            switch (state) {
                case 'error':
                    onError()
                    break
                case 'unknown':
                    script.addEventListener('error', onError)
                    break
                default:
                // noop
            }
        }
    }

    private preloadAd(type: OutOfPageAdType): Promise<RewardedBanner> {
        const unit = getUnitFromType(type, this.platform)
        let typeToLoad = type
        if (
            type === 'interstitial' &&
            Date.now() < this.gameManualInterstitialTriggeredAt + GAME_INTERSTITIAL_TIMEOUT
        ) {
            // preload rewarded instead of interstitial
            typeToLoad = 'rewarded'
        }

        const bannerPromise = new Promise<RewardedBanner>((res, rej) => {
            let slot: googletag.Slot | null
            this.gpt.cmd.push(() => {
                slot = this.gpt.defineOutOfPageSlot(`${this.gamAccount}/${unit}`, this.formats![typeToLoad])

                // Slot returns null if the page or device does not support rewarded ads.
                if (!slot) {
                    // eslint-disable-next-line prefer-promise-reject-errors
                    rej()
                    return
                }

                const banner = new RewardedBanner({
                    destroy: () => {
                        this.slots.delete(slot!)
                        this.gpt.cmd.push(() => {
                            googletag.destroySlots([slot!])
                            if (type === 'rewarded') {
                                // prelaod for rewarded banners only,
                                // because game interstitial banners have a timeout for a trigger event.
                                // taking into account timeout, it's better not to preload them
                                // to don't make the code messy
                                // this.gpt.cmd.push(() => {
                                //     this.preloadAd(typeToLoad)
                                // })
                            }
                        })
                    },
                })

                this.slots.set(slot, banner)

                res(banner)

                slot.addService(this.gpt.pubads())

                this.gpt.enableServices()
                this.gpt.display(slot)

                banner.addEventListener('closed', () => {
                    banner.destroy()
                })
            })
        })
        this.preloadedGameBannerCache.set(type, bannerPromise)
        return bannerPromise
    }

    // https://developers.google.com/publisher-tag/samples/display-rewarded-ad

    async prepareOutOfPageAd(type: OutOfPageAdType, timeout?: number): Promise<RewardedBanner> {
        // eslint-disable-next-line prefer-promise-reject-errors
        const rejectByTimeout = timeout ? timer(timeout) : Promise.reject()
        // const bannerPromise = this.preloadedGameBannerCache.get(type)

        this.preloadedGameBannerCache.delete(type)

        const loadingBanner = await /* bannerPromise ||  */ this.preloadAd(type) // an unprocessable slot triggers rejection
        this.preloadedGameBannerCache.delete(type)

        rejectByTimeout.then(() => {
            if (loadingBanner.state === BannerState.loading) {
                console.info('empty by timeout, google')
                loadingBanner.triggerEmpty()
                loadingBanner.destroy()
            }
        })
        // duplicate banner state event
        switch (loadingBanner.state) {
            case BannerState.ready:
                setTimeout(() => {
                    loadingBanner.triggerReady()
                }, 16)
                break
            case BannerState.empty:
                setTimeout(() => {
                    loadingBanner.triggerEmpty()
                    loadingBanner.destroy()
                }, 16)
                break
            default:
                break
        }

        return loadingBanner
    }

    requestPageAd(options: PageAdOptions): Promise<Banner> {
        const unit = getUnitFromType(options.type, this.platform)
        return new Promise((resolve, reject) => {
            this.gpt.cmd.push(() => {
                let refreshInterval: ReturnType<typeof setInterval>
                let slot: googletag.Slot | null = null
                if (options.type === 'sticky_portrait') {
                    slot = this.gpt.defineOutOfPageSlot(`${this.gamAccount}/${unit}`, this.formats!.sticky_portrait)
                } else if (options.sizes) {
                    slot = this.gpt.defineSlot(`${this.gamAccount}/${unit}`, options.sizes, options.el)
                }
                if (!slot) {
                    console.error('Failed to define slot')
                    // eslint-disable-next-line prefer-promise-reject-errors
                    reject()
                    return
                }

                slot.addService(this.gpt.pubads())
                this.gpt.enableServices()
                this.gpt.pubads().setCentering(true)
                this.gpt.pubads().collapseEmptyDivs()
                this.gpt.display(slot)

                if (options.refresh) {
                    refreshInterval = setInterval(() => {
                        this.gpt.pubads().refresh([slot])
                    }, options.refresh * 1000)
                }

                const banner = new Banner({
                    destroy: () => {
                        this.gpt.destroySlots([slot])
                        if (refreshInterval) {
                            clearInterval(refreshInterval)
                        }
                        this.slots.delete(slot)
                    },
                })

                this.slots.set(slot, banner)

                resolve(banner)
            })
        })
    }

    updateTargeting(targeting: AdvServiceConfig['targeting'] = {}) {
        this.gpt.cmd.push(() => {
            Object.entries(targeting).forEach(([key, value]) => {
                if (value) {
                    this.gpt.pubads().setTargeting(key, value)
                } else {
                    this.gpt.pubads().clearTargeting(key)
                }
            })
        })
    }
}
