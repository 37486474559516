import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

import Button from '@/components_new/Button.vue'
import PromoBanner from '@/components_new/PromoItems/PromoBanner.vue'
import { useUserStore } from '@/store/user-store/user-store'
import { storeToRefs } from 'pinia'
import FriendImg from '../images/friend.webp'


export default /*@__PURE__*/_defineComponent({
  __name: 'PromoInviteVertical',
  setup(__props) {

const userStore = useUserStore()
const { isAuthorized, isUserInfoLoaded } = storeToRefs(userStore)

return (_ctx: any,_cache: any) => {
  return (_unref(isAuthorized) && _unref(isUserInfoLoaded))
    ? (_openBlock(), _createBlock(PromoBanner, {
        key: 0,
        description: _ctx.$tr('promoItems.invite_desc'),
        direction: "column",
        icon: "silver_fennec_coin_small",
        image: { src: _unref(FriendImg), alt: _ctx.$tr('promoItems.invite_alt') },
        title: "10 000"
      }, {
        default: _withCtx(({ handleClick }) => [
          _createVNode(Button, {
            size: "l",
            interfaceTarget: "open-invite-modal",
            onClick: ($event: any) => (handleClick('invite'))
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$tr('promoItems.invite_btn')), 1)
            ]),
            _: 2
          }, 1032, ["onClick"])
        ]),
        _: 1
      }, 8, ["description", "image"]))
    : _createCommentVNode("", true)
}
}

})