import {
    createRouter as createVueRouter,
    type RouterHistory,
    type RouteRecordRaw,
    RouteLocationNormalized,
    Router,
} from 'vue-router'
import { ROUTE_NAMES, ROUTE_NAMES_GAME_GROUPS } from '@/router/constants'
import InitWrapper from '@/InitWrapper.vue'
import { I18NAddon } from '@/i18n/i18n'
import { AvailableLocale } from '@/i18n/types'
import { nonAuthorizedOnlyGuard } from './guards'
import { ImportWrapper } from '@/types'
// import router localization all at once eagerly, because we need to resolve hreflangs
import { en, es, type LocalizedPaths } from './localized-paths'

const groupGameBeforeEnter = (to: RouteLocationNormalized) => {
    const { page, ...queries } = to.query
    if (page === '1') {
        return { path: to.path, query: queries, replace: true }
    }
    return undefined
}

const createLocalizedRoutes = (locale: AvailableLocale, registerImport: ImportWrapper): RouteRecordRaw[] => {
    const localizedPaths = {
        en,
        es,
    }[locale]

    const getLocalizedPathName = (key: keyof LocalizedPaths) => localizedPaths[key]
    return [
        {
            path: '',
            name: ROUTE_NAMES.MAIN,
            component: registerImport(() => import(`@/pages/Homepage/Homepage.vue`)),
        },
        {
            path: getLocalizedPathName('game'),
            name: ROUTE_NAMES.GAME_PAGE,
            component: registerImport(() => import(`@/pages/Games/GameRouteWrapper.vue`)),
        },
        {
            path: 'export/game/:game',
            name: ROUTE_NAMES.EXPORT_GAME_PAGE,
            component: registerImport(() => import(`@/pages/Games/ExportGame.vue`)),
            meta: {
                manuallyHandledErrors: true,
            },
        },
        {
            path: 'widget/game/:game',
            name: ROUTE_NAMES.WIDGET_GAME_PAGE,
            component: registerImport(() => import(`@/pages/Games/WidgetGame.vue`)),
            meta: {
                manuallyHandledErrors: true,
            },
        },
        {
            path: getLocalizedPathName('invite'),
            name: ROUTE_NAMES.INVITE,
            beforeEnter: nonAuthorizedOnlyGuard,
            component: registerImport(() => import('@/pages/Invite/Invite.vue')),
        },
        {
            path: getLocalizedPathName('category'),
            name: ROUTE_NAMES.CATEGORY,
            component: registerImport(() => import(`@/pages/Category/CategoryPage.vue`)),
            beforeEnter: groupGameBeforeEnter,
        },
        {
            path: getLocalizedPathName('tag'),
            name: ROUTE_NAMES.TAG,
            component: registerImport(() => import(`@/pages/Category/CategoryPage.vue`)),
            beforeEnter: groupGameBeforeEnter,
        },
        {
            path: getLocalizedPathName('series'),
            name: ROUTE_NAMES.SERIES,
            component: registerImport(() => import(`@/pages/Category/CategoryPage.vue`)),
            beforeEnter: groupGameBeforeEnter,
        },
        {
            path: getLocalizedPathName('contacts'),
            name: ROUTE_NAMES.CONTACTS,
            component: registerImport(() => import(`@/pages/ContactUs/ContactUsPage.vue`)),
        },
        {
            path: getLocalizedPathName('profile'),
            name: ROUTE_NAMES.PROFILE,
            component: registerImport(() => import(`@/pages/UserProfile.vue`)),
        },
        {
            path: getLocalizedPathName('shop'),
            name: ROUTE_NAMES.SHOP,
            component: registerImport(() => import(`@/pages/Shop.vue`)),
        },
        {
            path: getLocalizedPathName('allCategories'),
            name: ROUTE_NAMES.ALL_CATEGORIES,
            component: registerImport(() => import('@/pages/GamesCategories.vue')),
        },
        {
            path: getLocalizedPathName('allTags'),
            name: ROUTE_NAMES.ALL_TAGS,
            component: registerImport(() => import('@/pages/GamesCategories.vue')),
        },
        {
            path: getLocalizedPathName('allSeries'),
            name: ROUTE_NAMES.ALL_SERIES,
            component: registerImport(() => import('@/pages/GamesCategories.vue')),
        },
        {
            path: getLocalizedPathName('allCategoriesOld'),
            name: ROUTE_NAMES.ALL_CATEGORIES_OLD_PAGE,
            component: registerImport(() => import('@/pages/GamesCategories.vue')),
        },
        {
            path: getLocalizedPathName('confidential'),
            name: ROUTE_NAMES.CONFIDENTIAL,
            component: registerImport(() => import('@/pages/static/StaticPage.vue')),
        },
        {
            path: getLocalizedPathName('termsofuse'),
            name: ROUTE_NAMES.TERMS_OF_USE,
            component: registerImport(() => import('@/pages/static/StaticPage.vue')),
        },
        {
            path: getLocalizedPathName('takedown_notice'),
            name: ROUTE_NAMES.TAKEDOWN_NOTICE,
            component: registerImport(() => import('@/pages/static/StaticPage.vue')),
        },
        {
            path: getLocalizedPathName('license'),
            name: ROUTE_NAMES.LICENSE,
            component: registerImport(() => import('@/pages/static/StaticPage.vue')),
        },
        {
            path: getLocalizedPathName('partners_api'),
            name: ROUTE_NAMES.PARTNERS_API,
            component: registerImport(() => import('@/pages/static/StaticPage.vue')),
        },
        {
            // this route is for rendering 5xx errors
            // the error itself is rendered by InitWrapper, so here the component is a dummy
            path: 'error',
            component: {},
        },
        {
            path: ':catchAll(.*)',
            component: registerImport(() => import(`@/pages/Error/NotFoundPage.vue`)),
        },
    ]
}

// Update `children` when changing locale
const updateLocalizedRoutes = (router: Router, registerImport: ImportWrapper, locale: AvailableLocale) => {
    const parentRoute = router.getRoutes().find((route) => route.name === ROUTE_NAMES.ROOT)
    if (parentRoute) {
        parentRoute.children.forEach((child) => {
            if (child.name) {
                router.removeRoute(child.name)
            }
        })

        const newChildren = createLocalizedRoutes(locale, registerImport)
        newChildren.forEach((child) => router.addRoute(ROUTE_NAMES.ROOT, child))
    }
}

const getRoutes = ({
    i18nAddon,
    registerImport,
}: {
    i18nAddon: I18NAddon
    registerImport: ImportWrapper
}): RouteRecordRaw[] => {
    const supportedLocales = i18nAddon.getSupportedLocales()
    return [
        {
            path: supportedLocales.length > 1 ? `/:locale(${i18nAddon.getSupportedLocales().join('|')})?/` : '/',
            name: ROUTE_NAMES.ROOT,
            component: InitWrapper,
            // create default EN router to match the locale param on initial navigation
            children: createLocalizedRoutes('en', registerImport),
        },
    ]
}

const createRouterForLocale = (locale: AvailableLocale, history: RouterHistory): Router => {
    // Create routes for this specific locale
    const routes = [
        {
            path: '/',
            name: `${ROUTE_NAMES.ROOT}_${locale}`,
            component: InitWrapper,
            children: createLocalizedRoutes(locale, (cb) => cb),
        },
    ]

    // Create a router instance for this locale
    const router = createVueRouter({
        routes,
        history,
        // We don't need scroll behavior for these routers as they're just for meta tags
    })

    return router
}

// Create routers for all supported locales
export function createRoutersForAllLocales({
    history,
    supportedLocales,
}: {
    history: RouterHistory
    supportedLocales: AvailableLocale[]
}) {
    const result = {} as Record<AvailableLocale, Router>
    for (const locale of supportedLocales) {
        const router = createRouterForLocale(locale as AvailableLocale, history)
        result[locale] = router
    }
    return result
}

export default function createRouter({
    history,
    registerImport,
    i18nAddon,
}: {
    history: RouterHistory
    registerImport: ImportWrapper
    i18nAddon: I18NAddon
}) {
    // Create the main router that will be used for navigation
    const router = createVueRouter({
        routes: getRoutes({ i18nAddon, registerImport }),
        history,
        scrollBehavior: async (to, from, savedPosition) => {
            /*
            Before activate scroll behavior we should make sure that router has completed navigation 
            to avoid errors and mismatches on hydration
            */
            await router.isReady()
            if (to.hash) {
                return {
                    el: to.hash,
                }
            }
            if (savedPosition) {
                return savedPosition
            }
            // special behavior on categories page
            const gameGroupPages = [ROUTE_NAMES.CATEGORY, ROUTE_NAMES.TAG, ROUTE_NAMES.SERIES]
            if (gameGroupPages.includes(to.name as ROUTE_NAMES_GAME_GROUPS)) {
                if (gameGroupPages.includes(from.name as ROUTE_NAMES_GAME_GROUPS)) {
                    // do not scroll to top when changing only query params on the same category
                    if (to.params.hru === from.params.hru) {
                        return false
                    }
                } else if (to.query.page) {
                    // scroll to specific page on direct access
                    return {
                        el: `#page-${to.query.page}`,
                    }
                }
            }

            return { top: 0 }
        },
    })

    const defaultLocale = i18nAddon.getDefaultLocale()

    router.beforeEach(async (to, _, next) => {
        const paramLocale = (to.params.locale as string) || ''
        const paramLocaleWithDefault = paramLocale || defaultLocale

        const currentLocale = i18nAddon.currentLocale.value
        const preferredLocale = i18nAddon.getPreferredLocale()
        if (
            paramLocale === '' && // only do redirect if the locale is omitted
            preferredLocale && // if we detected a locale from cookie or accept-language header
            preferredLocale !== paramLocaleWithDefault && // if it's not the same as the one in the URL
            to.name // we can only redirect once it is parsed against it's corresponing localised router
        ) {
            const nextLocale = preferredLocale === defaultLocale ? '' : preferredLocale // remove default locale from URL
            return next({ name: to.name, params: { ...to.params, locale: nextLocale }, query: to.query })
        }

        if (paramLocaleWithDefault === currentLocale) {
            return next()
        }
        const loadedLocale = await i18nAddon.loadLocale({ locale: paramLocaleWithDefault, registerImport })

        updateLocalizedRoutes(router, registerImport, loadedLocale)

        return next(to)
    })

    const supportedLocales = i18nAddon.getSupportedLocales()
    // Create routers for all supported locales (for meta tags and other seo purposes)
    const routersByLocale: Record<AvailableLocale, Router> = createRoutersForAllLocales({ history, supportedLocales })

    return { router, routersByLocale }
}
