import { defineStore } from 'pinia'
import { ref } from 'vue'

export interface ToastOptions {
    title: string
    description: string
    animation?: Record<string, unknown>
    image?: string
    buttonText?: string
    onAccept?: () => void
    onClose?: () => void
}

export const useToastStore = defineStore('toast', () => {
    const isVisible = ref(false)
    const toast = ref<ToastOptions | null>(null)

    const showToast = (options: ToastOptions) => {
        const { title, description, animation, image, buttonText, onAccept, onClose } = options

        toast.value = {
            title,
            description,
            animation,
            image,
            buttonText,
            onAccept,
            onClose,
        }
        isVisible.value = true
    }

    const closeToast = () => {
        if (toast.value?.onClose) {
            toast.value.onClose()
        }
        isVisible.value = false
        toast.value = null
    }

    const acceptToast = () => {
        if (toast.value?.onAccept) {
            toast.value.onAccept()
        }
        isVisible.value = false
        toast.value = null
    }

    return {
        toast,
        isVisible,
        showToast,
        closeToast,
        acceptToast,
    }
})
