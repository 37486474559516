import { defineStore } from 'pinia'
import { ref, computed } from 'vue'
import { useToastStore } from '@/components_new/Toaster/store'
import {
    isBrowserIOSChrome,
    isBrowserIOSSafari,
    isDesktopChrome,
    isDesktopEdge,
    isDesktopSafari,
    isPWARuntime,
} from './utils'
import ChromeDesktopSVG from './guides/chrome-desktop.svg?file'
import { useTr } from '@/composables/use-tr'
import { useLayoutStore } from '@/store/layout-store'

const PWA_INSTALL_CONFIG = {
    MIN_VISITS: 2,
    MAX_DISPLAY_COUNT: 100,
    DISPLAY_DELAY_HOURS: 24 * 7,
    TOAST_DELAY_MS: 3000,
} as const

export interface BeforeInstallPromptEvent extends Event {
    prompt: () => Promise<void>
    userChoice: Promise<{ outcome: 'accepted' | 'dismissed' }>
}

export const usePWAInstallStore = defineStore(
    'pwa-install',
    () => {
        const deferredPrompt = ref<BeforeInstallPromptEvent | null>(null)
        const toastStore = useToastStore()
        const layoutStore = useLayoutStore()
        const tr = useTr()

        const displayCount = ref(0)
        const lastDisplayTime = ref(0)
        const optedOut = ref(false)
        const installed = ref(false)
        const sessions = ref(0)
        const initialized = ref(false)
        const isDisplaying = ref(false)

        const shouldShowPrompt = computed(() => {
            if (installed.value) {
                return false
            }

            if (layoutStore.fullScreen) {
                return false
            }

            // keep showing the toast for now
            // if (optedOut.value) {
            //     return false
            // }

            if (sessions.value < PWA_INSTALL_CONFIG.MIN_VISITS) {
                return false
            }

            if (displayCount.value >= PWA_INSTALL_CONFIG.MAX_DISPLAY_COUNT) {
                return false
            }

            if (Date.now() - lastDisplayTime.value < PWA_INSTALL_CONFIG.DISPLAY_DELAY_HOURS * 60 * 60 * 1000) {
                return false
            }

            return true
        })

        const handleOptOut = () => {
            optedOut.value = true
            lastDisplayTime.value = Date.now()
            displayCount.value += 1
            isDisplaying.value = false
        }

        const handleInstalled = () => {
            installed.value = true
            lastDisplayTime.value = Date.now()
            displayCount.value += 1
        }

        const handleAccepted = () => {
            if (deferredPrompt.value) {
                triggerNativePrompt()
            }

            // We show button only for native prompt atm
            // else {
            //     handleInstalled()
            // }

            isDisplaying.value = false
        }

        const triggerNativePrompt = async () => {
            if (deferredPrompt.value) {
                try {
                    await deferredPrompt.value.prompt()
                    const { outcome } = await deferredPrompt.value.userChoice

                    if (outcome === 'accepted') {
                        handleInstalled()
                    } else {
                        handleOptOut()
                    }

                    deferredPrompt.value = null
                } catch {
                    handleOptOut()
                }
            }
        }

        const showPWAInstallPrompt = async () => {
            if (!shouldShowPrompt.value) {
                return
            }

            // loading animations when showing the toast
            let animation, image
            if (isDesktopChrome() || isDesktopEdge()) {
                image = ChromeDesktopSVG
            } else if (isBrowserIOSSafari() || isBrowserIOSChrome()) {
                animation = await import('./guides/safari.json').then((module) => module.default)
            } else if (isDesktopSafari()) {
                animation = await import('./guides/safari-desktop.json').then((module) => module.default)
            } else {
                animation = await import('./guides/chromium.json').then((module) => module.default)
            }

            const description = isDesktopSafari()
                ? tr('toast-pwa.description-safari-desktop')
                : tr('toast-pwa.description')
            const buttonText = deferredPrompt.value ? tr('toast-pwa.button_text') : undefined

            isDisplaying.value = true

            const params = {
                title: tr('toast-pwa.title'),
                description,
                animation,
                image,
                buttonText,
                onAccept: handleAccepted,
                onClose: handleOptOut,
            }

            toastStore.showToast(params)

            lastDisplayTime.value = Date.now()
            displayCount.value += 1
        }

        function init() {
            if (initialized.value) {
                return
            }

            initialized.value = true
            sessions.value += 1

            if (isPWARuntime()) {
                installed.value = true
                return
            }

            window.addEventListener('beforeinstallprompt', (e) => {
                e.preventDefault()
                deferredPrompt.value = e as BeforeInstallPromptEvent
            })

            window.addEventListener('appinstalled', () => {
                handleInstalled()
            })

            setTimeout(showPWAInstallPrompt, PWA_INSTALL_CONFIG.TOAST_DELAY_MS)
        }

        return {
            deferredPrompt,
            displayCount,
            lastDisplayTime,
            optedOut,
            installed,
            sessions,
            triggerNativePrompt,
            handleOptOut,
            handleAccepted,
            handleInstalled,
            showPWAInstallPrompt,
            shouldShowPrompt,
            init,
            initialized,
            isDisplaying,
        }
    },
    {
        persist: {
            key: 'pwa_install',
            pick: ['displayCount', 'lastDisplayTime', 'optedOut', 'installed', 'sessions'],
        },
    },
)
