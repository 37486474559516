<template>
    <div :class="$style.container">
        <div :class="$style.center">
            <img
                :class="$style.img"
                :src="content.img"
                :alt="content.imgAlt"
            />
            <div :class="$style.texts">
                <Typography
                    type="header"
                    size="m"
                    as="h2"
                >
                    {{ content.title }}
                </Typography>
                <Typography
                    type="paragraph"
                    size="s"
                >
                    {{ content.text }}
                </Typography>
            </div>
            <div
                v-if="showButtons"
                :class="$style.buttons"
            >
                <Button
                    v-for="(button, index) in content.buttons"
                    :key="index"
                    :class="$style.button"
                    size="l"
                    :interfaceTarget="button.interfaceTarget"
                    :to="button.link"
                    :visual="index === 0 ? 'fill' : 'outline'"
                    @click="button.onClick"
                >
                    {{ button.text }}
                </Button>
            </div>
        </div>
    </div>
</template>
<script setup lang="ts">
import Button from '@/components_new/Button.vue'
import { computed } from 'vue'
import { useRoute } from 'vue-router'
import { useCategoriesStore } from '@/store/categories-store'
import { type ErrorStatus, type PageStatus } from '@/store/app-store'
import img404 from './assets/404.webp'
import imgJoystickOnFire from './assets/joystick-on-fire.webp'
import Typography from '../Typography.vue'
import { useTr } from '@/composables/use-tr'
import { ROUTE_NAMES } from '@/router/constants'

const categoriesStore = useCategoriesStore()
const tr = useTr()

const props = defineProps<{
    type: Exclude<PageStatus, 200>
    randomGameHRU: string | null
    showButtons?: boolean
}>()

const route = useRoute()

const contentForType = computed(() => ({
    404: {
        img: img404,
        imgAlt: tr('errorContent.404_imgAlt'),
        title: tr('errorContent.404_title'),
        text: tr('errorContent.404_text'),
        buttons: [
            {
                text: tr('errorContent.404_back'),
                link: { name: ROUTE_NAMES.MAIN },
                interfaceTarget: 'navigation-link',
                // onClick: () => {},
            },
            props.randomGameHRU && {
                text: tr('errorContent.404_randomGame'),
                interfaceTarget: 'random-game',
                link: { name: ROUTE_NAMES.GAME_PAGE, params: { game: props.randomGameHRU } },
                onClick: () => {
                    categoriesStore.updateRandomGame()
                },
            },
        ].filter((x) => !!x),
    },
    500: {
        img: imgJoystickOnFire,
        imgAlt: tr('errorContent.500_imgAlt'),
        title: tr('errorContent.500_title'),
        text: tr('errorContent.500_text'),
        buttons: [
            {
                text: tr('errorContent.500_reload'),
                link: route.fullPath,
                interfaceTarget: 'reload-page',
                onClick: () => {},
            },
            {
                text: tr('errorContent.500_back'),
                link: { name: ROUTE_NAMES.MAIN },
                interfaceTarget: 'navigation-link',
                onClick: () => {},
            },
        ],
    },
}))

const content = computed(() => contentForType.value[props.type as ErrorStatus] || contentForType.value[500])
</script>
<style module>
.container {
    flex-grow: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 60px 0;
}

.center {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    gap: 40px;
}

.img {
    max-width: 60%;
    max-height: 160px;
}

.texts {
    display: flex;
    flex-direction: column;
    gap: 12px;
}

.title {
    font-size: 28px;
    font-weight: 600;
    line-height: 100%;
}

.text {
    font-size: 14px;
    font-weight: 400;
    line-height: 120%;
}

.buttons {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    gap: 12px;
}
.button {
    flex-grow: 1;
}
</style>
