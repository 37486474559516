<template>
    <ModalContainer
        wide
        :title="$tr('paymentModal.header')"
        :isOpened="isPaymentModalOpen"
        @closeModal="onCloseModal"
    >
        <template #custom-content>
            <div :class="$style.row">
                <div :class="$style.column">
                    <Typography
                        type="header"
                        size="s"
                        :responsive="false"
                    >
                        {{ $tr('paymentModal.title') }}
                    </Typography>
                    <TextInput
                        id="gold-amount"
                        name="gold-amount"
                        type="number"
                        :value="localAmount"
                        :class="$style.margin"
                        @update:modelValue="onAmountChange"
                    />
                    <Typography
                        is="p"
                        size="s"
                        :responsive="false"
                        :class="$style.margin"
                    >
                        {{ $tr('paymentModal.description', { amount: paymentModalAmount.toString() }) }}
                    </Typography>
                </div>
                <div :class="[$style.row, $style.iframe]">
                    <iframe
                        v-if="tokenReady"
                        :src="xsollaIframeSrc"
                        width="375"
                        height="600"
                        allow="clipboard-read; clipboard-write; payment"
                    />
                    <Icon
                        v-if="!tokenReady"
                        name="loader"
                        :class="$style.loader"
                    />
                </div>
            </div>
        </template>
    </ModalContainer>
</template>
<script setup lang="ts">
import ModalContainer from '@/components_new/ModalItems/ModalContainer.vue'
import { useLayoutStore } from '@/store/layout-store'
import { useUserStore } from '@/store/user-store/user-store'
import Typography from '@/components_new/Typography.vue'
import { storeToRefs } from 'pinia'
import { computed, onMounted, onUnmounted, ref, watch } from 'vue'
import TextInput from '@/components_new/TextInput/TextInput.vue'
import { request } from '@/utils/requests/request'
import { useDebounceFn } from '@vueuse/core'
import Icon from '@/components_new/Icon/Icon.vue'

const layoutStore = useLayoutStore()
const userStore = useUserStore()
const { isPaymentModalOpen, paymentModalAmount } = storeToRefs(layoutStore)

const xsollaIframeToken = ref('')
const abortRef = ref()
const xsollaIframeSrc = computed(
    () => `https://sandbox-secure.xsolla.com/paystation4/?token=${xsollaIframeToken.value}`,
)
const tokenReady = computed(() => Boolean(xsollaIframeToken.value))

const localAmount = ref(paymentModalAmount.value)
watch(paymentModalAmount, (val) => {
    localAmount.value = val
})
const updateAmount = (val: number) => {
    localAmount.value = Math.max(paymentModalAmount.value, val)
}

const debouncedUpdateAmount = useDebounceFn(updateAmount, 200)
const onAmountChange = (val: number) => {
    xsollaIframeToken.value = ''
    if (abortRef.value) {
        abortRef.value.abort('New request sent')
        abortRef.value = undefined
    }
    debouncedUpdateAmount(val)
}

watch(localAmount, async (amount) => {
    if (amount > 0) {
        abortRef.value = new AbortController()
        try {
            const res = await request('/api/v1/in-game-payments/createXsollaToken', {
                method: 'POST',
                // Uncomment this locally
                /*
                headers: {
                    'content-type': 'application/json',
                    'cf-ipcountry': 'ES',
                },
                */
                body: JSON.stringify({ amount }),
                signal: abortRef.value.signal,
            })

            if (res.errors.length === 0) {
                xsollaIframeToken.value = res.data
                abortRef.value = undefined
            }
        } catch (e) {
            console.error(e)
            abortRef.value = undefined
        }
    }
})

function onCloseModal() {
    layoutStore.setPaymentModalAmount(0)
    xsollaIframeToken.value = ''
}

function modalEventListener(event: MessageEvent) {
    try {
        const eventData = JSON.parse(event.data)
        if (eventData.command === 'open-status-success') {
            setTimeout(() => {
                layoutStore.setPaymentModalAmount(0)
            }, 1000)
            userStore.fetchUserInfo()
        }
    } catch {}
}

onMounted(() => {
    window.addEventListener('message', modalEventListener)
})
onUnmounted(() => {
    window.removeEventListener('message', modalEventListener)
})
</script>

<style module>
.row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
}

.column {
    display: flex;
    flex-direction: column;
    flex-basis: 100%;
    flex: 1;
    padding: 10px;
}

.margin {
    margin-top: 10px;
}

.iframe {
    width: 50%;
    min-width: 375px;
    min-height: 600px;
    background-color: rgb(44, 38, 64);
    position: relative;
}

.loader {
    position: absolute;
    top: 200px;
    left: 191px;
}

@media (--mobile-landscape), (--tablet-plus) {
    .iframe {
        margin: 0 -16px;
    }
}
@media (--desktop-small-plus) {
    .iframe {
        margin: 0;
    }
}
</style>
